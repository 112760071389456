import { ForgeList } from '@tylertech/forge-react';
import { getProjectStageFilterEntries, getProjectStageFiltersTitle } from '../../helper/configHelper';
import FilterOption from './FilterOption';
import useProjectStageFilter from '../../hooks/useProjectStageFilter';

interface ProjectStageFilterProps {
  type: string;
  name: string;
}

const ProjectStageFilter = () => {
  const projectStageFilterEntries: ProjectStageFilterProps[] = getProjectStageFilterEntries();
  const title = getProjectStageFiltersTitle();
  const  { checkIfFiltersSelected, onSelectProjectStage } = useProjectStageFilter();

  return (
    <div className="project-type-wrapper">
      <h2 className="section-heading" title={title}>{title}</h2>
      <div className="project-type-filter">
        <ForgeList className="project-type-list" dense>
          {projectStageFilterEntries.map((config, index: number) => (
            <FilterOption
              config={config}
              index={index}
              isOptionSelected={checkIfFiltersSelected(config.type)}
              onOptionSelect={onSelectProjectStage}
            />
          ))}
        </ForgeList>
      </div>
    </div>
  );
};

export default ProjectStageFilter;
