import _ from "lodash";
import mapboxgl from 'mapbox-gl';

interface ShapeGeom {
  type: string;
  coordinates: any[];
}

export const getShapeGeomBounds = (shapeGeom: ShapeGeom): mapboxgl.LngLatBounds => {
  let coordinates: any[];

  if (shapeGeom.type === "Point") {
    coordinates = shapeGeom.coordinates;
    const bounds = new mapboxgl.LngLatBounds();
    return bounds.extend([coordinates[0], coordinates[1]]);
  } else if (shapeGeom.type === "MultiPolygon") {
    const flattened_coordinates = _.flatten(shapeGeom.coordinates);
    coordinates = _.flatten(flattened_coordinates);
  } else if (shapeGeom.type === "Polygon" || shapeGeom.type === "MultiLineString") {
    coordinates = _.flatten(shapeGeom.coordinates);
  } else {
    coordinates = shapeGeom.coordinates;
  }

  return coordinates.reduce((bounds: mapboxgl.LngLatBounds, coord: any) => {
    return bounds.extend([coord[0], coord[1]]);
  }, new mapboxgl.LngLatBounds([coordinates[0][0], coordinates[0][1]], [coordinates[0][0], coordinates[0][1]]));
};
