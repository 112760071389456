import { ForgeDrawer, ForgeList, ForgeListItem } from '@tylertech/forge-react';
import _ from 'lodash';
import React from 'react';

export interface AdminListItemProps {
  selectedMenuItem: string;
  menuItemAccessMap: Record<string, boolean | undefined>;
  handleMenuItemClick: (menuItem: Record<string, string>) => void;
  ADMIN_MENUS: Record<string, string>[];
}

const AdminListItem: React.FC<AdminListItemProps> = ({ selectedMenuItem, menuItemAccessMap, handleMenuItemClick, ADMIN_MENUS }) => {

  const renderListItem = (menuItem: Record<string, string>) => {
    const uniqueKey = `menu_item_${menuItem.value}`;
    const activeClass = selectedMenuItem === menuItem.value ? 'active' : '';

    if (!menuItemAccessMap[menuItem.value]) {
      return null;
    }

    return (
      <ForgeListItem
        key={uniqueKey}
        className={`py-2 cursor-pointer admin-menu-item ${activeClass}`}
        onClick={() => handleMenuItemClick(menuItem)}
      >
        <button>{menuItem.name}</button>
      </ForgeListItem>
    );
  };

  const renderListItems = () => {
    return _.map(ADMIN_MENUS, renderListItem);
  };

  return (
    <ForgeDrawer slot="left" open={true} direction="left">
      <ForgeList className="admin-menu mt-3">
        {renderListItems()}
      </ForgeList>
    </ForgeDrawer>
  );
};

export default AdminListItem;
