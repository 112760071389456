import { useEffect, useState } from "react";
import { getDistinctValuesByColumn } from "../../data/api";
import { checkIfLoginPage } from "../../helper/serverConfigHelper";

const useDistinctData = (params: any) => {
	const isLoginPage = checkIfLoginPage();
	const [distinctValues, setDistinctValues] = useState<string[]>([]);

	const fetchDistinctData = async () => {
		try {
			const distinctValues = await getDistinctValuesByColumn(params);
			setDistinctValues(distinctValues);
		} catch {
			setDistinctValues([]);
			console.error("Error fetching project data");
		}
	};

	useEffect(() => {
		if (!isLoginPage) {
			fetchDistinctData();
		}
	}, []);

	return { distinctValues };
};

export default useDistinctData;
