import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

type UseReactQueryFetchParams = {
  queryKey: string | unknown[],
  fetchFn: (params: any) => Promise<any>,
  params?: any,
  cacheTime?: number,
  refetchInterval?: number,
  retry?: number,
  refetchOnWindowFocus?: boolean,
};

/**
 * Custom error class for query errors with status code support
 */
class QueryError extends Error {
  response?: { status: number };
  
  constructor(message: string, status?: number) {
    super(message);
    this.name = 'QueryError';
    if (status) {
      this.response = { status };
    }
  }
}

/**
 * Custom React Query hook that handles errors and propagates them to ErrorBoundary
 */
const useReactQueryFetch = ({
  queryKey,
  fetchFn,
  params = {},
  cacheTime = 1000 * 60 * 2,
  refetchInterval = 0,
  retry = 1,
  refetchOnWindowFocus = false,
}: UseReactQueryFetchParams) => {
  const result = useQuery({
    queryKey: Array.isArray(queryKey) ? queryKey : [queryKey, params],
    queryFn: async () => {
      try {
        return await fetchFn(params);
      } catch (err: any) {
        // Create appropriate error objects based on status code
        const status = err.response?.status;
        if (status === 404 || status === 500) {
          const statusText = status === 404 ? 'Not found' : 'Server error';
          throw new QueryError(`${statusText}: ${err.message}`, status);
        }
        
        throw err;
      }
    },
    cacheTime,
    refetchInterval,
    refetchOnWindowFocus,
    retry,
    useErrorBoundary: true,
  });

  // If there's an error, throw it to be caught by the ErrorBoundary
  const { isError, error } = result;
  useEffect(() => {
    if (isError && error) {
      // Make sure we have a proper Error object
      if (!(error instanceof Error)) {
        throw new QueryError(String(error), 500);
      }
      throw error;
    }
  }, [isError, error]);

  return result;
};

export default useReactQueryFetch;
