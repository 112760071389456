export const ALL_PROJECTS_CATEGORY_ID = { 'all': [] };
export const HOME_TAB = 'home';
export const PROJECTS_LIST_TAB = 'list';
export const PROJECTS_TAB = 'projects';
export const DEFAULT_SEARCH_FIELD = 'project_name';
export const SITE_TYPE = "capitalProjectsExplorer";
export const COLOR_CODES = {
  'green': '#1DAB3A',
  'yellow': '#ffd22c',
  'red': '#ff4d2c',
};

export const PROJECT_SORT_TYPE = {
  NAME: "name",
  COST_VARIANCE: 'cost_variance',
  SCHEDULE_VARIANCE: 'schedule_variance',
  VARIANCE: 'variance',
};

export const ADMIN_MENU_ITEM = {
  ADMINISTRATION: 'administration',
  USER_PROFILE: 'user_profile',
  BRANDING: 'branding',
  USER_ROLES: 'user_roles', 
  SIGN_OUT: 'sign-out'
};

export const ADMIN_MENUS = [
  { name: 'User profile', value: ADMIN_MENU_ITEM.USER_PROFILE },
  { name: 'Branding', value: ADMIN_MENU_ITEM.BRANDING },
  { name: 'Users & Roles', value:  ADMIN_MENU_ITEM.USER_ROLES}
];

export const PROFILE_OPTIONS = [
  {
    value: ADMIN_MENU_ITEM.ADMINISTRATION,
    label: "Administration"
  },
  {
    value: ADMIN_MENU_ITEM.USER_PROFILE,
    label: "User Profile",
    to: "/admin",
  },
  {
    value: ADMIN_MENU_ITEM.BRANDING,
    label: "Branding",
    to: "/admin"
  },
  {
    value: ADMIN_MENU_ITEM.USER_ROLES,
    label: "Users & Roles",
    to: "/admin"
  },
  { value: ADMIN_MENU_ITEM.SIGN_OUT, label: "Sign Out", to: "" },
]; 

export const BRANDING_COLORS = [
  { name: '#9DA2A9', value: '#9DA2A9' },
  { name: '#FFFFFF', value: '#FFFFFF' },
  { name: '#000000', value: '#000000' },
  { name: '#175777', value: '#175777' },
  { name: '#2BB05E', value: '#2BB05E' },
  { name: '#138A81', value: '#138A81' },
  { name: '#29A4C5', value: '#29A4C5' },
  { name: '#D0CA91', value: '#D0CA91' },
  { name: '#E6D43A', value: '#E6D43A' },
  { name: '#871816', value: '#871816' },
  { name: '#F24069', value: '#F24069' },
  { name: '#FD9459', value: '#FD9459' },
  { name: '#5D91D6', value: '#5D91D6' },
  { name: '#8F5FC0', value: '#8F5FC0' },
  { name: '#C0BDE1', value: '#C0BDE1' },
  { name: '#2F1E42', value: '#2F1E42' },
  { name: '#dA6E94', value: '#dA6E94' },
  { name: '#76CDB4', value: '#76CDB4' },
  { name: '#A9E5BB', value: '#A9E5BB' },
  { name: '#2E6362', value: '#2E6362' },
  { name: '#FEC332', value: '#FEC332' },
  { name: '#DB6E1D', value: '#DB6E1D' },
  { name: '#2763C2', value: '#2763C2' },
  { name: '#9DCAF1', value: '#9DCAF1' },
  { name: '#AF7CA1', value: '#AF7CA1' },
  { name: '#634184', value: '#634184' },
  { name: '#BF222D', value: '#BF222D' },
  { name: '#5A1908', value: '#5A1908' },
  { name: '#4D74A3', value: '#4D74A3' },
  { name: '#FCBF8E', value: '#FCBF8E' },
  { name: '#B65C60', value: '#B65C60' },
];

export const DATA_INSIGHTS_URL = "https://www.tylertech.com/products/data-insights";

export const ANIMATION_TYPE = "slide";
