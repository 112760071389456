import _ from 'lodash';
import {
  getConfig
} from "../helper/serverConfigHelper";

class MiniMapMouseInteractionHandler {
  map: mapboxgl.Map;
  popupHandler: any;
  customerConfigurations: any;

  constructor(map: mapboxgl.Map, popupHandler: any) {
    this.map = map;
    this.popupHandler = popupHandler;
    this.customerConfigurations = getConfig();

    this._onMouseMove = this._onMouseMove.bind(this);
    this._onCanvasMouseMove = this._onCanvasMouseMove.bind(this);

    this.map.on('mousemove', this._onMouseMove);
  }

  _onMouseMove(e: mapboxgl.MapMouseEvent) {
    const target = e.originalEvent.target as HTMLElement;
    if (target && target.classList.contains('mapboxgl-canvas')) {
      this._onCanvasMouseMove(e);
    } else {
      // Clicked on an overlay like spider, which will emit separate events or be handled by its own handler.
    }
  }

  _onCanvasMouseMove(e: mapboxgl.MapMouseEvent) {
    const hoverableLayers = [
      'district-map-fill',
      'district-map-fill-highlight',
      'district-map-outlines',
      'district-map-label'
    ];

    const features = this.map.queryRenderedFeatures(e.point, { layers: hoverableLayers });

    // Change the cursor style as a UI indicator.
    this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';

    if (
      features[0] && features[0].layer &&
      _.includes(
        ['district-map-fill', 'district-map-fill-highlight', 'district-map-outlines'],
        features[0].layer.id
      )
    ) {
      this.popupHandler.showFeaturePopup(features[0], { isPinHovered: false, isDistrict: true }, e);
    } else if (
      features[0] &&
      features[0].layer && features[0].layer.id === 'district-map-label' &&
      this.customerConfigurations.show_label_on_map === 'show'
    ) {
      this.popupHandler.showFeaturePopup(features[0], { isPinHovered: false, isDistrict: true }, e);
    } else {
      this.popupHandler.hidePopups();
    }
  }

  destroy() {
    // Remove all event listeners
    this.map.off('mousemove', this._onMouseMove);
  }
}

export default MiniMapMouseInteractionHandler;
