import {
  getBudgetIndicatorByValue,
  getOptionalIndicatorByValue,
  getScheduleIndicatorByValue,
} from '../../helper/projectIndicatorHelper';
import { RootState } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';
import { Project } from 'types/projectTypes';

const ProjectIndicatorNarrative = () => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  
  const {
    budget_indicator_narrative: budgetNarrative,
    schedule_indicator_narrative: scheduleNarrative,
    optional_indicator_narrative: optionalNarrative,
    budget_indicator: budgetIndicator,
    schedule_indicator: scheduleIndicator,
    optional_indicator: OptionalIndicator
  } = currentProject || {};
  const {
    icon_class: budgetIconClass,
    icon_color: budgetIconColor
  } = getBudgetIndicatorByValue(budgetIndicator);
  const {
    icon_class: scheduleIconClass,
    icon_color: scheduleIconColor
  } = getScheduleIndicatorByValue(scheduleIndicator);

  const {
    icon_class: optionalIconClass,
    icon_color: optionalIconColor
  } = getOptionalIndicatorByValue(OptionalIndicator);

  return (
    <div className="project-indicator-container">
      {budgetNarrative && budgetIndicator && (
        <div className="project-indicator-wrapper">
          <span className={`fa ${budgetIconClass}`} style={{ color: budgetIconColor }} ></span>
          <span>{budgetNarrative}</span>
        </div>
      )}
      {scheduleNarrative && scheduleIndicator && (
        <div className="project-indicator-wrapper">
          <span className={`fa ${scheduleIconClass}`} style={{ color: scheduleIconColor }}></span>
          <span>{scheduleNarrative}</span>
        </div>
      )}
      {optionalNarrative && OptionalIndicator && (
        <div className="project-indicator-wrapper">
          <span className={`fa ${optionalIconClass}`} style={{ color: optionalIconColor }}></span>
          <span>{optionalNarrative}</span>
        </div>
      )}
    </div>
  );
};

export default ProjectIndicatorNarrative;
