import { ForgeList } from '@tylertech/forge-react';
import {
  getFundFilterTitleConfig,
  getProjectFundTypeEntries,
  getShowFundTypeFilterConfig
} from '../../helper/configHelper';
import FilterOption from './FilterOption';
import useProjectFundFilter from '../../hooks/useProjectFundFilter';


interface ProjectFundFilterProps {
  type: string;
  name: string;
}

const ProjectFundFilter = () => {
  const projectFundFilterEntries: ProjectFundFilterProps[] = getProjectFundTypeEntries();
  const title = getFundFilterTitleConfig();
  const isFundFilterEnabled = getShowFundTypeFilterConfig();
  const { checkIfFiltersSelected, onSelectProjectType } = useProjectFundFilter();

  return isFundFilterEnabled && (
    <div className="project-type-wrapper">
      <h2 className="section-heading">{title}</h2>
      <div className="project-type-filter">
        <ForgeList className="project-type-list" dense={true}>
          {projectFundFilterEntries.map((config, index: number) => (
            <FilterOption
              config={config}
              index={index}
              isOptionSelected={checkIfFiltersSelected(config.type)}
              onOptionSelect={onSelectProjectType}
            />
          ))}
        </ForgeList>
      </div>
    </div>
  );
};

export default ProjectFundFilter;
