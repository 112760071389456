import _ from 'lodash';
import qs from 'qs';
import { ProjectsReducer, ReduxState } from "types/stateTypes";
import useUrlParamAdapter from '../util/urlParamAdapter';
import { ALL_PROJECTS_CATEGORY_ID, DEFAULT_SEARCH_FIELD, HOME_TAB } from '../appConstants';

export const updateStoreToUrl = (state: any) => {
  const { encodeCategoryParams } = useUrlParamAdapter();
  let stateWithStoreValues: { [key: string]: any } = {};

  const searchField = _.get(state, 'search.searchField', undefined);
  const selectedFilterStages = _.get(state, 'projectFilter.selectedFilterStages', undefined);
  const selectedMatrixId = _.get(state, 'projectFilter.matrixId', undefined);
  const selectedFilterFunds = _.get(state, 'projectFilter.selectedFilterFunds', undefined);
  const currentTab = _.get(state, 'projectFilter.currentTab', undefined);
  const selectedFilterCategories = _.get(state, 'projectFilter.selectedFilterCategories', undefined);
  const searchText = _.get(state, 'search.searchText', undefined);
  const selectedLocationId = _.get(state, 'projectFilter.locationId', undefined);

  stateWithStoreValues['categoryId'] = encodeCategoryParams(selectedFilterCategories);
  stateWithStoreValues['stageId'] = !_.isEmpty(selectedFilterStages) ? encodeURIComponent(selectedFilterStages.join(',')) : undefined;
  stateWithStoreValues['matrixId'] = selectedMatrixId ;
  stateWithStoreValues['locationId'] = !_.isEmpty(selectedLocationId) ? selectedLocationId.join(',') : undefined;
  stateWithStoreValues['fund'] = !_.isEmpty(selectedFilterFunds) ? encodeURIComponent(selectedFilterFunds.join(',')) : undefined;
  stateWithStoreValues['tab'] = currentTab ? currentTab : undefined;
  stateWithStoreValues['searchText'] = encodeURIComponent(searchText);
  stateWithStoreValues['searchField'] = searchField === DEFAULT_SEARCH_FIELD ? undefined : searchField;

  stateWithStoreValues = _.omitBy(stateWithStoreValues, value => _.isNil(value) || (_.isEmpty(value) && !_.isNumber(value)));
  return qs.stringify(stateWithStoreValues, {
    arrayFormat: 'repeat', addQueryPrefix: true, encode: false
  });
};

export const updateStoreFromUrl = (initialState: Partial<ReduxState>, urlParams: any): ReduxState => {
  const { decodeCategoryParams } = useUrlParamAdapter();

  const locationState = Object.fromEntries(new URLSearchParams(urlParams));
  const tab = _.get(locationState, 'tab', HOME_TAB);
  const searchField = _.get(locationState, 'searchField', DEFAULT_SEARCH_FIELD);
  const searchText = decodeURIComponent(_.get(locationState, 'searchText', ''));
  const categoryId = _.get(locationState, 'categoryId', undefined);
  const matrixId =_.get(locationState, 'matrixId', undefined);
  const stageId = _.get(locationState, 'stageId', undefined);
  const fund = _.get(locationState, 'fund', undefined);
  const locationId = _.get(locationState, 'locationId', undefined);

  const selectedFilterCategories = decodeCategoryParams(categoryId);
  const selectedFilterStages = stageId ? stageId.split(',').map((item: any) => item.trim()) : [];
  const selectedFilterFunds = fund ? fund.split(',').map((item: any) => item.trim()) : [];
  const selectedLocationIds = locationId ? locationId.split(',').map((item: any) => item.trim()) : [];

  return {
    ...initialState,
    search: {
      ...initialState.search,
      searchField,
      searchText,
      showSearchContainer: initialState.search?.showSearchContainer ?? false
    },
    projectFilter: {
      ...initialState.projectFilter,
      currentTab: tab,
      selectedFilterCategories: selectedFilterCategories,
      selectedFilterStages: selectedFilterStages,
      selectedFilterFunds: selectedFilterFunds,
      selectedColumnFilters: {},
      matrixId: matrixId ? matrixId : undefined,
      locationId: selectedLocationIds,
      allProjectFilters: {
        columnFilters: false,
        projectTypeFilter: _.size(selectedFilterCategories) > 0
          && !_.isEqual(selectedFilterCategories, ALL_PROJECTS_CATEGORY_ID),
        mapFilter: false,
        stageFilter:  _.size(selectedFilterStages) > 0,
        fundFilter:  _.size(selectedFilterFunds) > 0,
        searchText: _.size(searchText) > 0
      }
    },
    projects: initialState.projects || {} as ProjectsReducer
  };
};
