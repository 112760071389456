import { ForgeAutocomplete, ForgeIcon, ForgeTextField } from '@tylertech/forge-react';
import useRemoveFocusIndicator from '../../hooks/useRemoveFocusIndicator';
import useHeaderSearch from '../../hooks/useHeaderSearch';
import { getI18nConfig } from '../../helper/serverConfigHelper';

const SearchBar = () => {
  useRemoveFocusIndicator('#solution-name-search', '.solution-library-search');
  const { clearSearch, onSearchInputChange, onSearchValueSelect,
    searchInputText, formattedProjects, inputRef, autoCompleteRef
  } = useHeaderSearch();
  const {search_placeholder_text: searchPlaceholderText} = getI18nConfig();

  return (
    <li className="search-bar" id="search-container" role="search">
        <label htmlFor="solution-name-search" className="visually-hidden">
          {searchPlaceholderText}
        </label>
        <ForgeAutocomplete
          ref={autoCompleteRef}
          debounce={200}
          id="solution-name-search"
          filterOnFocus={false}
          optionLimit="20"
          mode={'stateless'}
          allowUnmatched={true}
          constrainPopupWidth={true}
          on-forge-autocomplete-select={onSearchValueSelect}
          filterText={searchInputText}
          syncPopupWidth={true}
          filterFocusFirst={false}
          className='forge-popup-host'
          popupClasses={'search-filter-popup'}
          filter={(filterText) => {
            return formattedProjects.filter(item =>
              typeof item === 'object' && item !== null && 'value' in item &&
              item.value.toLowerCase().includes(filterText.toLowerCase())
            );
          }}>
          <ForgeTextField className='forge-popup-host solution-library-search'
            variant='plain'
          >
            <input
              aria-label={searchPlaceholderText}
              type="text"
              ref={inputRef}
              onChange={onSearchInputChange}
              placeholder={searchPlaceholderText} />
            {searchInputText &&
              <ForgeIcon name="close" slot="trailing"
                className='cursor-pointer clear-search-icon'
                onClick={clearSearch} />
            }
          </ForgeTextField>
        </ForgeAutocomplete>
    </li>
  );
};

export default SearchBar;
