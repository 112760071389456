import useAuthLogin from '../../hooks/useAuthLogin';
import {
  getConfig,
  getI18nConfig
} from '../../helper/serverConfigHelper';
import useFetchUserDetails from '../../hooks/useFetchUserDetails';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { CurrentUser } from '../../types/stateTypes';

const SignInButton = () => {
  const currentUser = useSelector<RootState>((store) => store.currentUser) as CurrentUser;
  const { loggedIn } = currentUser;
  const { show_sign_in } = getConfig();
  const { sign_in_label: signInLabel } = getI18nConfig();
  const { fetchAndUpdateUserDetails } = useFetchUserDetails();
  const { signIn } = useAuthLogin(fetchAndUpdateUserDetails);
  const isSignInHidden = show_sign_in === 'no';


  if (isSignInHidden || loggedIn) {
    return null;
  }

  return (
    <li>
      <a href="#" className="nav-links" onClick={() => signIn()}>
        {signInLabel}
      </a>
    </li>
  );
};

export default SignInButton;
