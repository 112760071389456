import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

import BreadCrumb from '../../components/BreadCrumb';
import ProjectDescriptionDetail from './ProjectDescriptionDetail';
import ProjectIndicatorNarrative from './ProjectIndicatorNarrative';
import ProjectDetailHeader from './ProjectDetailHeader';
import ProjectDetailsSidebar from './ProjectDetailsSidebar';
import useScrollBar from '../../hooks/useScrollBar';
import ProjectWebsiteLink from './ProjectWebsiteLink';
import { ProjectProp } from 'types/projectTypes';

const Project = () => {
  const { handleScrollIconClick } = useScrollBar();
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as ProjectProp;

  return !_.isEmpty(currentProject) && (
    <div className="project-details-container" id="project-details-container">
      <BreadCrumb />
      <div className="d-none d-md-block project-scroll-top-icon cursor-pointer" onClick={handleScrollIconClick}>
        <span className="fa fa-3x fa-angle-double-up"></span>
      </div>
      <div className="project-details-wrapper row">
        <div className="col-md-8 project-details-left-side">
          <ProjectDetailHeader />
          <ProjectWebsiteLink />
          <ProjectIndicatorNarrative />
          <ProjectDescriptionDetail />
        </div>
        <ProjectDetailsSidebar />
      </div>
    </div>
  );
};

export default Project;
