import _ from "lodash";

export const isViewDataUrl = (url: string) => {
  return _.last(url.split("/")) === "view-data";
};

export const sanitizeUrl = (domain: string) => {
  if (
    (domain &&
      (domain.startsWith("#!") ||
        domain.startsWith("/#!") ||
        domain.startsWith("#") ||
        domain.startsWith("/"))) ||
    /^https?:\/\//i.test(domain)
  ) {
    return domain;
  } else {
    return "http://" + domain;
  }
};
