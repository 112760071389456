import { Project } from "types/projectTypes";

export const UPDATE_PROJECT_LIST = 'UPDATE_PROJECT_LIST';
export const UPDATE_FILTERED_PROJECT_LIST = 'UPDATE_FILTERED_PROJECT_LIST';
export const UPDATE_CURRENT_PROJECT = 'UPDATE_CURRENT_PROJECT';
export const UPDATE_IS_LOADING = 'UPDATE_IS_LOADING';

export const updateProjectList = (allProjects: Project[]) => ({
  type: UPDATE_PROJECT_LIST,
  allProjects
});

export const updateFilteredProjectList = (filteredProjects: any) => ({
  type: UPDATE_FILTERED_PROJECT_LIST,
  filteredProjects
});

export const updateCurrentProject = (currentProject: any) => ({
  type: UPDATE_CURRENT_PROJECT,
  currentProject
});

export const updateIsLoading = (isLoading: boolean) => ({
  type: UPDATE_IS_LOADING,
  isLoading
});
