import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from '../ErrorPage';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  statusCode: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      statusCode: '500'
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Determine status code from error if available
    let statusCode = '500';
    if (error.message.includes('404') || (error as any).response?.status === 404) {
      statusCode = '404';
    }

    return {
      hasError: true,
      error,
      statusCode
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorPage statusCode={this.state.statusCode} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
