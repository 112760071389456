import { ForgeIcon, ForgeIconButton, ForgePaginator } from '@tylertech/forge-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';


export interface UserDataTypes {
  admin_welcome_email_sent_at: string;
  email: string;
  isAdmin: string;
  isManageBranding: string;
  isManageUser: string;
  is_email_sent: string;
  is_hidden: string;
  permission_mapping: string
  role: string;
  sent_admin_welcome_email: string;
  send_welcome_email: string;
  user_created_at: string;
  user_created_by: string;
  user_created_from: string;
  user_updated_at: string;
  welcome_email_sent_at: string;
}

interface UserTableProps {
  paginatedData: UserDataTypes[]
  renderRoleDropdown: (user: UserDataTypes, index: number, isCurrentUser: boolean) => JSX.Element
  handleDeleteUser:(isOpen: boolean, user?: UserDataTypes) => void
  setPaginatedData: (paginatedData: UserDataTypes[]) => void
  filteredUserList: UserDataTypes[]
  currentUserEmail: string
}

const UserTable = ({paginatedData, renderRoleDropdown, handleDeleteUser, setPaginatedData, filteredUserList, currentUserEmail}: UserTableProps) => {
  const [pageEntry, setPageLimit] = useState({ limit: 10, index: 0 });

  const paginateData = (limit: number, index: number) => {
    const start = index * limit;
    const end = start + limit;
    const paginatedData: UserDataTypes[] = _.slice(filteredUserList, start, end);
    console.log("paginatedData", paginatedData);
    
    setPaginatedData(paginatedData);
  };

  const onPageLimitChange = (e: CustomEvent<{ pageSize: number; pageIndex: number }>) => {
    setPageLimit({ limit: e.detail.pageSize, index: e.detail.pageIndex });
  };

  useEffect(() => {
    paginateData(pageEntry.limit, pageEntry.index);
  }, [filteredUserList, pageEntry]);

  const renderHeader = () => (
    <tr>
      <th style={{ width: '47%' }}>User Email ID</th>
      <th style={{ width: '45%' }}>Roles</th>
      <th>Actions</th>
    </tr>
  );

  const renderRowData = () => {
    if (_.isEmpty(paginatedData)) {
      return <p> No users added! </p>;
    }

    return _.map(paginatedData, (user, index) => {
      const uniqueKey = `user_${index}`;
      const isCurrentUser = user.email === currentUserEmail;
      return (
        <tr key={uniqueKey}>
          <td>{user.email}</td>
          <td>{renderRoleDropdown(user, index, isCurrentUser)}</td>
          <td>
            {!isCurrentUser && (
              <ForgeIconButton title="Delete user" variant="flat" onClick={() => handleDeleteUser(true, user)}>
                <ForgeIcon name="close" className="close-icon"></ForgeIcon>
              </ForgeIconButton>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
     <table>
        <thead>{renderHeader()}</thead>
        <tbody>{renderRowData()}</tbody>
      </table>
      {filteredUserList.length > pageEntry.limit && (
          <ForgePaginator
            total={filteredUserList.length}
            alternative={true}
            pageSize={pageEntry.limit}
            pageIndex={pageEntry.index}
            on-forge-paginator-change={onPageLimitChange}
          />
      )} 
    </>
  );
};

export default UserTable;
