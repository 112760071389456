import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  updateAllProjectFilters,
  updateCurrentTab,
  updateSelectedColumnFilters
} from "../actions/projectFilterActions";
import useDistinctData from "./data/useDistinctData";
import useLocationUpdateWithParams from "./useLocationUpdateWithParams";
import { updateCurrentProject } from "../actions/projectListActions";
import { getI18nConfig } from "../helper/serverConfigHelper";
import { RootState } from "reducers/rootReducer";
import { PROJECTS_LIST_TAB, PROJECTS_TAB } from "../appConstants";

interface ColumnDistinctValue {
  value: string; 
  isChecked: boolean;
}

const useProjectColumnFilter = (config: any) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [columnDistinctValues, setColumnDistinctValues] = useState<ColumnDistinctValue[]>([]);
  const mappedFieldName = _.get(config, 'mapped_field_name', '');
  const dispatch = useDispatch();
  const i18nStrings = getI18nConfig();
  const selectedColumnFilters: any = useSelector<RootState>((store) => store.projectFilter.selectedColumnFilters);
  const currentProject: any = useSelector<RootState>((store) => store.projects.currentProject);
  const currentTab: any = useSelector<RootState>((store) => store.projectFilter.currentTab);
  const { updateLocationWithParams } = useLocationUpdateWithParams();
  const { distinctValues } = useDistinctData({ field: mappedFieldName });

  const defaultSelectedColumnValue = _.get(config, 'default_filter_column_value', '');

  useEffect(() => {
    if (!_.isEmpty(distinctValues)) {
      const formattedDistinctValues = getFormattedColumnDistinctValues(distinctValues);
      setColumnDistinctValues(formattedDistinctValues);
    }
  }, [distinctValues]);

  const getFormattedColumnDistinctValues = (columnValues: string[]) => {
    const formattedValue = _.map(columnValues, (columnValue) => {
      return {
        name: columnValue,
        value: columnValue, 
        isChecked: defaultSelectedColumnValue === 'Select All' || defaultSelectedColumnValue === columnValue
      };
    });

    return _.sortBy(formattedValue, 'value');
  };

  const getSelectedValueCount = () => {
    const selectedColumnFilterValues = _.filter(columnDistinctValues, (columnValue) => columnValue.isChecked);
    const count = _.size(selectedColumnFilterValues);
    return count > 0 ? `(${count})` : '';
  };

  const isAllFiltersSelected = () => {
    const selectedColumnFilterValues = _.filter(columnDistinctValues, (columnValue) => columnValue.isChecked);
    return !_.isEmpty(columnDistinctValues) && _.size(selectedColumnFilterValues) === _.size(columnDistinctValues);
  };

  const handleSelectColumnFilter = (selectedConfig: any) => {
    selectedConfig.isChecked = !selectedConfig.isChecked;
    const columnValuesChecked = _.filter(columnDistinctValues, (columnValue) => columnValue.isChecked);
    const selectedColumnFilterValues: string[] = _.map(columnValuesChecked, 'value');
    let updatedFilters:any = {};
    if(!_.isEmpty(columnValuesChecked)) {
      updatedFilters = _.set(_.cloneDeep(selectedColumnFilters), config.mapped_field_name, selectedColumnFilterValues);
    }else {
      updatedFilters = _.omit(selectedColumnFilters, config.mapped_field_name);
    }
    dispatchUpdatedFilters(updatedFilters);
  };

  const handleSelectAllColumnFilters = () => {
    let updatedFilters: any = {};
    if (!isAllSelected) {
      _.map(columnDistinctValues, (columnValue) => { columnValue.isChecked = true;});
      const selectedColumnFilterValues: string[] = _.map(columnDistinctValues, 'value');
      updatedFilters = _.set(_.cloneDeep(selectedColumnFilters), config.mapped_field_name, _.cloneDeep(selectedColumnFilterValues));
    } else {
      _.map(columnDistinctValues, (columnValue) => { columnValue.isChecked = false;});
      updatedFilters = _.omit(selectedColumnFilters, config.mapped_field_name);
    }
    setIsAllSelected(!isAllSelected);
    dispatchUpdatedFilters(updatedFilters);
  };

  const dispatchUpdatedFilters = (updatedFilters: any) => {
    dispatch(updateSelectedColumnFilters(updatedFilters));
    dispatch(updateAllProjectFilters('columnFilters', _.size(updatedFilters) > 0));
    if (!_.isEmpty(currentProject) || !_.includes([PROJECTS_TAB, PROJECTS_LIST_TAB], currentTab)) {
      dispatch(updateCurrentProject({}));
      dispatch(updateCurrentTab('projects'));
      updateLocationWithParams('/projects', {});
    }
  };

  useEffect(() => {
    setIsAllSelected(isAllFiltersSelected());
  }, [selectedColumnFilters]);

  return {
    distinctValues: columnDistinctValues,
    i18nStrings,
    isAllSelected,
    getSelectedValueCount,
    isAllFiltersSelected,
    handleSelectColumnFilter,
    handleSelectAllColumnFilters
  };
};

export default useProjectColumnFilter;
