import { getConfig, getI18nConfig } from '../../helper/serverConfigHelper';
import _ from 'lodash';

const ProjectListHeader = ({ searchEntry }: any) => {
  const {
    project_name_label_text: projectNameLabelText,
    cost_status_label_text: costStatusLabelText,
    schedule_status_label_text: scheduleStatusLabelText,
  } = getI18nConfig();

  const { fields } = getConfig();
  const { 
    budget_indicator: budgetIndicator,
    schedule_indicator: scheduleIndicator
  } = fields;

  return (
    <thead>
      <tr>
        {!searchEntry?.isProject && <th>{searchEntry?.name || ''}</th>}
        <th id="project-name" colSpan={2} style={{ width:'70%' }}>
          {projectNameLabelText}
        </th>
        { !_.isEmpty(budgetIndicator) && (
          <th className="text-center" id="cost_status" style={{ minWidth: '15%' }}>
            {costStatusLabelText}
          </th>
        )}
        { !_.isEmpty(scheduleIndicator) && (
          <th className="text-center" id="schedule_status" style={{ width: '15%' }}>
            {scheduleStatusLabelText}
          </th>
        )}
      </tr>
    </thead>
  );
};

export default ProjectListHeader;
