import { useSelector } from 'react-redux';
import Paginator from './Paginator';
import _ from 'lodash';
import { useProjectList } from '../../hooks/useProjectList';
import { getItemPerPage, isPaginateProject, isShowAdvancedProjectMetrics } from '../../helper/configHelper';
import AdvanceProjectList from './AdvanceProjectList';
import ProjectListHeader from './ProjectListHeader';
import AdvanceProjectListHeader from './AdvanceProjectListHeader';
import BreadCrumb from '../../components/BreadCrumb';
import { getI18nConfig } from '../../helper/serverConfigHelper';


const ProjectList = () => {
  const filteredProjects: any = useSelector<any>((store) => store.projects.filteredProjects);
  const showAdvanceProjectList = isShowAdvancedProjectMetrics();
  const itemPerPage = getItemPerPage();
  const showPagination = isPaginateProject();
  const { project_not_found_info_text: projectNotFoundInfoText } = getI18nConfig();
  

  const {
    toggleCostVariance,
    handlePaginatorChange,
    handleRowClick,
    sortProjects,
    toggleVariance,
    sortByVariance,
    pageEntry,
    isCostSortAsc,
    isNameSortAsc,
    sortType,
    projectData,
    searchEntry
  } = useProjectList();

  const renderTableHeader = () => {
    return showAdvanceProjectList ?
      <AdvanceProjectListHeader
        toggleCostVariance={toggleCostVariance}
        sortProjects={sortProjects}
        toggleVariance={toggleVariance}
        sortByVariance={sortByVariance}
        isCostSortAsc={isCostSortAsc}
        isNameSortAsc={isNameSortAsc}
        sortType={sortType}
        searchEntry={searchEntry}
      /> : <ProjectListHeader searchEntry={searchEntry} />;
  };

  const renderErrorMessage = () => {
    if (_.isEmpty(projectData)) {
      return <p className="error-message">{projectNotFoundInfoText}</p>;
    }
  };

  return (
    <div className="project-table-wrapper">
      <BreadCrumb />
      <table className="table project-table">
        {renderTableHeader()}
        <AdvanceProjectList
          toggleCostVariance={toggleCostVariance}
          projectData={projectData}
          searchEntry={searchEntry}
          handleRowClick={handleRowClick}
        />
      </table>
      {renderErrorMessage()}
      {showPagination && itemPerPage < filteredProjects.length && (
        <Paginator
          total={_.size(filteredProjects)}
          pageSize={pageEntry.limit}
          pageIndex={pageEntry.index}
          onPageLimitChange={handlePaginatorChange}
        />
      )}
    </div>
  );
};

export default ProjectList;
