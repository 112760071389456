import classNames from 'classnames';
import { Project, Stage } from '../../types/projectTypes';
import { getProjectStagesFilters, isFinancialEnabled } from '../../helper/configHelper';
import ProjectPieCharts from '../../components/Visualization/ProjectPieCharts';
import ProjectStageTable from './ProjectStageTable';
import { getConfig, getI18nConfig } from '../../helper/serverConfigHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

const ProjectStages = () => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const projectFilterStages = getProjectStagesFilters();
  const financeEnabled = isFinancialEnabled();
  const advanceProjectList = getConfig().show_advanced_projects_list_metrics === 'show' && isFinancialEnabled();
  const { stage_label_text: stageLabelText } = getI18nConfig();

  const getStageClassNames = (stage: Stage): string => {
    return classNames('progress-bar-stage', {
      'status-active': currentProject.project_stage_id === stage.type,
    });
  };

  const solutionClass = classNames('project-stages-responsive-table', {
    'col-md-6': financeEnabled && advanceProjectList,
  });

  const renderProjectStageHeader = () => {
    return (
      <thead>
        <tr>
          {advanceProjectList && (
            <th style={{ width: '135px', display: 'block' }}></th>
          )}
          {projectFilterStages.map((stage: Stage) => (
            <th key={stage.id}>
              <div className="project-stage">
                <div className={getStageClassNames(stage)}></div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  return (
    <div className="project-stages-container mt-3">
      <h3 className='project-stages-container-header'>{stageLabelText}</h3>
      <div className="project-stages-wrapper row">
        <div className={solutionClass}>
          <table className="table project-stages-table">
              {renderProjectStageHeader()}
              <ProjectStageTable />
          </table>
        </div>
        <div className="project-pie-chart col-md-6 col-sm-12">
          <ProjectPieCharts />
        </div>
      </div>
    </div>
  );
};

export default ProjectStages;
