import { useEffect, useState, useRef } from "react";
import _ from "lodash";
import mapboxgl from "mapbox-gl";

import useMapDistrictMapShapeLayer from "./useMapDistrictMapShapeLayer";
import PopupHandler from "../handlers/PopupHandler";
import MiniMapMouseInteractionHandler from "../handlers/MiniMapMouseInteractionHandler";
import GlobalEvents from "../components/GlobalEvents";
import { getConfig, getMapBoxglAccessToken } from "../helper/serverConfigHelper";
import { getDistrictShapes } from "../data/api";
import { formatShapes } from "../helper/geoJsonMapFormatter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";
import {
  updateAllProjectFilters,
  updateCurrentTab,
  updateLocationId,
} from "../actions/projectFilterActions";
import { PROJECTS_LIST_TAB, PROJECTS_TAB } from "./../appConstants";
import { updateCurrentProject } from "./../actions/projectListActions";
import useLocationUpdateWithParams from "./useLocationUpdateWithParams";
import useReactQueryFetch from "./data/useReactQueryFetch";

const useMiniMap = () => {
  const [isMapStyleLoaded, setIsMapStyleLoaded] = useState(false);
  const customerConfigurations = getConfig();
  const miniMapContainer = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const { addDistrictShapeLayer, addDistrictLableLayer } =
    useMapDistrictMapShapeLayer();
  const selectedLocationIds: any = useSelector<RootState>(
    (store) => store.projectFilter.locationId
  );
  const currentProject: any = useSelector<RootState>(
    (store) => store.projects.currentProject
  );
  const currentTab: any = useSelector<RootState>(
    (store) => store.projectFilter.currentTab
  );
  const locationIdsRef = useRef<string[]>([]);
  const currentProjectRef = useRef<string[]>([]);

  const dispatch = useDispatch();
  const { updateLocationWithParams } = useLocationUpdateWithParams();
  const mapboxglAccessToken = getMapBoxglAccessToken();

  const { data: districtShapesData } = useReactQueryFetch({
    queryKey: "districtShapes",
    fetchFn: getDistrictShapes,
  });

  useEffect(() => {
    locationIdsRef.current = selectedLocationIds;
    currentProjectRef.current = currentProject;
  }, [selectedLocationIds, currentProject]);

  useEffect(() => {
    if (miniMapContainer.current) {
      const center = (customerConfigurations.district_map || {}).center || {
        lat: 0,
        lng: 0,
      };
      const zoom =
        Number((customerConfigurations.district_map || {}).zoom) || 6.4;

      mapboxgl.accessToken = mapboxglAccessToken;
      const mapInstance = new mapboxgl.Map({
        style: getBasicMapStyle(),
        container: miniMapContainer.current,
        zoom: zoom,
        maxZoom: 18,
        minZoom: 0,
        center: new mapboxgl.LngLat(Number(center.lng), Number(center.lat)),
        scrollZoom: false,
        dragPan: false,
        keyboard: false,
        doubleClickZoom: false,
        touchZoomRotate: false,
      });

      mapInstance.on("style.load", () => {
        addDistrictShapeLayer(mapInstance);
        addDistrictLableLayer(mapInstance);
        setIsMapStyleLoaded(true);
      });
      setMap(mapInstance);

      return () => {
        mapInstance.remove();
        GlobalEvents.off(`Map::InvalidateSize`, resizeMap);
      };
    }
  }, [miniMapContainer]);

  useEffect(() => {
    if (map) {
      addMapEventListeners(map);
      if (customerConfigurations.show_flyout_on_district_map === "show") {
        const popupHandler = new PopupHandler(map);
        new MiniMapMouseInteractionHandler(map, popupHandler);
      }
    }
  }, [map]);

  useEffect(() => {
    if (!isMapStyleLoaded) return;
    if (!_.isEmpty(districtShapesData)) {
      const districtMapSource = map?.getSource("district-map");
      if (districtMapSource) {
        (districtMapSource as mapboxgl.GeoJSONSource).setData(
          formatShapes(districtShapesData, "district_name")
        );
      }
      if (customerConfigurations.show_label_on_map === "show" && map) {
        const districtLabelSource = map.getSource("district-label");
        if (districtLabelSource) {
          (districtLabelSource as mapboxgl.GeoJSONSource).setData(
            formatShapes(districtShapesData, "district_name")
          );
        }
      }
      resizeMap();
    }
  }, [map, isMapStyleLoaded, districtShapesData]);

  useEffect(() => {
    if (_.isUndefined(selectedLocationIds) || !isMapStyleLoaded) return;
    highlightDistricts(selectedLocationIds);
  }, [selectedLocationIds, isMapStyleLoaded]);

  const addMapEventListeners = (mapInstance: mapboxgl.Map) => {
    mapInstance.on("mousemove", onMouseMoveEvent);
    mapInstance.on("mouseout", onMouseOutEvent);
    mapInstance.on("click", onMouseClick);
  };

  const onMouseClick = (e: mapboxgl.MapMouseEvent) => {
    if (map) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ["district-map-fill"],
      });
      if (!_.isEmpty(features)) {
        var selectedDistrictId = features[0]?.["properties"]?.["id"];
        highlightDistricts(selectedDistrictId);
        onDistrictSelect(selectedDistrictId);
      }
    }
  };

  const onMouseOutEvent = () => {
    if (map) {
      const districtIds = locationIdsRef.current;
      map.setFilter(
        "district-map-fill-highlight",
        ["in", "id"].concat(_.compact(districtIds))
      );
    }
  };

  const onMouseMoveEvent = (e: mapboxgl.MapMouseEvent) => {
    if (map) {
      const locationIds = locationIdsRef.current;
      var features = map.queryRenderedFeatures(e.point, {
        layers: ["district-map-fill"],
      });
      if (!_.isEmpty(features)) {
        var hoverOveredDistrictId = features[0]?.["properties"]?.["id"],
          districtIds = locationIds.concat(hoverOveredDistrictId);
        map.setFilter(
          "district-map-fill-highlight",
          ["in", "id"].concat(_.compact(districtIds))
        );
      } else {
        map.setFilter(
          "district-map-fill-highlight",
          ["in", "id"].concat(_.compact(locationIds))
        );
      }
    }
  };

  const highlightDistricts = (districtIds: string[]) => {
    const locationIds = locationIdsRef.current;
    const selectedLocationIds: string[] = _.isEmpty(locationIds)
      ? districtIds
      : _.compact(locationIds.concat(districtIds));
    if (map) {
      map.setFilter(
        "district-map-fill-highlight",
        ["in", "id"].concat(selectedLocationIds)
      );
    }
  };

  const getBasicMapStyle = (): mapboxgl.StyleSpecification => ({
    version: 8,
    glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
    sources: {},
    layers: [],
  });

  const resizeMap = () => {
    setTimeout(() => {
      if (map) {
        map.resize();
      }
    }, 500);
  };

  const onFocusDistrictSelect = (district: { district_id: string }) => {
    const locationIds = locationIdsRef.current;
    const districtId = district.district_id;
    const selectedDistrictIds = locationIds.concat(districtId);
    if (map) {
      map.setFilter(
        "district-map-fill-highlight",
        ["in", "id"].concat(_.compact(selectedDistrictIds))
      );
    }
  };

  const onFocusOutDistrictUnSelect = () => {
    if (map) {
      const locationIds = locationIdsRef.current;
      map.setFilter(
        "district-map-fill-highlight",
        ["in", "id"].concat(_.compact(locationIds))
      );
    }
  };

  const onDistrictSelect = (districtId: string) => {
    const locationIds = locationIdsRef.current;
    let selectedLocationIds = _.cloneDeep(locationIds);
    if (_.includes(selectedLocationIds, districtId)) {
      selectedLocationIds = _.pull(selectedLocationIds, districtId);
    } else {
      selectedLocationIds = _.concat(selectedLocationIds, districtId);
    }
    dispatch(updateLocationId(selectedLocationIds));
    dispatch(
      updateAllProjectFilters("mapFilter", _.size(selectedLocationIds) > 0)
    );
    if (
      !_.isEmpty(currentProjectRef.current) ||
      !_.includes([PROJECTS_TAB, PROJECTS_LIST_TAB], currentTab)
    ) {
      dispatch(updateCurrentProject({}));
      dispatch(updateCurrentTab("projects"));
      updateLocationWithParams("/projects", {});
    }
  };

  const onResetFilter = () => {
    dispatch(updateLocationId([]));
    dispatch(updateAllProjectFilters("mapFilter", false));
  };

  const canShowResetFilter = () => {
    const mapResetText = customerConfigurations.map_reset_text;
    return _.size(selectedLocationIds) > 0 && !_.isEmpty(mapResetText);
  };

  const getFilterCountText = () => {
    const selectedFilterCount = _.size(selectedLocationIds);
    return selectedFilterCount > 0 ? `(${selectedFilterCount})` : "";
  };


  GlobalEvents.on(`Map::InvalidateSize`, function () {
    resizeMap();
  });

  return {
    miniMapContainer,
    onFocusDistrictSelect,
    onFocusOutDistrictUnSelect,
    onDistrictSelect,
    onResetFilter,
    canShowResetFilter: canShowResetFilter(),
    getFilterCountText,
    districtShapesData,
  };
};

export default useMiniMap;
