import {
  ForgeButton,
  ForgeIcon,
  ForgeList,
  ForgeListItem,
  ForgePopover,
} from '@tylertech/forge-react';
import { PROFILE_OPTIONS } from '../../appConstants';
import useHeaderProfile from '../../hooks/useHeaderProfile';
import _ from 'lodash';
interface HeaderProfileProps {
  isOverflowing: boolean;
}

const HeaderProfile = (props: HeaderProfileProps) => {
  const { isOverflowing } = props;
  const {
    nickname,
    loggedIn,
    onClickProfile,
    menuItemAccessMap,
    popoverRef,
    headerDropdownIcon,
    headerBarTextColor
  } = useHeaderProfile();

  const color = isOverflowing
    ? '#FFFFFF'
    : headerBarTextColor || '#FFFFFF';

  if (!loggedIn) {
    return null;
  }

  const renderListItem = (
    option: { value: string; label: string; to?: string },
    index: number
  ) => {
    const menuValue = _.get(option, 'value', '');
    const label = _.get(option, 'label', '');
    const uniqueId = _.get(option, 'value', '') + index;

    if (!menuItemAccessMap[menuValue]) {
      return null;
    }

    return (
      <ForgeListItem
        className="cursor-pointer"
        key={uniqueId}
        onClick={() => onClickProfile(option)}
      >
        <button type="button">{label}</button>
      </ForgeListItem>
    );
  };

  const renderListItems = () => {
    return (
      <ForgeList dense="true" leadingSlot="Icon" trailingSlot="Icon">
        {_.map(PROFILE_OPTIONS, (option, index) => {
          return renderListItem(option, index);
        })}
      </ForgeList>
    );
  };

  const renderProfilePopup = () => {
    return (
      <li className="header-user-details">
        <ForgeButton id="popover-trigger" variant="flat">
          <div
            style={{ color: color, fontWeight: 400 }}
            className="header-user-name"
          >
            {nickname}
          </div>
          <ForgeIcon
            style={{ color: color, fontWeight: 400 }}
            name={headerDropdownIcon}
          />
        </ForgeButton>

        <ForgePopover
          ref={popoverRef}
          anchor="popover-trigger"
          options={{
            placement: 'bottom-end',
            animationType: 'slide',
            static: 'false',
          }}
        >
          {renderListItems()}
        </ForgePopover>
      </li>
    );
  };

  return <>{renderProfilePopup()}</>;
};

export default HeaderProfile;
