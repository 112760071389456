import { useDispatch, useSelector } from "react-redux";
import { updateAllProjectFilters, updateCurrentTab, updateSelectedFilterFunds } from "../actions/projectFilterActions";
import _ from "lodash";
import useLocationUpdateWithParams from "./useLocationUpdateWithParams";
import { updateCurrentProject } from "../actions/projectListActions";
import { RootState } from "reducers/rootReducer";
import { PROJECTS_LIST_TAB, PROJECTS_TAB } from "../appConstants";

const useProjectFundFilter = () => {
  const selectedFilterFunds: any = useSelector<RootState>((store) => store.projectFilter.selectedFilterFunds);
  const currentProject: any = useSelector<RootState>((store) => store.projects.currentProject);
  const currentTab: any = useSelector<RootState>((store) => store.projectFilter.currentTab);

  const dispatch = useDispatch();
  const { updateLocationWithParams } = useLocationUpdateWithParams();

  const checkIfFiltersSelected = (type: string) => {
    return _.includes(selectedFilterFunds, type);
  };

  const onSelectProjectType = (config: any) => {
    let updatedFilterEntries = _.cloneDeep(selectedFilterFunds);
    const isAlreadySelected = _.includes(updatedFilterEntries, config.type);
    if (isAlreadySelected) {
      updatedFilterEntries = _.pull(updatedFilterEntries, config.type);
    } else {
      updatedFilterEntries = _.concat(updatedFilterEntries, config.type);
    }
    dispatch(updateSelectedFilterFunds(updatedFilterEntries));
    dispatch(updateAllProjectFilters('fundFilter', _.size(updatedFilterEntries) > 0));
    if(!_.isEmpty(currentProject) || !_.includes([PROJECTS_TAB, PROJECTS_LIST_TAB], currentTab)) {
      dispatch(updateCurrentProject({}));
      dispatch(updateCurrentTab('projects'));
      updateLocationWithParams('/projects', {});
    }
  };

  return { checkIfFiltersSelected, onSelectProjectType };
};

export default useProjectFundFilter;
