import ColumnFilter from './ColumnFilter';
import { getProjectFiltersByColumns } from '../../helper/serverConfigHelper';

export interface IProjectColumnFiltersProps {
	type?: string;
	title?: string;
	mapped_field_name?: string;
	default_filter_column_value?: string;
}

const ProjectColumnFilters = () => {
	const projectStageFilterEntries: IProjectColumnFiltersProps[] = getProjectFiltersByColumns();

	return (
		<div className="project-type-wrapper">
			{projectStageFilterEntries.map((config) => (
				<ColumnFilter config={config}/>
			))}
		</div>
	);
};

export default ProjectColumnFilters;
