import { updateAllProjectFilters, updateCurrentTab, updateFilterSelectedCategories, updateMatixId } from '../actions/projectFilterActions';
import { getProjectTypesForMatrix } from '../helper/configHelper';
import { filterProjectsByType } from '../helper/filterProjectsHelper';
import { getMatrixCount, isAllProjectSelected } from '../helper/matrixHelper';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reducers/rootReducer';

const useProjectMatrix = () => {
  const defaultMatrixType: any = { 'categoryId': { all: [] }, name: 'All Projects' };
  const projects: any = useSelector<RootState>((store) => store.projects.allProjects);
  const [matrixCount, setMatrixCount] = useState({});
  const [matrixTotal, setMatrixTotal] = useState(0);
  const [selectedMatrix, setSelectedMatrix] = useState(defaultMatrixType);
  const matrixTypes = getProjectTypesForMatrix();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(projects)) {
      setMatrixCount(getMatrixCount(projects));
      setMatrixTotal(projects.length);
    }
  }, [projects]);

  const onAllProjectSelect = (matrixOption: any) => {
    const updatedFilters = matrixOption.categoryId;
    dispatch(updateCurrentTab("projects"));
    dispatch(updateFilterSelectedCategories(updatedFilters));
    dispatch(updateMatixId(_.toString(matrixOption.matrixId)));
    dispatch(updateAllProjectFilters('matrixId', _.size(updatedFilters) > 0));
  };

  const onMatrixFilterChange = function (event: any) {
    const selectedName = event.detail;
    const filterMatrixType = _.find(matrixTypes, { name: selectedName }) || {};
    setSelectedMatrix(filterMatrixType as any);
    const type: any = _.get(filterMatrixType, 'categoryId', '');
    const matrixFilterProjects = filterProjectsByType(projects, type);
    if (isAllProjectSelected(type)) {
      setMatrixCount(getMatrixCount(projects));
      setMatrixTotal(projects.length);
    } else {
      setMatrixCount(getMatrixCount(matrixFilterProjects));
      setMatrixTotal(matrixFilterProjects.length);
    }
  };

  return {
    matrixTypes,
    selectedMatrix,
    matrixCount,
    matrixTotal,
    onAllProjectSelect,
    onMatrixFilterChange
  };
};

export default useProjectMatrix;
