import LineChart from '../../components/Visualization/LineChart';
import { getDefaultImage, getProjectFinancialConfig, isFinancialEnabled } from '../../helper/configHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import ProjectMap from './ProjectMap';
import _ from 'lodash';
import { Project } from 'types/projectTypes';

const ProjectDetailsSidebar = () => {
  const defaultProjectImage = getDefaultImage();
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const financialConfig = getProjectFinancialConfig();
  const lineChartTitle= _.get(financialConfig, "line_chart_title", "");

  const projectImageUrl = _.isEmpty(currentProject.project_image)
    ? defaultProjectImage
    : typeof currentProject.project_image === 'string'
    ? currentProject.project_image
    : currentProject.project_image['url'];
  const showProjectImage = !_.isUndefined(currentProject.project_image) || !_.isUndefined(defaultProjectImage);
  const hasFinancialData = !_.isEmpty(currentProject) && isFinancialEnabled();

  return (
    showProjectImage && (
      <div className="col-md-4 project-details-right-side">
        {projectImageUrl && <img src={projectImageUrl} alt="Project Image" /> }
        <ProjectMap />
        <div>
          <h5>{lineChartTitle}</h5>
          {hasFinancialData && (
            <LineChart  />
          )}
        </div>
      </div>
    )
  );
};

export default ProjectDetailsSidebar;
