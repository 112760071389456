import CustomDropdownList from '../../components/Common/CustomDropdownList';
import useUserRoles from '../../hooks/useUserRoles';
import _ from 'lodash';
import UserSearch from './UserSearch';
import AddUserEmail from './AddUserEmail';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useState } from 'react';
import DeleteConfirmModal from './DeleteConfirmModal';
import UserTable, { UserDataTypes } from './UserTable';

const UserRoles = () => {
  const {
    searchEmail,
    email,
    securityRoles,
    handleChangeRole,
    onSearchByEmail,
    onChangeEmail,
    onClickSave,
    deleteUser,
    isLoading,
    currentUserEmail,
    filteredUserList,
  } = useUserRoles();

  const [paginatedData, setPaginatedData] = useState<UserDataTypes[]>([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteUserRole, setDeleteUserRole] = useState<any>({});

  const handleDeleteUser = (isOpenDelete: boolean, user?: UserDataTypes) => {
    setIsOpenDeleteModal(isOpenDelete);
    setDeleteUserRole(isOpenDelete ? user : {});
  };

  const handleConfirmDelete = () => {
    deleteUser(deleteUserRole);
    setIsOpenDeleteModal(false);
  };

  const renderRoleDropdown = (
    user: UserDataTypes,
    index: number,
    isCurrentUser: boolean
  ) => {
    const roleId = _.get(user, 'role', '');
    const uniqueKey = `role_${index}`;

    if (isCurrentUser) {
      const role = _.get(_.find(securityRoles, { role_id: roleId }), 'name');
      return <span>{role}</span>;
    }

    return (
      <CustomDropdownList
        key={uniqueKey}
        id={uniqueKey}
        value={roleId}
        valueField="role_id"
        className="p-0 d-flex"
        options={securityRoles}
        optionDisplayField="name"
        placement={'bottom-left'}
        onSelect={(option) => handleChangeRole(option, user)}
        showDescription
      />
    );
  };

  const renderUserTable = () => {
    return (
      <>
        <div className="user-roles-search px-3 mb-2 row">
          <UserSearch
            searchEmail={searchEmail}
            onSearchByEmail={onSearchByEmail}
          />
          <AddUserEmail
            email={email}
            onChangeEmail={onChangeEmail}
            onClickSave={onClickSave}
          />
        </div>
        <div className="user-roles-table forge-popup-host">
          <UserTable
            paginatedData={paginatedData}
            renderRoleDropdown={renderRoleDropdown}
            handleDeleteUser={handleDeleteUser}
            setPaginatedData={setPaginatedData}
            currentUserEmail={currentUserEmail}
            filteredUserList={filteredUserList}
          />
          <DeleteConfirmModal
            isOpenDeleteModal={isOpenDeleteModal}
            handleDeleteUser={handleDeleteUser}
            handleConfirmDelete={handleConfirmDelete}
          />
        </div>
        <LoadingSpinner isLoading={isLoading} />
      </>
    );
  };

  return <div className="user-role">{renderUserTable()}</div>;
};

export default UserRoles;
