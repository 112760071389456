class MouseInteractionHandler {
  private map: mapboxgl.Map;
  private popupHandler: any;

  constructor(map: mapboxgl.Map, popupHandler: any) {
    this.map = map;
    this.popupHandler = popupHandler;

    // Bind methods to ensure proper context
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onCanvasMouseMove = this._onCanvasMouseMove.bind(this);

    // Attach event listeners
    this.map.on('style.load', () => {
      this.map
        .on('mousemove', this._onMouseMove);
    });
  }

  _onMouseMove(e: mapboxgl.MapMouseEvent) {
    const target = e.originalEvent.target as HTMLElement;
    if (target && target.classList.contains('mapboxgl-canvas')) {
      this._onCanvasMouseMove(e);
    } else {
      // Handle cases where click is on overlay or other non-canvas elements
    }
  }

  _onCanvasMouseMove(e: mapboxgl.MapMouseEvent) {
    const hoverableLayers = [
      'pins',
      'project-multi-pins',
      'project-shape-fill',
      'project-shape-outlines',
      'project-shape-line',
    ];

    const features = this.map.queryRenderedFeatures(e.point, { layers: hoverableLayers });

    // Update cursor style
    this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';

    if (features[0] && features[0].layer && features[0].layer.id === 'pins') {
      this.popupHandler.showFeaturePopup(features[0], { isPinHovered: true }, e);
    } else if (
      features[0] &&
      features[0].layer && ['project-multi-pins', 'project-shape-fill', 'project-shape-outlines', 'project-shape-line'].includes(features[0].layer.id)
    ) {
      this.popupHandler.showFeaturePopup(features[0], { isPinHovered: false }, e);
    } else {
      this.popupHandler.hidePopups();
    }
  }

  destroy() {
    // Remove all event listeners
    this.map.on('style.load', () => {
      this.map
        .off('mousemove', this._onMouseMove);
    });
  }
}

export default MouseInteractionHandler;
