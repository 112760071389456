import MatrixTable from './MatrixTable';
import useProjectMatrix from '../../hooks/useProjectMatrix';
import ScheduleVarianceHeader from './ScheduleVarianceHeader';
import { getConfig } from '../../helper/serverConfigHelper';

const VarianceMatrix = () => {
  const showMatrix = getConfig().show_advanced_project_metrics === 'show';
  const {
    matrixTypes,
    matrixCount,
    matrixTotal,
    selectedMatrix,
    onAllProjectSelect,
    onMatrixFilterChange
  } = useProjectMatrix();

  return (
    <div className='matrix-container'>
      {showMatrix && (
        <div className='matrix-wrapper'>
          <ScheduleVarianceHeader
            matrixTypes={matrixTypes}
            selectedMatrix={selectedMatrix}
            onMatrixFilterChange={onMatrixFilterChange}
            matrixTotal={matrixTotal}
            onAllProjectSelect={onAllProjectSelect}
          />
          <MatrixTable
            selectedMatrix={selectedMatrix}
            matrixCount={matrixCount}
            onAllProjectSelect={onAllProjectSelect}
          />
        </div>
      )}
    </div>
  );
};

export default VarianceMatrix;
