import { KeyboardEvent } from "react";

export const ENTER_BUTTON = "Enter";
export const ESCAPE_BUTTON = "Escape";
export const SPACE_BUTTON = "Space";

export const isEnterButtonPressed = (e: KeyboardEvent) => {
  return e.key === ENTER_BUTTON;
};

export const isSpaceButtonPressed = (e: KeyboardEvent) => {
  return e.code === SPACE_BUTTON;
};

export const isEscapeButtonPressed = (e: KeyboardEvent) => {
  return e.key === ESCAPE_BUTTON;
};
