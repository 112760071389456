import { formatValueBasedOnType } from './../helper/projectUtilHelper';
import { getConfig, getFlyoutEntries } from './../helper/serverConfigHelper';
import mapboxgl from 'mapbox-gl';

class PopupHandler {
  private map: mapboxgl.Map;
  private popup: any;
  private currentPopupType: string;
  private currentPopupForMarker: any;
  private currentPopupOptions: any;

  constructor(map: mapboxgl.Map) {
    this.map = map;
    this.popup = null;
    this.currentPopupType = 'markerPopup';
    this.currentPopupForMarker = null;
    this.currentPopupOptions = null;
  }

  getPopupContent(project: any) {
    const flyoutEntries = getFlyoutEntries();
    const emptyDataPhrase = getConfig().empty_data_phrase || 'N/A';
    if (!flyoutEntries || flyoutEntries.length === 0) {
      return `<div class="content-inline">${project.project_name || emptyDataPhrase}</div>`;
    }

    return flyoutEntries
      .map((entry) => {
        const fieldValue = project[entry.mapped_field_name];
        const formattedValue = formatValueBasedOnType(fieldValue, entry.format_type) || emptyDataPhrase;
        return `<div class="content-inline"><span class="type">${entry.name}</span><span class="content">${formattedValue}</span></div>`;
      })
      .join('');
  }

  getMaxWidth(e: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) {
    const pointX = e.point.x;
    const mapContainerWidth = this.map.getContainer().clientWidth;
    const betweenValue = mapContainerWidth * 0.1;
    const leftRightReduceValue = 25;
    const mapContainerLeftWidth = mapContainerWidth / 2;
    const defaultMaxWidth = mapContainerWidth / 2;

    let maxWidth =
      mapContainerLeftWidth > pointX
        ? mapContainerWidth - (pointX + leftRightReduceValue)
        : pointX - leftRightReduceValue;

    if (
      pointX >= mapContainerLeftWidth - betweenValue &&
      pointX <= mapContainerLeftWidth + betweenValue
    ) {
      maxWidth = mapContainerWidth - leftRightReduceValue * 6;
    } else {
      maxWidth = Math.max(maxWidth, defaultMaxWidth);
    }

    return Math.round(maxWidth);
  }

  showFeaturePopup(feature: any, args: any, e: any) {
    if (!feature) return;

    let coordinates;
    if (args.isPinHovered) {
      coordinates = feature.geometry.coordinates;
    } else {
      const { lng, lat } = e.lngLat || {};
      coordinates = [lng, lat];
    }

    const maxWidth = this.getMaxWidth(e);
    const popupInstance = this.onPopupCreate(0, 0, args).setLngLat(coordinates);

    let popupContent;
    if (args.isDistrict) {
      popupContent = `<div class="content-inline">${feature.properties.name}</div>`;
    } else {
      popupContent = this.getPopupContent(feature.properties);
      popupInstance.setMaxWidth(`${maxWidth}px`);
    }

    popupInstance.setHTML(popupContent).addTo(this.map);

    this.onPopupShow({
      marker: feature.properties,
      popupType: 'markerPopup',
      options: args || {},
      popup: popupInstance,
    });
  }

  hidePopups() {
    if (this.popup) {
      this.popup.remove();
      this.onPopupHide();
    }
  }

  onPopupShow({ marker, popupType, options, popup }: { marker: any; popupType: string; options: any; popup: mapboxgl.Popup }) {
    this.hidePopups();
    this.currentPopupForMarker = marker;
    this.currentPopupType = popupType;
    this.currentPopupOptions = options;
    this.popup = popup;
  }

  onPopupHide() {
    this.currentPopupForMarker = null;
    this.currentPopupType = 'markerPopup';
    this.currentPopupOptions = null;
    this.popup = null;
  }

  offsetVariant(baseOffset: [number, number], offsetX: number, offsetY: number): [number, number] {
    return [baseOffset[0] + offsetX, baseOffset[1] + offsetY];
  }

  onPopupCreate(offsetX: number, offsetY: number, args: any = {}) {
    const offset = 10;
    return new mapboxgl.Popup({
      closeButton: !!args.persistent,
      closeOnClick: !args.persistent,
      offset: {
        top: this.offsetVariant([0, offset], offsetX, offsetY),
        'top-left': this.offsetVariant([offset, offset], offsetX, offsetY),
        'top-right': this.offsetVariant([-offset, offset], offsetX, offsetY),
        'bottom': this.offsetVariant([0, -offset], offsetX, offsetY),
        'bottom-left': this.offsetVariant([offset, -offset], offsetX, offsetY),
        'bottom-right': this.offsetVariant([-offset, -offset], offsetX, offsetY),
        'left': this.offsetVariant([offset, 0], offsetX, offsetY),
        'right': this.offsetVariant([-offset, 0], offsetX, offsetY),
      },
    });
  }

  destroy() {
    // Cleanup popups and event listeners
    if (this.popup) {
      this.popup.remove();
    }
  }
}

export default PopupHandler;
