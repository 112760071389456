import React from 'react';
import PlotlyChart from './PlotlyChart';
import useBarChartData from '../../hooks/useBarChartData';
import _ from 'lodash';
import { getFinancialChartTitle } from '../../helper/configHelper';
import {  ProjectProp } from '../../types/projectTypes';

const BulletChart: React.FC<ProjectProp> = ({ project, isTableView= false, toggleCostVariance= false }) => {
  if (_.isEmpty(project) || _.isEmpty(project.financials)) {
    return null;
  }
  const financialChartTitle = getFinancialChartTitle();
  const { chartData, layout } = useBarChartData(project, isTableView, toggleCostVariance);

  if(_.isEmpty(chartData)) {
    return  null;
  }

  return (
    <>
      { !isTableView && <h5>{financialChartTitle}</h5> }
      <PlotlyChart data={chartData} layout={layout} />
    </>
  );
};

export default BulletChart;
