import  { updateStoreToUrl, updateStoreFromUrl } from './helper/urlParamsHelper';
import { AnyAction, applyMiddleware, createStore, Store } from 'redux';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import rootReducer from './reducers/rootReducer';
import _ from 'lodash';
import { getProjectFilterReducerState } from './reducers/projectFilterReducer';
import { getHeaderInitialState } from './reducers/searchReducer';
import { getProjectListInitialState } from './reducers/projectListReducer';
import { ReduxState } from 'types/stateTypes';

const history = createBrowserHistory();

const store = (props: Partial<ReduxState>): Store<ReduxState, AnyAction> => {
  const userData =  {...props.currentUser, data: _.get(props.currentUser, 'data.user', {})};
  const initialState: ReduxState = {
    ...props,
    currentUser: userData,
    projects: getProjectListInitialState(),
    projectFilter: getProjectFilterReducerState(),
    search: getHeaderInitialState(),
  } ;

const updatedInitialState: any = updateInitialStateFromUrl(initialState);
return createStore(
  rootReducer,
  updatedInitialState,
  applyMiddleware(logger, updateStateToUrl)
);
};

const updateStateToUrl = (store: any) => (next: any) => (action: any) => {
  const result = next(action);
  const pathname = location.pathname;
  const state = store.getState();
  history.push({ pathname: pathname, search: updateStoreToUrl(state) });
  return result;
};

function updateInitialStateFromUrl(initialState: ReduxState) {
  const searchParams = location.search;
  if (!_.isEmpty(searchParams)) {
    return updateStoreFromUrl(initialState, searchParams);
  }
  return initialState;
}

export default store;
