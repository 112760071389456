import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

import ProjectDetailLink from './ProjectDetailLink';
import { ProjectColumnEntry, ProjectProp } from '../../types/projectTypes';
import { formatProjectDetailEntry, isEmailField } from '../../helper/projectDetailEntryHelper';
import { ProjectInfoProps } from './ProjectInfo';

export interface ProjectDetailEntriesProps {
  projectDetail: ProjectInfoProps;
}

const ProjectDetailEntries: React.FC<ProjectDetailEntriesProps> = ({ projectDetail }) => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as ProjectProp;
  const formattedValue = formatProjectDetailEntry(projectDetail as ProjectColumnEntry, currentProject);
  const isLinkField = projectDetail.format_type === 'link' && !isEmailField(projectDetail.field_name);
  const isNotLinkField = projectDetail.format_type !== 'link';

  return (
    <div className="col-xs-6 project-detail-entry">
      <h3>{projectDetail.display_name}</h3>
      {isNotLinkField && (
        <strong>{formattedValue}</strong>
      )}
      {isLinkField && (
        <ProjectDetailLink
          projectDetail={projectDetail}
          formattedValue={formattedValue}
        />
      )}
    </div>
  );
};

export default ProjectDetailEntries;
