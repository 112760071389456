import { ForgeCircularProgress } from '@tylertech/forge-react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

interface LoadingSpinnerProps {
  isLoading?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ isLoading = false }) => {
  const showLoading = useSelector<RootState, boolean>((store) => store.projects.isLoading);

  if (!isLoading && !showLoading) {
    return null;
  }

  return (
    <div className="spinner-overlay">
      <ForgeCircularProgress
        open={true}
        determinate={false}
        progress={0.5}
      />
    </div>
  );
};

export default LoadingSpinner;


