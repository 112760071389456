import { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { isViewDataUrl, sanitizeUrl } from "../helper/layoutHelper";
import { getConfig, getI18nConfig } from "../helper/serverConfigHelper";
import { getProjectMetaData } from "../data/api";
import useReactQueryFetch from "./data/useReactQueryFetch";

const useFooterData = () => {
  const config = getConfig();
  const i18nStrings = getI18nConfig();
  const poweredByLabelText: string = i18nStrings.powered_by_label_text;
  const dataUpdatedInfoText: string = i18nStrings.data_updated_info_text;
  const showDataUpdatedMessage: boolean = config.show_data_updated_message !== "hide";
  const showLastModifiedTime: boolean = config.data_updated_message_position === "footer";
  const [lastModifiedDate, setLastModifiedDate] = useState<string>("");
  const privacyPolicy: any = config.privacy_policy || {};
  const logoUrl = (config.footer || {}).logo_url;
  const copyrightText = (config.footer || {}).copyright_text;

  const {data: projectMetaData, isLoading: isProjectsLoading} = useReactQueryFetch({
    queryKey: "projectMetaData",
    fetchFn: getProjectMetaData,
  });

  useEffect(() => {
    if (projectMetaData && !isProjectsLoading) {
      const { rowsUpdatedAt, createdAt } = projectMetaData;
      const timeStamp = rowsUpdatedAt ?? createdAt;
      if (timeStamp) {
      setLastModifiedDate(moment.unix(timeStamp).format("MMM D, YYYY"));
      }
    }
  }, [projectMetaData, isProjectsLoading]);

  const getFootorLinks = () => {
    const order: any = { one: 1, two: 2, three: 3, four: 4, five: 5 };
    let links = _.chain((config.footer || {}).links)
      .map((linkConfig, indexWord) => {
        if (_.isEmpty(linkConfig.name) || _.isEmpty(linkConfig.url)) {
          return "";
        } else {
          return {
            name: linkConfig.name,
            url: sanitizeUrl(linkConfig.url),
            position: order[indexWord],
            isViewDataUrl: isViewDataUrl(linkConfig.url),
          };
        }
      })
      .compact()
      .sortBy("position")
      .value();

    if (!_.isEmpty(privacyPolicy.name) && !_.isEmpty(privacyPolicy.link)) {
      links.push({
        name: privacyPolicy.name,
        url: sanitizeUrl(privacyPolicy.link),
        position: links.length,
        isViewDataUrl: false,
      });
      links = _.sortBy(links, ["position"]);
    }

    return links;
  };

  return {
    logoUrl,
    links: getFootorLinks(),
    poweredByLabelText,
    dataUpdatedInfoText,
    showDataUpdatedMessage,
    showLastModifiedTime,
    lastModifiedDate,
    copyrightText
   };
};
export default useFooterData;
