import React from 'react';
import UserProfile from './UserProfile';
import Branding from '../../components/Branding/Branding';
import UserRoles from './UserRoles';

interface AdminListPageProps {
  selectedMenuItem: string;
  ADMIN_MENU_ITEM: {
    USER_PROFILE: string;
    BRANDING: string;
    USER_ROLES: string;
  };
}

const AdminListPage:React.FC<AdminListPageProps> = ({selectedMenuItem, ADMIN_MENU_ITEM}) => {
  const renderUserProfile = () => {
    if (selectedMenuItem !== ADMIN_MENU_ITEM.USER_PROFILE) {
      return null;
    }

    return <UserProfile />;
  };

  const renderBranding = () => {
    if ((selectedMenuItem !== ADMIN_MENU_ITEM.BRANDING)) {
      return null;
    }
    return (
      <Branding />
    );
  };

  const renderUserRoles = () => {
    if ((selectedMenuItem !== ADMIN_MENU_ITEM.USER_ROLES)) {
      return null;
    }

    return <UserRoles />;
  };
  return (
    <div slot="body" className="admin-rightside">
     {renderUserProfile()}
     {renderBranding()}
     {renderUserRoles()}
    </div>
  );
};

export default AdminListPage;
