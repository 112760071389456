import _ from "lodash";
import { getEnableMultiCategorySupportConfig, getProjectTypesMatrix } from "./configHelper";
import { ALL_PROJECTS_CATEGORY_ID } from "./../appConstants";


export function filterProjectsByType(projects: any, categoryFilters: any) {
  const enableMultiCategorySupport = getEnableMultiCategorySupportConfig();
  return _.filter(projects, function (project) {
    const currentProjectTypes = getProjectCategories(project);
     let selectedProjectCategory;
     let isSubCategorySelected = false;
      let subCategoryTypes = (enableMultiCategorySupport == 'false' ? [project['sub_category_id']] : (project['sub_category_id'] || '').split(',').map((item: any) =>  {
          return item.trim();
      }));
    let selectedCategoriesWithoutSubCategory = _.keys(_.pickBy(categoryFilters, function (subCategories) {
        return _.isEmpty(subCategories);
      }));
    _.each(currentProjectTypes, function (projectType) {
      if (!_.isEmpty(_.intersection(subCategoryTypes, categoryFilters[projectType]))) {
        isSubCategorySelected = true;
      }
    });
    selectedProjectCategory = _.compact(_.keys(categoryFilters));
    if (selectedProjectCategory.length == 0) {
      return false;
    } else if (isSubCategorySelected) {
      return true;
    } else {
      return !_.isEmpty(_.intersection(currentProjectTypes, selectedCategoriesWithoutSubCategory));
    }
  });
};

export const getMatrixCount = (projects: any) => {
  return _.merge({ '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0 },
    _.countBy(projects, (project) => { return project.matrix_id; }));
};


export const getProjectCategories = (project: any) => {
  const enableMultiCategorySupport = getEnableMultiCategorySupportConfig();
  let currentProjectTypes = (enableMultiCategorySupport == 'false' ? [project['category_id']] : (project['category_id'] || '').split(',').map((item: any) => {
      return item.trim();
  }));
  return _.map(currentProjectTypes,(type) => {
      return type.trim();
  });
};

export function isAllProjectSelected(categoryId: any) {
  return _.isEqual(categoryId, ALL_PROJECTS_CATEGORY_ID);
}

export const currentProjectTypes = () => {
  const projectTypes = getProjectTypesMatrix();
    const matrixProjectTypes = _.map(projectTypes,(projectType: any) => {
      let hash: any = {name: projectType.name, categoryId: {}};
      hash['categoryId'][projectType.type] = _.compact(_.map(projectType.childConfigs,(childConfig) => {
        return childConfig.type;
      }));
      return hash;
    });
  return [{'categoryId' : {all: []}, name: 'All Projects'}].concat(matrixProjectTypes);
};
