import { useEffect } from 'react';
import _ from 'lodash';
import { ForgeAppBar } from '@tylertech/forge-react';
import {
  getCurrentModeAngularConstants,
  getCurrentUser,
  getI18nConfig,
} from '../../helper/serverConfigHelper';
import useAuthLogin from '../../hooks/useAuthLogin';
import { getHeaderLogoUrl } from '../../helper/configHelper';

const LoginHeader = () => {
  const { loggedIn } = getCurrentUser();
  const currentModeConstants = getCurrentModeAngularConstants();
  const { sign_in_label: signInLabel } = getI18nConfig();
  const headerLogo = getHeaderLogoUrl();
  const { signIn } = useAuthLogin(_.noop);

  if (currentModeConstants.isUnifiedMode) {
    return null;
  }

  useEffect(() => {
    document.body.classList.add('signin-page');
    return () => {
      document.body.classList.remove('signin-page');
    };
  }, []);

  return (
    <ForgeAppBar theme="white" className="app-bar">
      <div className="logo-container app-bar-list" slot="logo">
        <img className="brand-icon" src={headerLogo} alt="Brand Logo" />
      </div>
      {!loggedIn && (
        <div slot="end" className="nav-bar-list app-bar-list">
          <ul className="navbar-right">
            <li>
              <a href="#" className="nav-links" onClick={signIn}>
                {signInLabel}
              </a>
            </li>
          </ul>
        </div>
      )}
    </ForgeAppBar>
  );
};

export default LoginHeader;
