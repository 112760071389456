import {
  ForgeIcon,
  ForgeIconButton,
  ForgeList,
  ForgeListItem,
  ForgePopover,
} from '@tylertech/forge-react';
import { useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { IPopoverComponent } from '@tylertech/forge';
import { ANIMATION_TYPE } from '../../appConstants';
import { ProjectTypeFilterProps } from './ProjectTypeFilter';

interface ProjectSubCategoryFilterProps {
  config: ProjectTypeFilterProps;
  handleSubFilterSelection?: (childConfig: ProjectTypeFilterProps, config: ProjectTypeFilterProps) => void;
  checkIfSubFilterSelected?: (childConfig: ProjectTypeFilterProps, config: ProjectTypeFilterProps) => boolean;
}

const ProjectSubCategoryFilter = (props: ProjectSubCategoryFilterProps) => {
  const { config, handleSubFilterSelection, checkIfSubFilterSelected } = props;
  const popoverRef = useRef<IPopoverComponent>(null);
  const childConfigs: ProjectTypeFilterProps[] = _.get(config, 'childConfigs', []);

  useEffect(() => {
    const popoverElement = popoverRef.current;
    if (popoverElement) {
      popoverElement.addEventListener(
        'forge-popover-beforetoggle',
        onForgePopoverBeforeToggle
      );
    }
    return () => {
      if (popoverElement) {
        popoverElement.removeEventListener(
          'forge-popover-beforetoggle',
          onForgePopoverBeforeToggle
        );
      }
    };
  }, [popoverRef]);

  const onForgePopoverBeforeToggle = (e: CustomEvent) => {
    const getBodyTag = document.querySelector('.site-wrapper');
    const getListParent = e.target ? (e.target as Element).closest('.filter-selector-container') : null;

    if (e.detail.newState === 'closed') {
      if (getBodyTag) {
        getBodyTag.classList.remove('forge-popup-overlay');
      }
    }else{
      if (getBodyTag) {
        getBodyTag.classList.add('forge-popup-overlay');
      }
      if (getListParent) {
        getListParent.classList.add('filter-selector-container-active');
      }
    }
  };

  const handleDialogDismiss = () => {
    if (popoverRef.current) {
      popoverRef.current.open = false;
    }
    const getBodyTag = document.querySelector('.site-wrapper');
    if (getBodyTag) {
      getBodyTag.classList.remove('forge-popup-overlay');
    }
  };

  const isFilterSelected = (childConfig: ProjectTypeFilterProps, config: ProjectTypeFilterProps) => {
    return checkIfSubFilterSelected ? checkIfSubFilterSelected(childConfig, config) : false;
  };

  const renderIcon = (childConfig: ProjectTypeFilterProps) => {
    const { project_icon: parentProjectIcon } = config;
    const { project_icon: childProjectIcon } = childConfig;
    const projectIcon = childProjectIcon ? childProjectIcon : parentProjectIcon;
    if (projectIcon) {
      return (
        <img
          src={projectIcon}
          alt="light_icon"
          onError={(e) => e.currentTarget.classList.add('hidden')}
        />
      );
    }
    return <span className="icon fa fa-question"></span>;
  };

  const handleKeyDown = (event: React.KeyboardEvent, childConfig: ProjectTypeFilterProps, config: ProjectTypeFilterProps) => {
		if (event.key === 'Enter' || event.key === ' ') {
      if (handleSubFilterSelection) {
        handleSubFilterSelection(childConfig, config);
      }
		}
	};

  const popOverId =
    _.toLower(_.replace(config.name, /[^A-Za-z0-9_]/g, '_')) + '_icon';

  return (
    <>
      <div className="sublist-popup-expand-icon">
        <ForgeIconButton id={popOverId} dense={true} shape={'squared'}>
          <ForgeIcon name="arrow_right" />
        </ForgeIconButton>
        <ForgePopover
          anchor={popOverId}
          ref={popoverRef}
          arrow={true}
          placement={'right-end'}
          animation-type={ANIMATION_TYPE}
        >
          <div className="custom-search-popup">
            <div className="sub-filter-modal-header">
              <strong>
                <span className="sub-filter-modal-heading">{config.name}</span>
              </strong>
              <ForgeIcon
                name="close"
                className="sub-filter-modal-close"
                onclick={() => handleDialogDismiss()}
              ></ForgeIcon>
            </div>
            <ForgeList dense="true" leadingSlot="Icon" trailingSlot="Icon">
              {childConfigs.map((childConfig, index: number) => {
                const filterClass = classNames('sub-filter-modal-container', {
                  'filter-selector-wrapper-active': isFilterSelected(
                    childConfig,
                    config
                  ),
                });
                return (
                  <ForgeListItem
                    className={filterClass}
                    key={index}
                    onClick={() =>
                      handleSubFilterSelection && handleSubFilterSelection(childConfig, config)
                    }
                  >
                    <div className="sub-filter-modal-wrapper">
                      <div className="filter-selector-label filter-selector-list">
                        <label className="all-projects-selector">
                          <span className="fa fa-check fa-checked" 
                            tabIndex={0} 
                            onKeyDown={(event) => handleKeyDown(event, childConfig, config)}></span>
                        </label>
                        {renderIcon(childConfig)}
                        <span className="sub-filter-modal-label" title={childConfig.name}>
                          {childConfig.name}
                        </span>
                      </div>
                    </div>
                  </ForgeListItem>
                );
              })}
            </ForgeList>
          </div>
        </ForgePopover>
      </div>
    </>
  );
};

export default ProjectSubCategoryFilter;
