import React from 'react';
import { ForgeDialog, ForgeScaffold, ForgeToolbar, ForgeButton, ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

interface DeleteConfirmModalProps {
  isOpenDeleteModal: boolean;
  handleDeleteUser: (isOpen: boolean) => void;
  handleConfirmDelete: () => void;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({ isOpenDeleteModal, handleDeleteUser, handleConfirmDelete }) => {
  if (!isOpenDeleteModal) return null;

  return (
    <ForgeDialog style={{ width: '200px', height: 'auto' }} open={isOpenDeleteModal} on-forge-dialog-close={() => handleDeleteUser(false)}>
      <ForgeScaffold>
        <ForgeToolbar slot="header" no-divider>
          <h1 className="forge-typography--heading4" id="dialog-title" slot="start">
            Confirm delete
          </h1>
          <ForgeIconButton slot="end" aria-label="Close dialog" onClick={() => handleDeleteUser(false)}>
            <ForgeIcon name="close" />
          </ForgeIconButton>
        </ForgeToolbar>
        <p slot="body" id="dialog-message">
          Are you sure you want to remove this user's access? They will no longer be able to view the application.
        </p>
        <ForgeToolbar slot="footer" no-divider>
          <ForgeButton slot="end" variant="outlined" onClick={() => handleDeleteUser(false)} style={{ marginRight: '16px' }}>
            Cancel
          </ForgeButton>
          <ForgeButton slot="end" variant="raised" onClick={handleConfirmDelete}>
            Ok
          </ForgeButton>
        </ForgeToolbar>
      </ForgeScaffold>
    </ForgeDialog>
  );
};

export default DeleteConfirmModal;
