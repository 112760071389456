import { getI18nConfig } from './helper/serverConfigHelper';
import { updateCurrentTab } from './actions/projectFilterActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './reducers/rootReducer';
import GlobalEvents from './components/GlobalEvents';

const ResponsiveFooter = () => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const currentTab = useSelector<RootState>((store) => store.projectFilter.currentTab);
  const dispatch = useDispatch();
  const i18nStrings = getI18nConfig();

  useEffect(() => {
    if (currentTab === 'home') {
      setSelectedIndex(0);
    } else if (currentTab === 'projects') {
      setSelectedIndex(1);
    } else if (currentTab === 'map') {
      setSelectedIndex(2);
    } else if (currentTab === 'filter') {
      setSelectedIndex(3);
    }
  }, [currentTab]);

  const listItems = [
    {
      icon: 'fa fa-home',
      text: i18nStrings.mobile_view.home_tab_title_text,
      className: 'home-view',
    },
    {
      text: i18nStrings.mobile_view.projects_list_tab_title_text,
      className: 'projects_list_view',
    },
    {
      icon: 'fa fa-map',
      text: i18nStrings.mobile_view.map_tab_title_text,
      className: 'map-view',
    },
    {
      icon: 'fa fa-filter',
      text: i18nStrings.mobile_view.filter_tab_title_text,
      className: 'filter-view',
    },
  ];

  const handleClick = (index: number) => {
    if (selectedIndex === index) {
      return;
    }
    setSelectedIndex(index === selectedIndex ? null : index);
    if (index === 0) {
      dispatch(updateCurrentTab('home'));
    } else if (index === 1) {
      dispatch(updateCurrentTab('projects'));
    } else if (index === 2) {
      setTimeout(function () {
        GlobalEvents.emit('Map::InvalidateSize::mainMap');
      }, 500);
      dispatch(updateCurrentTab('map'));
    } else if (index === 3) {
      dispatch(updateCurrentTab('filter'));
      setTimeout(function () {
        GlobalEvents.emit('Map::InvalidateSize');
      }, 500);
    }
  };


  return (
    <div className="responsive-footer">
      <ul>
        {listItems.map((item, index) => (
          <li key={index} className={selectedIndex === index ? 'selected' : ''}>
            <button onClick={() => handleClick(index)}>
              {item.icon && (
                <em className={item.icon} role="presentation" aria-hidden="true"></em>
              )}
              <strong>{item.text}</strong>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ResponsiveFooter;
