import _ from "lodash";
import moment from "moment";
import { addHttp, formatEmail } from "../util/httpUtil";
import { ProjectProp, ProjectColumnEntry, Project, Stage } from "types/projectTypes";
import { getCpeSchema } from "./serverConfigHelper";
import { getEmptyDataPhrase } from "./configHelper";
import { getFormattedCurrencyValue } from "./projectUtilHelper";
import { useSelector } from "react-redux";
import { RootState } from "reducers/rootReducer";

export const isEmailField = (field: string) => {
  const cpeSchema = getCpeSchema();
  return _.includes(["email", "Email", "@"], cpeSchema[field]);
};

export const formatProjectDetailEntry = (projectEntry: ProjectColumnEntry, project: ProjectProp): string => {
  const emptyDataPhrase = getEmptyDataPhrase() || "N/A";

  if (!project) return emptyDataPhrase;

  const mappedFieldValue = _.get(project, projectEntry.mapped_field_name);
  let formattedValue: string | undefined = mappedFieldValue;

  switch (projectEntry.format_type) {
    case "date":
      if (typeof mappedFieldValue === "string" && !isNaN(Number(mappedFieldValue))) {
        formattedValue = moment(parseInt(mappedFieldValue)).format("MMMM, YYYY");
      } else if (moment(formattedValue).isValid()) {
        formattedValue = moment(mappedFieldValue).format("MMMM, YYYY");
      }
      formattedValue = _.isEmpty(mappedFieldValue)? emptyDataPhrase : formattedValue;
      break;
    case "amount":
      formattedValue = getFormattedCurrencyValue(mappedFieldValue);
      break;
    case "link":
      const isEmailType = isEmailField(projectEntry.field_name);
      formattedValue = mappedFieldValue
        ? isEmailType
          ? formatEmail(mappedFieldValue)
          : addHttp(mappedFieldValue)
        : emptyDataPhrase;
      break;
    default:
      formattedValue = mappedFieldValue
        ? typeof mappedFieldValue === "object"
          ? JSON.stringify(mappedFieldValue)
          : mappedFieldValue
        : emptyDataPhrase;
  }

  if (
    projectEntry.alternate_column &&
    _.get(project, projectEntry.alternate_column)
  ) {
    const alternateValue = _.get(project, projectEntry.alternate_column);
    const alternateFormattedValue =
      projectEntry.format_type === "date"
        ? new Intl.DateTimeFormat("en-US", {
            month: "long",
            year: "numeric",
          }).format(new Date(alternateValue))
        : projectEntry.format_type === "amount"
        ? getFormattedCurrencyValue(alternateValue)
        : alternateValue;

    formattedValue = `${formattedValue} - ${alternateFormattedValue}`;
  }

  return formattedValue || emptyDataPhrase;
};


export const getProjectStageData = function (projectStage: Stage, key: keyof Stage) {
  const emptyDataPhrase = getEmptyDataPhrase() || "N/A";
  const project = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  if (_.isUndefined(project) || _.size(project.stages) === 0) {
    return emptyDataPhrase;
  }

  const projectStageData = _.filter(project.stages, (stage) => {
    return (
      stage.phase_step_name === projectStage.name &&
      stage.phase_id === projectStage.type
    );
  });

  if (projectStageData.length === 0 || _.isUndefined(projectStageData[0][key])) {
    return emptyDataPhrase;
  }
  const dateValue = moment(projectStageData[0][key]);

return dateValue.isValid()
  ? dateValue.format('MMM D, YYYY')
  : emptyDataPhrase;
};
