import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from 'reducers/rootReducer';
import _ from 'lodash';
import { updateCurrentProject } from '../actions/projectListActions';
import { Project, ProjectProp } from 'types/projectTypes';

const useScrollBar = () => {
  const { project_id } = useParams();
  const projects = useSelector<RootState>((store) => store.projects.allProjects) as ProjectProp[];
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const dispatch = useDispatch();

  const onUpdateScrollPosition = () => {
    const detailView = document.querySelector('#project-details-container');
    if (detailView) {
      detailView.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    updateCurrentProjectDetails(project_id);
    setTimeout(() => {
      onUpdateScrollPosition();
    }, 300);
  }, [project_id, projects]);

  const updateCurrentProjectDetails = (project_id: any) => {
    if(_.isEmpty(currentProject)) {
      const projectId = project_id.replace(/~/g, '/');
      const project = _.find(projects, (project: Project) => project.project_id === projectId);
      dispatch(updateCurrentProject(project));
    }
  };

  const handleScrollIconClick = () => {
    const siteWrapper = document.querySelector('.site-wrapper');
    if (siteWrapper) {
      siteWrapper.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };
  return { handleScrollIconClick};
};

export default useScrollBar;
