import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterParam, getFilterProjects } from '../helper/filterProjectsHelper';
import { updateFilteredProjectList } from '../actions/projectListActions';
import { RootState } from "../reducers/rootReducer";
import { Project } from '../types/projectTypes';
import { updateTotalProjects } from '../helper/projectMapMarkerHelper';

const useFilterProjectsWithParams = () => {
	const dispatch = useDispatch();
	const selectedFilterCategories = useSelector<RootState>((store) => store.projectFilter.selectedFilterCategories);
	const selectedFilterStages = useSelector<RootState>((store) => store.projectFilter.selectedFilterStages);
	const selectedFilterFunds = useSelector<RootState>((store) => store.projectFilter.selectedFilterFunds);
	const selectedColumnFilters = useSelector<RootState>((store) => store.projectFilter.selectedColumnFilters);
	const currentTab = useSelector<RootState>((store) => store.projectFilter.currentTab);
	const searchText = useSelector<RootState>((store) => store.search.searchText);
	const searchField = useSelector<RootState>((store) => store.search.searchField);
	const projects = useSelector<RootState>((store) => store.projects.allProjects);
	const matrixId = useSelector<RootState>((store) => store.projectFilter.matrixId);
	const locationId = useSelector<RootState>((store) => store.projectFilter.locationId);
	const allProjectFilters = useSelector<RootState>((store) => store.projectFilter.allProjectFilters);

	const filterParams = useMemo(() => ({
		selectedFilterCategories,
		selectedFilterStages,
		selectedFilterFunds,
		searchText,
		searchField,
		selectedColumnFilters,
		matrixId,
		locationId,
		currentTab,
		allProjectFilters
	}), [allProjectFilters]);

	useEffect(() => {
		const filteredProjects = getFilterProjects(projects as Project[], filterParams as FilterParam);
		dispatch(updateFilteredProjectList(filteredProjects));
		updateTotalProjects(filteredProjects);
	}, [projects, filterParams]);
};

export default useFilterProjectsWithParams;
