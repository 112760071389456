/**
 * Register all used Forge Icons in this file in order to get icons to render
 * throughout the platform.
 *
 * Importing the desired icon(s) and defining them
 * within the Forge icon registry should be done as early in your application
 * bootstrapping process as possible. Make sure all icons are defined, prior
 * to the page rendering or else the component will not be able to find the
 * icon data when it is instantiated.
 */
import {
    tylIconAccountBalance,
    tylIconAccountCircle,
    tylIconAdd,
    tylIconAddCircle,
    tylIconAddCircleOutline,
    tylIconArrowBack,
    tylIconEdit,
    tylIconArrowUpward,
    tylIconAttachFile,
    tylIconBarChart,
    tylIconBusiness,
    tylIconCampaign,
    tylIconChevronLeft,
    tylIconChevronRight,
    tylIconCheck,
    tylIconCheckCircleOutline,
    tylIconCheckCircle,
    tylIconClear,
    tylIconClose,
    tylIconCode,
    tylIconContentCopy,
    tylIconContentPaste,
    tylIconDangerous,
    tylIconDelete,
    tylIconDomain,
    tylIconDragHandle,
    tylIconError,
    tylIconFace,
    tylIconFilterList,
    tylIconFormatPaint,
    tylIconGroups,
    tylIconHelpOutline,
    tylIconHistory,
    tylIconHowToReg,
    tylIconInfo,
    tylIconInfoOutline,
    tylIconKeyboardArrowRight,
    tylIconKeyboardArrowUp,
    tylIconKeyboardArrowDown,
    tylIconLock,
    tylIconLogout,
    tylIconRefresh,
    tylIconMenuBook,
    tylIconMoreVert,
    tylIconMoreHoriz,
    tylIconNotifications,
    tylIconOndemandVideo,
    tylIconPeople,
    tylIconPerson,
    tylIconPrint,
    tylIconPlayArrow,
    tylIconPublic,
    tylIconRedo,
    tylIconRemoveCircle,
    tylIconRemoveCircleOutline,
    tylIconSchool,
    tylIconSearch,
    tylIconSettings,
    tylIconSwapHoriz,
    tylIconTranslate,
    tylIconUndo,
    tylIconVideoLibrary,
    tylIconViewColumn,
    tylIconTextFields,
    tylIconWarning,
    tylIconOpenInNew,
    tylIconLayers,
    tylIconMenu,
    tylIconFormatAlignCenter,
    tylIconFormatAlignLeft,
    tylIconFormatAlignRight,
    tylIconFilterAlt,
    tylIconListAlt,
    tylIconViewComfy,
    tylIconVpnKey
  } from '@tylertech/tyler-icons/standard';
  import {
    tylIconAccountGroup,
    tylIconAccountCheck,
    tylIconAlertCircleOutline,
    tylIconArrangeBringForward,
    tylIconCalendar,
    tylIconCalendarClock,
    tylIconCardPlus,
    tylIconChartBox,
    tylIconCheckboxMarkedOutline,
    tylIconCheckboxMarkedCircleOutline,
    tylIconCloseCircle,
    tylIconCursorMove,
    tylIconDatabaseEdit,
    tylIconDatabasePlus,
    tylIconDockBottom,
    tylIconDotsVertical,
    tylIconFileMultipleOutline,
    tylIconFilter,
    tylIconFilterPlus,
    tylIconFilterVariantRemove,
    tylIconFormatText,
    tylIconGroup,
    tylIconHelpCircleOutline,
    tylIconLockAlert,
    tylIconLockOpen,
    tylIconPound,
    tylIconSetNone,
    tylIconSortAlphabeticalAscending,
    tylIconSortAlphabeticalDescending,
    tylIconSortAlphabeticalDescendingVariant,
    tylIconUpdate,
    tylIconVariable,
    tylIconViewColumnOutline,
    tylIconMapMarker,
    tylIconMapMarkerOutline,
    tylIconLinkVariant,
    tylIconChevronDoubleDown,
    tylIconChevronDoubleUp,
    tylIconDatabase,
    tylIconTooltipEdit,
    tylIconFilterMenu,
    tylIconSort,
    tylIconTrashCan,
    tylIconCog,
    tylIconEyeOff,
    tylIconDatabaseArrowRight,
    tylIconTransitConnectionHorizontal,
    tylIconChartTimelineVariant,
    tylIconChartTimelineVariantShimmer,
    tylIconHeadQuestionOutline,
    tylIconOfficeBuilding,
    tylIconMap,
    tylIconNewspaperVariantOutline,
    tylIconDatabaseCog,
    tylIconFormatBold,
    tylIconFormatItalic,
    tylIconPlusThick,
    tylIconAccountArrowRight
  } from '@tylertech/tyler-icons/extended';
  import {
    tylIconDownload,
    tylIconFilterVariantCheck,
    tylIconTylerTalkingTLogo,
    tylIconDatabaseFilter
  } from '@tylertech/tyler-icons/custom';
  import { IconRegistry } from '@tylertech/forge';
  
  IconRegistry.define([
    tylIconAccountBalance,
    tylIconAccountCheck,
    tylIconAccountCircle,
    tylIconAccountGroup,
    tylIconAdd,
    tylIconAddCircle,
    tylIconAddCircleOutline,
    tylIconAlertCircleOutline,
    tylIconArrowBack,
    tylIconArrangeBringForward,
    tylIconEdit,
    tylIconArrowUpward,
    tylIconAttachFile,
    tylIconBarChart,
    tylIconBusiness,
    tylIconCalendar,
    tylIconCalendarClock,
    tylIconCardPlus,
    tylIconCampaign,
    tylIconChartBox,
    tylIconCheck,
    tylIconCheckboxMarkedCircleOutline,
    tylIconCheckboxMarkedOutline,
    tylIconCheckCircleOutline,
    tylIconCheckCircle,
    tylIconChevronLeft,
    tylIconChevronRight,
    tylIconClear,
    tylIconClose,
    tylIconCloseCircle,
    tylIconCode,
    tylIconContentCopy,
    tylIconContentPaste,
    tylIconCursorMove,
    tylIconDatabase,
    tylIconDatabaseEdit,
    tylIconDatabasePlus,
    tylIconDockBottom,
    tylIconDomain,
    tylIconDownload,
    tylIconDotsVertical,
    tylIconDangerous,
    tylIconDelete,
    tylIconDragHandle,
    tylIconError,
    tylIconFace,
    tylIconFileMultipleOutline,
    tylIconFilter,
    tylIconFilterList,
    tylIconFilterPlus,
    tylIconFilterVariantCheck,
    tylIconFilterVariantRemove,
    tylIconFormatPaint,
    tylIconFormatText,
    tylIconRefresh,
    tylIconGroup,
    tylIconGroups,
    tylIconHelpCircleOutline,
    tylIconHelpOutline,
    tylIconHistory,
    tylIconHowToReg,
    tylIconInfo,
    tylIconInfoOutline,
    tylIconKeyboardArrowRight,
    tylIconKeyboardArrowUp,
    tylIconKeyboardArrowDown,
    tylIconLayers,
    tylIconLinkVariant,
    tylIconChevronDoubleDown,
    tylIconChevronDoubleUp,
    tylIconListAlt,
    tylIconLock,
    tylIconLockAlert,
    tylIconLockOpen,
    tylIconLogout,
    tylIconMapMarkerOutline,
    tylIconMenuBook,
    tylIconMoreVert,
    tylIconMoreHoriz,
    tylIconNotifications,
    tylIconOndemandVideo,
    tylIconPeople,
    tylIconPerson,
    tylIconPlayArrow,
    tylIconPound,
    tylIconPrint,
    tylIconPublic,
    tylIconRedo,
    tylIconRemoveCircle,
    tylIconRemoveCircleOutline,
    tylIconSchool,
    tylIconSearch,
    tylIconSetNone,
    tylIconSettings,
    tylIconSort,
    tylIconSortAlphabeticalAscending,
    tylIconSortAlphabeticalDescending,
    tylIconSortAlphabeticalDescendingVariant,
    tylIconSwapHoriz,
    tylIconTooltipEdit,
    tylIconTranslate,
    tylIconTylerTalkingTLogo,
    tylIconUndo,
    tylIconUpdate,
    tylIconVariable,
    tylIconViewColumn,
    tylIconViewColumnOutline,
    tylIconVideoLibrary,
    tylIconWarning,
    tylIconTextFields,
    tylIconMapMarker,
    tylIconOpenInNew,
    tylIconFilterMenu,
    tylIconMenu,
    tylIconFormatAlignCenter,
    tylIconFormatAlignLeft,
    tylIconFormatAlignRight,
    tylIconTrashCan,
    tylIconCog,
    tylIconEyeOff,
    tylIconDatabaseCog,
    tylIconTransitConnectionHorizontal,
    tylIconFilterAlt,
    tylIconChartTimelineVariant,
    tylIconChartTimelineVariantShimmer,
    tylIconHeadQuestionOutline,
    tylIconOfficeBuilding,
    tylIconDatabaseArrowRight,
    tylIconMap,
    tylIconNewspaperVariantOutline,
    tylIconDatabaseFilter,
    tylIconFormatBold,
    tylIconFormatItalic,
    tylIconPlusThick,
    tylIconViewComfy,
    tylIconAccountArrowRight,
    tylIconKeyboardArrowDown,
    tylIconVpnKey
  ]);
  