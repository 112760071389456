import { useEffect, useState } from 'react';
import useAppConfig from './data/useAppConfig';
import _ from 'lodash';
import { SITE_TYPE } from './../appConstants';

interface BrandingData {
  browserTitle?: string;
  customerFavIcon?: string;
  logo?: string;
  title?: string;
  headerTitleColor?: string;
  headerBarBackgroundColor?: string;
}

const useBranding = () => {
  const { isLoading, appConfigData, updateAppConfig } = useAppConfig();

  const [isConfigChanges, setIsConfigChanges] = useState<boolean>(false);
  const [brandingData, setBrandingData] = useState<BrandingData>({});

  const brandingConfigData: BrandingData = {
    browserTitle: appConfigData?.branding?.customer_browser_tab_title,
    customerFavIcon: appConfigData?.branding?.customer_fav_icon,
    logo: appConfigData?.branding?.logo,
    title: appConfigData?.customer_name,
    headerBarBackgroundColor: appConfigData?.header_bar_color,
    headerTitleColor: appConfigData?.customer_name_text_color,
  };

  const updateBrandingConfig =  () => {
    const brandingKeyValue = [
      { app_config_key: 'branding.customer_browser_tab_title', config_value_to_update: brandingData.browserTitle },
      { app_config_key: 'branding.customer_fav_icon', config_value_to_update: brandingData.customerFavIcon },
      { app_config_key: 'branding.logo', config_value_to_update: brandingData.logo },
      { app_config_key: 'customer_name', config_value_to_update: brandingData.title },
      { app_config_key: 'header_bar_color', config_value_to_update: brandingData.headerBarBackgroundColor },
      { app_config_key: 'customer_name_text_color', config_value_to_update: brandingData.headerTitleColor }
      // { app_config_key: 'header_bar_text_color', config_value_to_update: brandingData.headerBarTextColor }
      
    ];

    const brandingConfig = { 
      configs: JSON.stringify(brandingKeyValue),
      site_type: SITE_TYPE
    };
    updateAppConfig(brandingConfig, true);
  };

  const onSelectColorValue = (selectedColor: any, fieldName='') => {
    setBrandingData({ ...brandingData, [fieldName]: selectedColor });       
  };

  useEffect(() => {
    setIsConfigChanges(!_.isEqual(brandingData, brandingConfigData));
  }, [brandingData, brandingConfigData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string ) => {
    const { value } = e.target;
    setBrandingData({ ...brandingData, [name]: value });
  };

  useEffect(() => {
    setBrandingData(brandingConfigData);
  }, [JSON.stringify(brandingConfigData)]);

  return { 
    isConfigChanges, 
    brandingData, 
    isLoading, 
    handleInputChange,
    updateBrandingConfig,
    onSelectColorValue
  };
};

export default useBranding;
