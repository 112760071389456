import { useDispatch } from "react-redux";
import { getBellerophonConfig, updateBellerophonConfig } from "../../data/api";
import { useEffect, useState } from "react";
import { updateIsLoading } from "../../actions/projectListActions";
import useToast from "../../hooks/useToast";

const useAppConfig = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [appConfigData, setAppConfigData] = useState<any>({});
  const showMessage = useToast();
  
  const dispatch = useDispatch();
  const fetchAppConfig = async () => {
    try {
      setIsLoading(true);
      dispatch(updateIsLoading(true));
      const bellerophonConfig = await getBellerophonConfig();
      setAppConfigData(bellerophonConfig); // Replace this line with the actual fetch call
    } catch {
      setAppConfigData({});
      console.error("Error fetching app config data");
    } finally {
      setIsLoading(false);
      dispatch(updateIsLoading(false));
    }
  };

  const updateAppConfig = async (configParams: Record<string, any>, isReload: boolean = false) => {
    try {
      setIsLoading(true);
      const updatedConfig = await updateBellerophonConfig(configParams);
      fetchAppConfig();
      const message = isReload ? "Success! Branding changes saved." : "User list successfully updated.";
      showMessage(message, "success");

      if(isReload) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch {
      console.error("Error updating app config data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAppConfig();
  }, []);

  return { appConfigData, isLoading, fetchAppConfig, updateAppConfig };
};

export default useAppConfig;
