import Main from '../main';
import ReactOnRails from 'react-on-rails';
import { defineComponents } from '@tylertech/forge';
import './../forgeIcons/ForgeIcons';
import Login from '../components/Login';

defineComponents();

ReactOnRails.register({
  Main,
  Login
});
