import Home from './components/Base/Home';

interface BodyContentProps {
  isMapView: boolean;
  hideMap: boolean;
  handleClick: () => void;
}

const BodyContent = ({ isMapView, handleClick, hideMap }: BodyContentProps) => {
  return (
    <div className={`base-page ${isMapView ? 'map-view' : ''} ${hideMap ? 'hide-map' : ''}`}>
      <div className={`map-view-toggle ${isMapView ? 'map-view' : ''}`} onClick={handleClick}>
        <span className="fa fa-chevron-left"></span>
      </div>
      <div className="center-section">
        <div className="project-list-wrapper">
          <Home />
        </div>
      </div>
    </div>
  );
};

export default BodyContent;
