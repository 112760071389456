import { useEffect } from 'react';

const useRemoveForgeElements = (
    autocompleteSelector: string,
    searchFieldClass: string
): void => {
    useEffect(() => {
        const removeForgeElements = (): void => {
            const forgeTextFields = document.querySelectorAll(searchFieldClass);

            forgeTextFields.forEach((forgeTextField) => {
                const element = forgeTextField as HTMLElement;

                if (element && element.shadowRoot) {
                    const focusIndicator = element.shadowRoot.querySelector('forge-field');
                    const accessForgeField = focusIndicator?.shadowRoot?.querySelector('forge-focus-indicator');
                    const stateLayer = element.shadowRoot.querySelector('forge-state-layer');

                    if (accessForgeField) {
                        accessForgeField.remove();
                        console.log('forge-focus-indicator removed');
                    } else {
                        console.log('forge-focus-indicator not found');
                    }

                    if (stateLayer) {
                        stateLayer.remove();
                        console.log('forge-state-layer removed');
                    } else {
                        console.log('forge-state-layer not found');
                    }
                } else {
                    console.log('forge-text-field or its shadowRoot not found');
                }
            });
        };

        removeForgeElements();

        const observer = new MutationObserver(() => {
            removeForgeElements();
        });

        const forgeAutocomplete = document.querySelector(autocompleteSelector) as HTMLElement | null;
        if (forgeAutocomplete) {
            observer.observe(forgeAutocomplete, { childList: true, subtree: true });
        }

        return () => {
            observer.disconnect();
        };
    }, [autocompleteSelector, searchFieldClass]);
};

export default useRemoveForgeElements;
