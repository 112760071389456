import { formatUrl } from '../../util/httpUtil';
import useIndicatorIconConfig from '../../hooks/useIndicatorIconConfig';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { Project } from 'types/projectTypes';

const InitiativeIconLink = () => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const { iconConfig } = useIndicatorIconConfig(currentProject);

  if (!iconConfig) {
    return null;
  }

  return (
    <a href={formatUrl(iconConfig.indicator_url)} target="_blank" rel="noopener noreferrer">
      <img
        src={iconConfig.indicator_icon}
        alt="Initiative Icon"
        className="initiative-icon"
      />
    </a>
  );
};

export default InitiativeIconLink;
