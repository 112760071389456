import {
  ForgeIcon,
  ForgeTextField,
} from '@tylertech/forge-react';
import { FC, ChangeEvent } from 'react';

interface UserSearchProps {
  searchEmail: string;
  onSearchByEmail: (value: string) => void;
}

const UserSearch: FC<UserSearchProps> = ({ searchEmail, onSearchByEmail }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchByEmail(e.target.value);
  };

  return (
    <div className="col-md-5 p-l-0 pe-5">
      <ForgeTextField
        density="default"
        floatLabelType="auto"
        className="user-email-search"
      >
        <ForgeIcon slot="leading" name="search" />
        <input
          value={searchEmail}
          onChange={handleChange}
          autoComplete="off"
          type="text"
          id="search-user-by-email"
          placeholder="Filter by customer email"
        />
      </ForgeTextField>
    </div>
  );
};

export default UserSearch;
