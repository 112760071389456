export const UPDATE_CURRENT_TAB = "UPDATE_CURRENT_TAB";
export const UPDATE_SELECTED_FILTER_CATEGORIES = "UPDATE_SELECTED_FILTER_CATEGORIES";
export const UPDATE_SELECTED_FILTER_STAGES = "UPDATE_SELECTED_FILTER_STAGES";
export const UPDATE_SELECTED_FILTER_FUNDS = "UPDATE_SELECTED_FILTER_FUNDS";
export const UPDATE_SELECTED_COLUMN_FILTERS = "UPDATE_SELECTED_COLUMN_FILTERS";
export const RESET_LEFT_PANEL_FILTERS = "RESET_LEFT_PANEL_FILTERS";
export const UPDATE_ALL_PROJECT_FILTERS = "UPDATE_ALL_PROJECT_FILTERS";
export const UPDATE_MATIX_ID = "UPDATE_MATIX_ID";
export const UPDATE_LOCATION_ID = "UPDATE_LOCATION_ID";

export const updateCurrentTab = (currentTab: string) => ({
  type: UPDATE_CURRENT_TAB,
  currentTab
});

export const updateMatixId = (matrixId: string) => ({
  type: UPDATE_MATIX_ID,
  matrixId
});

export const updateFilterSelectedCategories = (selectedFilterCategories: Record<string, string[]>) => ({
  type: UPDATE_SELECTED_FILTER_CATEGORIES,
  selectedFilterCategories
});

export const updateSelectedFilterStages = (selectedFilterStages: [string]) => ({
  type: UPDATE_SELECTED_FILTER_STAGES,
  selectedFilterStages
});

export const updateSelectedFilterFunds = (selectedFilterFunds: [string]) => ({
  type: UPDATE_SELECTED_FILTER_FUNDS,
  selectedFilterFunds
});

export const updateSelectedColumnFilters = (selectedColumnFilters: [string]) => ({
  type: UPDATE_SELECTED_COLUMN_FILTERS,
  selectedColumnFilters
});

export const resetLeftPanelFilters = (resetCategories: boolean) => ({
  type: RESET_LEFT_PANEL_FILTERS,
  resetCategories
});

export const updateAllProjectFilters = (filterType: string, value: boolean) => ({
  type: UPDATE_ALL_PROJECT_FILTERS,
  filterType, value
});

export const updateLocationId = (locationId: string[]) => ({
  type: UPDATE_LOCATION_ID,
  locationId
});
