const useMapShapeOutlineLayer = () => {

  const addMapOutlineLayer = (map: mapboxgl.Map) => {
    map.addSource('outlines', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addLayer({
      'id': 'outlines-stroke',
      'type': 'line',
      'source': 'outlines',
      'paint': {
        'line-color': "#5e6569",
        'line-width': 2
      }
    });
  };
  return { addMapOutlineLayer };
};

export default useMapShapeOutlineLayer;
