import { toast, ToastOptions } from "react-toastify";

const useToast = (defaultOptions: ToastOptions = {}) => {

  const showMessage = (message: string,
    type: "success" | "error" | "warning" | "info" = "info",
    toastOptions: ToastOptions = {}) => {

    const options = { ...defaultOptions, ...toastOptions };
    
    switch (type) {
      case "success":
        toast.success(message, options);
        break;
      case "error":
        toast.error(message, options);
        break;
      case "warning":
        toast.warning(message, options);
        break;
      case "info":
      default:
        toast.info(message, options);
    }
  };

  return showMessage;
};

export default useToast;
