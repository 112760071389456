import _ from 'lodash';

const useUrlParamAdapter = () => {
  const updateParams = (params: any) => {
    const encodedParams = encodeParams(params);
    const searchParams = new URLSearchParams(encodedParams).toString();
    window.history.pushState({}, '', '?' + searchParams);
  };

  const getParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return decodeParams(Object.fromEntries(searchParams.entries()));
  };

  const gotoState = (state: any, params: any, reload: any) => {
    var urlParams = encodeParams(params);
    urlParams = _.omitBy(urlParams, _.isNil);
    const searchParams = new URLSearchParams(urlParams).toString();
    const newUrl = _.isEmpty(searchParams) ? state : `${state}?${searchParams}`;
    if (reload) {
      window.location.assign(newUrl);
    } else {
      window.history.pushState({}, '', newUrl);
    }
  };

  const decodeParams = (params: any) => {
    const decodeParams: any = {};
    decodeParams.categoryId = decodeCategoryParams(params.categoryId);
    decodeParams.stageId = _.isUndefined(params.stageId) ? [] : params.stageId.split(',').map((item: any) => item.trim());
    decodeParams.fund = _.isUndefined(params.fund) ? [] : params.fund.split(',').map((item: any) => item.trim());
    decodeParams.locationId = _.isUndefined(params.locationId) ? [] : params.locationId.split(',').map((item: any) => item.trim());
    decodeParams.matrixId = params.matrixId;
    decodeParams.projectId = params.projectId;
    decodeParams.tab = params.tab;
    decodeParams.searchText = params.searchText;
    decodeParams.searchField = params.searchField;
    return decodeParams;
  };

  const encodeParams = (params: any) => {
    const encodedParams: any = {};
    encodedParams.categoryId = encodeCategoryParams(params.categoryId);
    encodedParams.stageId = _.isEmpty(params.stageId) ? undefined : params.stageId.join(',');
    encodedParams.fund = _.isEmpty(params.fund) ? undefined : params.fund.join(',');
    encodedParams.locationId = _.isEmpty(params.locationId) ? undefined : params.locationId.join(',');
    encodedParams.matrixId = params.matrixId || undefined;
    encodedParams.projectId = params.projectId || undefined;
    encodedParams.tab = params.tab;
    encodedParams.searchText = params.searchText;
    encodedParams.searchField = params.searchField;
    return encodedParams;
  };

  const decodeCategoryParams = (categorParamString: any) => {
    const categoryHash: any = {};
    const categoryArr = _.compact((categorParamString || '').split(',').map((item: any) => item.trim()));
    if (_.isEmpty(categoryArr)) {
      return {};
    }
    _.each(categoryArr, (categoryString: any) => {
      const pieces = categoryString.split(':');
      const category = decodeURIComponent(pieces[0]);
      categoryHash[category] = [];
      if (!_.isUndefined(pieces[1])) {
        let subCategories = _.compact(pieces[1].split('|'));
        subCategories = subCategories.map((subCategory: any) => decodeURIComponent(subCategory));
        categoryHash[category] = subCategories;
      }
    });
    return categoryHash;
  };

  const encodeCategoryParams = (categoryHash: any) => {
    if (_.isEmpty(categoryHash)) {
      return undefined;
    }
    const categoriesString = _.map(categoryHash, (subCategories, category) => {
      const encodedSubCategories = subCategories.map((subCategory: any) => encodeURIComponent(subCategory));
      return encodeURIComponent(category) + ':' + (encodedSubCategories || []).join('|');
    });
    return categoriesString.join(',');
  };
  return {
    updateParams,
    getParams,
    gotoState,
    encodeParams,
    decodeParams,
    decodeCategoryParams,
    encodeCategoryParams
  };
};

export default useUrlParamAdapter;
