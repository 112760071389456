import { getI18nConfig } from '../../helper/serverConfigHelper';
import {
  getProjectId,
  getProjectTypeHeader,
  isFinancialEnabled,
} from '../../helper/configHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import InitiativeIconLink from './ProjectInitiativeIcon';
import { Project } from 'types/projectTypes';
import { createRef, useEffect } from 'react';

const ProjectDetailHeader = () => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const showProjectId = getProjectId();
  const projectTypeHeader = getProjectTypeHeader();
  const {
    last_updated_date_label_text: lastUpdatedLabel,
    class_estimate_label_text: classEstimateLael,
    project_id_label_text: projectIdLabelText
  } = getI18nConfig();
  const {
    project_id: projectId,
    project_manager: projectManger,
    class_estimate: classEstimate,
    last_updated: lastUpdated,
    project_name: projectName,
    displayConfig: { project_icon: projectIcon } = {},
  } = currentProject || {};

  const projectDetailsHeaderRef = createRef<HTMLHeadingElement>();
  useEffect(() => {
    if (projectDetailsHeaderRef.current) {
      projectDetailsHeaderRef.current.focus();
    }
  }, [projectDetailsHeaderRef]);

  return (
    <div className="project-header-wrapper  row">
      <div className="project-details-header">
        <h1 className=' col-xs-12 col-sm-9'>
          <img src={projectIcon} alt={projectName} width="50" />
          <span id="project-name" ref={projectDetailsHeaderRef} tabIndex={0} role='heading' aria-level={1}>{projectName}</span>
        </h1>
        <div className="col-xs-6 col-sm-3 pull-right ng-scope" >
          <InitiativeIconLink />
        </div>
        {projectId && showProjectId && (
          <p className='col-xs-6 col-sm-9'>
            {projectIdLabelText}: <span>{projectId}</span>
          </p>
        )}
        {projectManger && (
          <p className='col-xs-6 col-sm-9'>
            <span>{projectTypeHeader}</span>:<span>{projectManger}</span>
          </p>
        )}
        {classEstimate && isFinancialEnabled() && (
          <p className='col-xs-6 col-sm-9'>
            {classEstimateLael}: <span>{classEstimate}</span>
          </p>
        )}
        {lastUpdated && (
          <p className='col-xs-6 col-sm-9 last-updated'>
            {lastUpdatedLabel}: <span>{lastUpdated}</span>
          </p>
        )}
      </div>

    </div>
  );
};

export default ProjectDetailHeader;
