import _ from "lodash";
import {
	UPDATE_SEARCH_FIELD,
	UPDATE_SEARCH_TEXT,
	UPDATE_SHOW_SEARCH_CONTAINER
} from "../actions/searchActions";
import { SearchReducer } from "types/stateTypes";

export const getHeaderInitialState = (): SearchReducer => {
	return {
		searchField: 'project_name',
		searchText: '',
		showSearchContainer: false
	};
};

interface Action extends SearchReducer {
	type: string;
};

const searchReducer = (state = getHeaderInitialState(), action: Action): SearchReducer => {
	switch (action.type) {
		case UPDATE_SEARCH_FIELD:
			return {
				...state,
				searchField: _.get(action, 'searchField')
			};
		case UPDATE_SEARCH_TEXT:
			return {
				...state,
				searchText: _.get(action, 'searchText')
			};
		case UPDATE_SHOW_SEARCH_CONTAINER:
			return {
				...state,
				showSearchContainer: _.get(action, 'showSearchContainer', false)
			};
		default:
			return state;
	}
};

export default searchReducer;
