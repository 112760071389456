import { getVarianceMatrixStrings } from '../../helper/configHelper';
import { getI18nConfig } from '../../helper/serverConfigHelper';

interface MatrixTableProps {
  onAllProjectSelect: (params: { matrixId: number; categoryId: number }) => void;
  selectedMatrix: { categoryId: number };
  matrixCount: Record<number, number>;
}

const MatrixTable: React.FC<MatrixTableProps> = ({ onAllProjectSelect, selectedMatrix, matrixCount }) => {
  const {
    schedule_variance_label_text: scheduleVarianceText,
    cost_variance_label_text: costVarianceText
  } = getI18nConfig();

  const {
    cost_variance_row_1: costVarianceRow1,
    cost_variance_row_2: costVarianceRow2,
    cost_variance_row_3: costVarianceRow3,
    schedule_variance_column_1: scheduleVarianceColumn1,
    schedule_variance_column_2: scheduleVarianceColumn2,
    schedule_variance_column_3: scheduleVarianceColumn3
  } = getVarianceMatrixStrings();

  const getMatrixCell = (matrixId: number) => (
    <td className={`matrix-${matrixId}`} width="25%">
      <a href="#" onClick={() => onAllProjectSelect({ matrixId, categoryId: selectedMatrix.categoryId })}>
        {matrixCount[matrixId]}
      </a>
    </td>
  );

  return (
    <div className="horizontal-rule">
      <table>
        <tbody>
          <tr>
            <td></td>
            <td className="text-left heading" colSpan={2}>{scheduleVarianceText}</td>
          </tr>
          <tr>
            <td className="heading">{costVarianceText}</td>
            <td>{scheduleVarianceColumn1}</td>
            <td>{scheduleVarianceColumn2}</td>
            <td>{scheduleVarianceColumn3}</td>
          </tr>
          <tr>
            <td width="15%">{costVarianceRow1}</td>
            {getMatrixCell(1)}
            {getMatrixCell(2)}
            {getMatrixCell(3)}
          </tr>
          <tr>
            <td width="15%">{costVarianceRow2}</td>
            {getMatrixCell(4)}
            {getMatrixCell(5)}
            {getMatrixCell(6)}
          </tr>
          <tr>
            <td width="15%">{costVarianceRow3}</td>
            {getMatrixCell(7)}
            {getMatrixCell(8)}
            {getMatrixCell(9)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MatrixTable;
