import { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "../types/stateTypes";
import { sanitizeUrl } from "../helper/layoutHelper";
import { getConfig } from "../helper/serverConfigHelper";

const useHeaderLinks = () => {
  const config = getConfig();
  const [links, setLinks] = useState<Link[]>([]);
  const order: any = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
  };
  useEffect(() => {
    const newLinks: Link[] = _.chain((config.header || {}).links)
      .map((linkConfig, indexWord) => {
        if (_.isEmpty(linkConfig.name) || _.isEmpty(linkConfig.url)) {
          return "";
        } else {
          return {
            name: linkConfig.name,
            url: sanitizeUrl(linkConfig.url),
            position: order[indexWord],
          };
        }
      })
      .compact()
      .sortBy("position")
      .value();

    setLinks(newLinks);
  }, [config]);
  return {links};
};

export default useHeaderLinks;
