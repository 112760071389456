import { getCurrencySymbol } from "../../helper/configHelper";
import _ from "lodash";
import { PlotData } from "plotly.js";

export const getLineChartData = (x: Array<number | string>, y: Array<number>, name: string, color: string = "blue"):
  Partial<PlotData> => {
    const names = new Array(_.size(x)).fill(name);
    const currencySymbol = getCurrencySymbol();

  return {
    x,
    y,
    type: "scatter",
    mode: "lines",
    line:{
      width: 4,
    },
    name: name,
    marker: { color },
    customdata: names,
    hovertemplate: `%{customdata}<br> ${currencySymbol}%{y:,.0f}<br> %{x}<br> <extra></extra>`,
    hoverlabel: getTooltipLabel()
  };
};

export const getBarChartData = (x: Array<number | string>, y: Array<number>, name: string,  color: string = "orange"):
  Partial<PlotData> => {
  return {
    x,
    y,
    type: "bar",
    marker: { color },
  };
};

function getTooltipLabel() {
  return {
    bgcolor: '#166289',
    bordercolor: 'gray',
    font: {
      size: 14,
      color: 'white',
    },
  };
};
