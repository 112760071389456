import Map from '../../components/Visualization/Map';
import { getConfig } from '../../helper/serverConfigHelper';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import useRecentreButton from '../../hooks/useRecentreButton';
import classNames from 'classnames';
import { Project } from 'types/projectTypes';
import { getShapeDatasets } from '../../helper/geoJsonMapFormatter';

interface MapVisualizationProps {
  isMapView: boolean;
  hideMap: boolean;
}

const MapVisualization = (props: MapVisualizationProps) => {
  const { isMapView, hideMap } = props;
  const projects = useSelector<RootState>((store) => store.projects.filteredProjects) as Project;
  const mapConfig = getConfig().map;
  const zoom = _.toNumber(mapConfig.zoom) || 12;
  const center =  _.mapValues(mapConfig.center, _.toNumber) || { lat: 0, lng: 0 };
  const { showRecenterButton, onReCenterBtnClick } = useRecentreButton(false, 'mainMap');

  const mapClassNames = classNames('base-page map-section', {
    'map-view': isMapView
  });

  return (
    <>
      {!hideMap && (
        <div className={mapClassNames}>
          <section className="map-section">
            <Map
              isSingleProject={false}
              zoom={zoom}
              center={center}
              showShapes={true}
              projects={projects}
              mapId="mainMap"
            />
            {showRecenterButton &&
              <button id="recentreBtn" className="recenter-button"
                onClick={() => onReCenterBtnClick()}>
                <i className="icons icon-location-arrow"></i>
                <span>Re-center</span>
              </button>
            }
          </section>
        </div>
      )}
     </>
  );
};

export default MapVisualization;
