import React, { useEffect, useRef, useState } from "react";
import { ForgeListItem } from "@tylertech/forge-react";
import classNames from "classnames";
import _ from "lodash";

interface Config {
	type?: string;
	name?: string;
	isChecked?: boolean;
	value?: string;
}

interface FilterOptionProps {
	config: Config;
	index: number;
	isOptionSelected: boolean;
	onOptionSelect: (config: Config) => void;
}

const FilterOption: React.FC<FilterOptionProps> = ({
	config,
	index,
	isOptionSelected,
	onOptionSelect,
}) => {

	
	const listItemId = `${_.toLower(
		_.replace(config.name || config.value || "", /\s+/g, "_")
	)}_${index}`;

	const [isFocused, setIsFocused] = useState(false);
	
	const filterClass = classNames("filter-selector-wrapper", {
		"filter-selector-wrapper-active": isOptionSelected,
		"focused": isFocused,
	});
	const listItemRef = useRef<HTMLDivElement>(null);

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			onOptionSelect(config);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	const handleClickOutside = (e: MouseEvent) => {
		if (listItemRef.current) {
			if (listItemRef.current.contains(e.target as Node)) {
				setIsFocused(true);
			} else {
				setIsFocused(false);
			}
		}
	};

	return (
		<div className="filter-selector-container" >
			<ForgeListItem className={`d-flex ${filterClass}`} key={index} ref={listItemRef}>
				<div
					className="filter-selector-info"
					onClick={() => onOptionSelect(config)}
				>
					<div
						className="filter-selector-label filter-selector-list"
						title={config.name}
					>
						<label
							className="all-projects-selector"
							htmlFor={`${listItemId}_${index}`}
						>
							<span
								className="fa fa-check fa-icon-checked"
								tabIndex={0}
								onKeyDown={(event) => handleKeyDown(event)}
								role="checkbox"
								aria-label={`${listItemId}_${index}`}
								aria-checked={isOptionSelected}
							></span>
						</label>
						<span
							className="overflow-hidden"
							id={`${listItemId}_label_${index}`}
						>
							{config.name}
						</span>
					</div>
				</div>
			</ForgeListItem>
		</div>
	);
};
export default FilterOption;
