import { useEffect, useState } from "react";
import _ from "lodash";
import { getUserAcessData } from "../../data/api";
import { CurrentUser, UserAccessData } from "../../types/stateTypes";

const useUserAccessData = (currentUser : CurrentUser) : UserAccessData => {
  const { data, loggedIn } = currentUser || {};
  const email = _.get(data, "email", "");
  const [userAccessData, setUserAccessData] = useState({});

  const fetchUserAccessData = async () => {
    try { 
      const userAccessData = await getUserAcessData({email});
      setUserAccessData(userAccessData);
    } catch {
      setUserAccessData({});
      console.error("Error fetching user access data");
    }
  };

  useEffect(() => {
    if (!_.isEmpty(email) && loggedIn ) {
      fetchUserAccessData();
    }
  }, [loggedIn]);

  return userAccessData;
};

export default useUserAccessData;
