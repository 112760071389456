import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { CarouselOptionsTypes } from "types/configurationTypes";
import { getHomePageCarouselConfigs } from "../../helper/configHelper";

const ImageSlider = () => {
  const carouselConfigs: CarouselOptionsTypes[] = getHomePageCarouselConfigs();
  const observer = useRef<IntersectionObserver | null>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);
  const visibilityObserver = useRef<IntersectionObserver | null>(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let imageCounter = 0;
    const totalImages = carouselConfigs.length;

    // Defining updateSliderHeight inside this useEffect scope
    function updateSliderHeightInternal() {
      requestAnimationFrame(() => {
        let sliderElement = document.querySelector(".slider.animated");
        let selectedSlideElement = document.querySelector(".slider.animated .slide.selected");
  
        const img = selectedSlideElement?.querySelector("img") as HTMLImageElement;
        if (img && img.height > 10) { // Ensure we have a real height (not just the 9px default)
          const height = Math.max(img.height, 300); // Never go below min height
          (sliderElement as HTMLElement).style.height = `${height}px`;
          (selectedSlideElement as HTMLElement).style.height = `${height}px`;
        } else if (img) {
          // Retry after a delay to ensure images have their natural dimensions
          setTimeout(() => {
            if (img.height > 10) {
              const height = Math.max(img.height, 300);
              (sliderElement as HTMLElement).style.height = `${height}px`;
              (selectedSlideElement as HTMLElement).style.height = `${height}px`;
            }
          }, 200);
        }
      });
    }

    // Create an intersection observer to detect when slider becomes visible
    visibilityObserver.current = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          updateSliderHeightInternal();
        }
      });
    }, { threshold: 0.1 });
    
    if (sliderRef.current) {
      visibilityObserver.current.observe(sliderRef.current);
    }

    // Create a ResizeObserver to handle window resizing events
    resizeObserver.current = new ResizeObserver(updateSliderHeightInternal);
    if (sliderRef.current) {
      resizeObserver.current.observe(sliderRef.current);
    }

    observer.current = new IntersectionObserver(updateSliderHeightInternal);

    const handleImageLoad = () => {
      imageCounter++;
      if (imageCounter === totalImages) {
        setImagesLoaded(true);
        updateSliderHeightInternal();
      }
    };

    // Set up load event listeners on all images
    const images = document.querySelectorAll(".carousel-image");
    images.forEach((img) => {
      const imgEl = img as HTMLImageElement;
      if (imgEl.complete) {
        handleImageLoad();
      } else {
        imgEl.addEventListener("load", handleImageLoad);
      }
      observer.current?.observe(img);
    });

    // Wait for DOM to settle and check again
    const timeoutId = setTimeout(updateSliderHeightInternal, 500);

    return () => {
      clearTimeout(timeoutId);
      observer.current?.disconnect();
      resizeObserver.current?.disconnect();
      visibilityObserver.current?.disconnect();
      const images = document.querySelectorAll(".carousel-image");
      images.forEach((img) => {
        (img as HTMLImageElement).removeEventListener("load", handleImageLoad);
      });
    };
  }, [carouselConfigs]);

  const renderImages = () => {
    return _.map(carouselConfigs, (carousel, index: number) => (
      <img
        loading="eager"
        alt={`Carousel Image ${index +1}`}
        key={index}
        className="img-responsive carousel-image"
        src={_.get(carousel, "image_url")}
      />
    ));
  };

  const isSingleImage = _.size(carouselConfigs) <= 1;

  // Define updateSliderHeight for event handlers - this mirrors the function in useEffect
  const updateSliderHeight = () => {
    // Use requestAnimationFrame for smooth timing with the render cycle
    requestAnimationFrame(() => {
      let sliderElement = document.querySelector(".slider.animated");
      let selectedSlideElement = document.querySelector(".slider.animated .slide.selected");
  
      const img = selectedSlideElement?.querySelector("img") as HTMLImageElement;
      if (img && img.height > 10) { // Use a threshold to ensure we have actual height
        const height = Math.max(img.height, 300); // Never go below min height
        (sliderElement as HTMLElement).style.height = `${height}px`;
        (selectedSlideElement as HTMLElement).style.height = `${height}px`;
      } else if (img) {
        // Try again after a short delay if image height isn't available yet
        setTimeout(() => {
          if (img.height > 10) {
            const height = Math.max(img.height, 300);
            (sliderElement as HTMLElement).style.height = `${height}px`;
            (selectedSlideElement as HTMLElement).style.height = `${height}px`;
          }
        }, 200); // Give more time for the image to load its dimensions
      }
    });
  };
  
  return (
    <div ref={sliderRef} className={`image-slider ${imagesLoaded ? 'images-loaded' : ''}`} id="carousel">
      {isSingleImage ? (
        renderImages()
      ) : (
        <Carousel
          autoPlay={true}
          dynamicHeight={false}
          interval={10000}
          infiniteLoop={true}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          onSwipeStart={updateSliderHeight}
          onSwipeEnd={updateSliderHeight}
          onChange={updateSliderHeight}
        >
          {renderImages()}
        </Carousel>
      )}
    </div>
  );
};

export default ImageSlider;
