import { AppConfigurationTypes } from "types/appConfigurationTypes";
import { CurrentModeAngularConstants, ProjectFinancialEntryType, SearchEntryType } from "types/configurationTypes";
import { i18nStringsTypes } from "types/i18nStringsTypes";
import { CurrentUser } from "types/stateTypes";

interface ServerConfig {
  customerConfigurations: any;
  searchEntries: SearchEntryType[];
  i18nStrings: i18nStringsTypes;
  openDataMountPath: string;
  projectColumnFilterEntries: Record<string, any>[];
  projectDetailsEntries: Record<string, any>[];
  cpeSchema: any;
  currentModeAngularConstants: CurrentModeAngularConstants;
  isLoginPage: boolean;
  homePageContent: string;
  isAuthorized: boolean;
  currentUser: CurrentUser;
  projectFinancialEntries: Record<string, any>[];
  baseMapTheme: string;
  flyoutEntries: Record<string, any>[];
  bellerophonUrl: string;
  mapboxglAccessToken: string;
}
interface CustomWindow extends Window {
  serverConfig: ServerConfig;
}

declare let window: CustomWindow;

export const getConfig = (): AppConfigurationTypes => {
  return window.serverConfig.customerConfigurations;
};

export const getBellerophonUrl = (): string => {
  return window.serverConfig.bellerophonUrl;
};

export const getProjectFiltersByColumns = () => {
  return window.serverConfig.projectColumnFilterEntries;
};

export const getMapBoxglAccessToken = () => {
  return window.serverConfig.mapboxglAccessToken;
};

export const getOpenDataMountPath = () => {
  return window.serverConfig.openDataMountPath.replace(/\/$/,"");
};

export const getCurrentModeAngularConstants = () : CurrentModeAngularConstants => {
  return window.serverConfig.currentModeAngularConstants;
};

export const getI18nConfig = () : i18nStringsTypes => {
  return window.serverConfig.i18nStrings as any as i18nStringsTypes;
};

export const checkIfLoginPage = () => {
  return window.serverConfig.isLoginPage;
};

export const getHomePageContent = (): string => {
  return window.serverConfig.homePageContent;
};

export const checkIfIsAuthorized = (): boolean => {
  return window.serverConfig.isAuthorized;
};

export const getProjectDetailsEntries = (): any => {
  const projectDetailsEntries =  window.serverConfig.projectDetailsEntries;
  return projectDetailsEntries || [];
};

export const getCpeSchema = (): any => {
  const cpeSchema =  window.serverConfig.cpeSchema;
  return cpeSchema || {};
};

export const getConfiguredSearchEntries = () : SearchEntryType[] => {
  return window.serverConfig.searchEntries;
};

export const getCurrentUser = () : CurrentUser => {
  return window.serverConfig.currentUser;
};

export const getProjectFinancialEntries = (): ProjectFinancialEntryType[] => {
  const projectDetailsEntries =  window.serverConfig.projectFinancialEntries;
  return projectDetailsEntries || [];
};

export const getBaseMapTheme = () : string => {
  return window.serverConfig.baseMapTheme;
};

export const getFlyoutEntries = () : Record<string, any>[] => {
  return window.serverConfig.flyoutEntries;
};
