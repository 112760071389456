import React from 'react';
import PlotlyChart from './PlotlyChart';

interface PieChartProps {
  label: string;
  value: number;
  layout?: Partial<Plotly.Layout>;
  color: string;
}
const PieChart: React.FC<PieChartProps> = ({ label, value, layout, color  }) => {
  const defaultLayout = {
    showlegend: false,
    height: 250,
    width: 350,
  };

  let remainingValue = 100 - value;
  remainingValue = remainingValue < 0 ? 0 : remainingValue;
  const data = [
    {
      type: 'pie',
      labels: [label, ''],
      values: [value, remainingValue],
      text: ['', ''],
      textinfo: 'none',
      hoverinfo: 'none',
      sort: false,      
      marker: {
        colors: [color, '#ECECEC'],
      },
    },
  ] as Plotly.Data[];

  return <PlotlyChart data={data} layout={layout || defaultLayout} />;
};

export default PieChart;
