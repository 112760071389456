import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "reducers/rootReducer";
import { useMemo } from "react";
import useAppConfig from "./data/useAppConfig";
import { UserReducer } from "types/stateTypes";

function useUserProfile() {
  const { appConfigData } = useAppConfig();
  const currentUser = useSelector<RootState>((store) => store.currentUser) as UserReducer;
  const { data, isLaunchpadAdmin } = currentUser || {};

  const email: string = _.get(data, 'email', "");
  const nickName = _.get(data, 'nickname', "");
  const configUserData = useMemo(() => JSON.parse(_.get(appConfigData, 'cpe_app_users', "[]")), [appConfigData]);
  const securityRoles = useMemo(() => JSON.parse(_.get(appConfigData, 'security_roles', "[]")), [appConfigData]);

  const userData = useMemo(() => _.find(configUserData, (user) => {
    return user["email"].toLowerCase() === email.toLowerCase();
  }), [configUserData, email]);

  const role = useMemo(() => {
    const userRole = _.find(securityRoles, (role) => {
      return role["role_id"] === _.get(userData, "role", "");
    });

    const launchpadAdminRole = { name: 'Launchpad Admin', description: '' };

    return isLaunchpadAdmin && _.isUndefined(userRole) ? launchpadAdminRole : userRole;
  }, [securityRoles, userData]);

  const validateEmail = (email: string): boolean => {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const getInitialOfUserName = (userName: string) => {
    if (validateEmail(userName)) {
      return userName.substring(0, 2).toUpperCase();
    }

    return _.map(userName.split(/[ .]+/), (name, index, arr) =>
      arr.length === 1
        ? name.substring(0, 2).toUpperCase()
        : name.charAt(0).toUpperCase()
    ).join("");
  };
	

  return {
    email,
    firstTwoLetters: getInitialOfUserName(nickName),
    role
  };
}

export default useUserProfile;
