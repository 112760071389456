import { useRef, useState } from 'react';
import { ForgeAppBar, ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import { getCurrentModeAngularConstants } from '../../helper/serverConfigHelper';
import HeaderNavigation from './HeaderNavigation';
import HeaderContent from './HeaderContent';
import classNames from 'classnames';
import { getCustomerName } from '../../helper/configHelper';

const Header = () => {
  const currentModeConstants = getCurrentModeAngularConstants();
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);
  const customerName = getCustomerName();
  const appBarRef = useRef<HTMLDivElement>(null);

  const appBarClassNames = classNames('app-bar', {
    'nav-bar-open': isNavBarOpen
  });

  const handleClick = () => {
    setIsNavBarOpen(!isNavBarOpen);
  };

  const renderSideMenuButton = () => (
    <div slot="end" className="app-bar-list">
      <ForgeIconButton aria-label="nav button" shape='squared' className='responsive-nav-button' onClick={handleClick}>
        <ForgeIcon name='menu' />
      </ForgeIconButton>
    </div>
  );

  if (currentModeConstants.isUnifiedMode) {
    return null;
  }

  return (
    <>
      <HeaderNavigation />
      <ForgeAppBar ref={appBarRef} theme="white" className={appBarClassNames}>
        {renderSideMenuButton()}
        <HeaderContent
          customerName={customerName}
          isNavBarOpen={isNavBarOpen}
          appBarRef={appBarRef}
        />
      </ForgeAppBar>
    </>
  );
};

export default Header;
