import { useNavigate } from 'react-router-dom';

const useLocationUpdateWithParams = () => {
    const navigate = useNavigate();

    const updateLocationWithParams = (pathname: string, params: Record<string, string>) => {
        const newSearchParams = new URLSearchParams(window.location.search);
        Object.keys(params).forEach(key => {
            newSearchParams.set(key, params[key]);
        });
        const newUrl = `${pathname}?${newSearchParams.toString()}`;
        navigate(newUrl);
    };

    return { updateLocationWithParams };
};

export default useLocationUpdateWithParams;
