import { useSelector } from 'react-redux';
import {
  getProjectLogoImg,
  getWebsiteLabelText,
} from '../../helper/configHelper';
import { RootState } from 'reducers/rootReducer';
import { Project } from 'types/projectTypes';
import _ from 'lodash';
import { getConfig } from '../../helper/serverConfigHelper';

const ProjectWebsiteLink = () => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const projectWebsiteLogo = getProjectLogoImg();
  const websiteLabelText = getWebsiteLabelText();
  const websiteTarget = _.get(getConfig(), 'open_website_in_new_tab');
  const target = websiteTarget=== 'true' ? '_blank' : '_current'

  return currentProject.website && (
      <div className="project-website">
        {projectWebsiteLogo && (
          <img src={projectWebsiteLogo} alt="Project Website" width="20" />
        )}
        <a
          href={typeof currentProject.website === 'string' ? currentProject.website : currentProject.website.url}
          role="link"
          target={target}
          aria-label="Click to open project website"
        >
          <span>{websiteLabelText}</span>
        </a>
      </div>
  );
};

export default ProjectWebsiteLink;
