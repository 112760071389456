import ProjectInfo from './ProjectInfo';
import _ from 'lodash';
import { getI18nConfig } from '../../helper/serverConfigHelper';
import { RootState } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';
import { Project } from 'types/projectTypes';

const ProjectDescriptionDetail = () => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const { description_label_text: descriptionLabelText}  = getI18nConfig();

  return (
    <div className="project-description-container">
      {!_.isEmpty(currentProject.project_description) && (
        <div className="project-description-header">
          <h3>{descriptionLabelText}:</h3>
          <p dangerouslySetInnerHTML={{ __html: currentProject.project_description || '' }}></p>
        </div>
      )}
      <ProjectInfo />
    </div>
  );
};

export default ProjectDescriptionDetail;
