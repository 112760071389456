import _ from 'lodash';
import {
  getProjectStageColors,
  getProjectTypeColors,
  getShapeColors
} from "./configHelper";
import { getConfig } from "./serverConfigHelper";

let totalProjects: any[] = [];

export const getDistrictMarkerColor = (districtId: any, geoType = 'pin') => {
  const shapeColors = getShapeColors();
  let shape = _.find(shapeColors, (shapeColor) => {
    return shapeColor.shape_id == districtId;
  });

  if (_.isUndefined(shape) || _.isEmpty(shape.color)) {
    return getDefaultDistrictColor(geoType);
  } else {
    return shape.color;
  }
};

export const getProjectStageMarkerColor = (projectStage: any, geoType = 'pin') => {
  let projectStageShape = _.find(getProjectStageColors(), (shapeColor) => {
    return shapeColor.project_stage == projectStage;
  });

  if (_.isUndefined(projectStageShape) || _.isEmpty(projectStageShape.color)) {
    return getDefaultColor(geoType);
  } else {
    return projectStageShape.color;
  }
};

export const getProjectTypeMarkerColor = (projectType: any, geoType = 'pin') => {
  let projectTypeShape = _.find(getProjectTypeColors(), (shapeColor) => {
    return shapeColor.project_type == projectType;
  });

  if (_.isUndefined(projectTypeShape) || _.isEmpty(projectTypeShape.color)) {
    return getDefaultColor(geoType);
  } else {
    return projectTypeShape.color;
  }
};

export const getDefaultColor = (geoType: any) => {
  if(geoType == 'shape') {
    return getConfig().base_map_shape_fill_color || '#158FCF';
  } else if(geoType == 'line') {
    return getConfig().base_map_shape_outline_color || '#158FCF';
  } else {
    return getConfig().default_pin_color || '#158FCF';
  }
};

export const getDefaultDistrictColor = (geoType: any) => {
  if(geoType == 'shape') {
    return getConfig().district_map_shape_fill_color || '#158FCF';
  } else if(geoType == 'line') {
    return getConfig().district_map_shape_outline_color || '#158FCF';
  } else {
    return getConfig().default_pin_color || '#158FCF';
  }
};

export const dimensionValueFromProjectId = (currentProject: any, dimensionField: any) => {
  if (_.isUndefined(currentProject) || _.isUndefined(currentProject.project_id)) {
    return null;
  }
  let projectId = currentProject.project_id;

  return (_.find(totalProjects, (project) => {
    return project.project_id === projectId;
  }) || {})[dimensionField];
};

export const updateTotalProjects = (projects: any) => {
  totalProjects = projects;
};
