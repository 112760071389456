import { getConfig } from '../helper/serverConfigHelper';

const useMapProjectsShapeLayer = () => {
  const customerConfigurations = getConfig();
  const clusterZoomLevelConfig = parseInt(customerConfigurations.cluster_on_zoom_level);
  const clusterZoomLevel = (clusterZoomLevelConfig > 22 || clusterZoomLevelConfig <= 0) ? 1 : clusterZoomLevelConfig;

  const addProjectShapesLayer = (map: mapboxgl.Map) => {
    map.addSource('project-shapes', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addLayer({
      'id': 'project-shape-outlines',
      'type': 'line',
      'source': 'project-shapes',
      'minzoom': clusterZoomLevel,
      'paint': {
        'line-width': 2,
        'line-color': {
          'type': 'identity',
          'property': 'border_color'
        },
      }
    });

    map.addLayer({
      'id': 'project-shape-fill',
      'type': 'fill',
      'source': 'project-shapes',
      'minzoom': clusterZoomLevel,
      'paint': {
        'fill-opacity': 0.8,
        'fill-color': {
          'type': 'identity',
          'property': 'border_color'
        }
      }
    });
  };

  const addProjectLinesLayer = (map: mapboxgl.Map) => {
    map.addSource('project-lines', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addLayer({
      'id': 'project-shape-line',
      'type': 'line',
      'source': 'project-lines',
      'minzoom': clusterZoomLevel,
      'paint': {
        'line-width': 2,
        'line-color': {
          'type': 'identity',
          'property': 'border_color'
        },
      }
    });
  };

  return { addProjectShapesLayer, addProjectLinesLayer };
};

export default useMapProjectsShapeLayer;
