import { useEffect, useState } from 'react';
import { InitiativeIconConfig, Project } from '../types/projectTypes';
import { getInitiativeIcons } from '../helper/configHelper';

const useIndicatorIconConfig = (project: Project) => {
  const [iconConfig, setIconConfig] = useState<InitiativeIconConfig | null>(null);

  useEffect(() => {
    if (project?.initiative_icon) {
      const initiativeIcons = getInitiativeIcons();
      const config = initiativeIcons.find(
        (icon: any) => icon.icon_type === project.initiative_icon
      );
      setIconConfig(config || null);
    } else {
      setIconConfig(null);
    }
  }, [project]);

  return { iconConfig };
};

export default useIndicatorIconConfig;
