import _ from "lodash";
import {
  UPDATE_PROJECT_LIST,
  UPDATE_FILTERED_PROJECT_LIST,
  UPDATE_CURRENT_PROJECT,
  UPDATE_IS_LOADING
} from "../actions/projectListActions";
import { ProjectsReducer } from "types/stateTypes";

export const getProjectListInitialState = (): ProjectsReducer => {
  return {
    allProjects: [],
    filteredProjects: [],
    currentProject: {},
    isLoading: false
  };
};
interface Action extends ProjectsReducer {
  type: string;
}

const projectsReducer = (state = getProjectListInitialState(), action: Action): ProjectsReducer => {
  switch (action.type) {
    case UPDATE_PROJECT_LIST:
      return {
        ...state,
        allProjects: _.get(action, 'allProjects', []),
      };
    case UPDATE_FILTERED_PROJECT_LIST:
      return {
        ...state,
        filteredProjects: _.get(action, 'filteredProjects', [])
      };
    case UPDATE_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: _.get(action, 'currentProject', {})
      };
    case UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: _.get(action, 'isLoading', false)
      };
    default:
      return state;
  }
};

export default projectsReducer;
