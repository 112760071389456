import { ProjectProp } from '../../types/projectTypes';
import {
  getBudgetIndicatorByValue,
  getScheduleIndicatorByValue,
} from '../../helper/projectIndicatorHelper';
import { getConfig } from '../../helper/serverConfigHelper';
import _ from 'lodash';

const ProjectListIcon: React.FC<ProjectProp> = ({ project }) => {
  const { icon_class: budgetIconClass, icon_color: budgetIconColor } =
    getBudgetIndicatorByValue(project.budget_indicator);
  const { icon_class: scheduleIconClass, icon_color: scheduleIconColor } =
    getScheduleIndicatorByValue(project.schedule_indicator);
  const { fields } = getConfig();
  const {
    budget_indicator: budgetIndicator,
    schedule_indicator: scheduleIndicator,
  } = fields;
  return (
    <>
      {!_.isEmpty(budgetIndicator) && (
        <td headers="cost_status" className="text-center">
          <span
            className={`fa ${budgetIconClass}`}
            style={{ color: budgetIconColor }}
          ></span>
        </td>
      )}
      {!_.isEmpty(scheduleIndicator) && (
        <td headers="schedule_status" className="text-center">
          <span
            className={`fa ${scheduleIconClass}`}
            style={{ color: scheduleIconColor }}
          ></span>
        </td>
      )}
    </>
  );
};

export default ProjectListIcon;
