import React from 'react';
import { ForgeTextField } from '@tylertech/forge-react';

interface ForgeInputTextFieldProps {
  type?: string;
  id?: string;
  label?: string;
  placeholderLabel?: string;
  hasLabel?: boolean;
  isDisabled?: boolean;
  hasPlaceholder?: boolean;
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  helperText?: string;
  className?: string;
  hasHelperText?: boolean;
  ariaLabel?: string;
  handleOnBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ForgeInputTextField: React.FC<ForgeInputTextFieldProps> = (props) => {
  const {
    id,
    type,
    label,
    placeholderLabel,
    hasLabel,
    hasPlaceholder,
    isDisabled,
    handleOnChange,
    handleOnBlur,
    value,
    helperText,
    hasHelperText,
    className
  } = props;

  const density = 'default';
  const floatLabelType = 'auto';
  const shape = 'default';
  const invalid = false;
  const required = false;

  return (
    <ForgeTextField
      density={density}
      floatLabelType={floatLabelType}
      shape={shape}
      invalid={invalid}
      className={className}
      required={required}>
      <label>{label}</label>
      <input
        autoComplete="off"
        type={type}
        id={id}
        value={value}
        disabled={isDisabled}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        placeholder={hasPlaceholder ? placeholderLabel : undefined} />
      {hasLabel && <label htmlFor={id} slot="label">{label}</label>}
      {hasHelperText && <span slot="helper-text">{helperText}</span>}
    </ForgeTextField>
  );
};

export default ForgeInputTextField;
