import { useDispatch } from "react-redux";
import _ from "lodash";
import { getUserDetails } from "../data/api";
import { updateCurrentUser } from "../actions/userActions";
import { CurrentUser } from "../types/stateTypes";

const useFetchUserDetails = () => {

  const dispatch = useDispatch();

  const formatUserDetails = (response: any) => {
    const user: CurrentUser = {};
    if (_.isEmpty(response) || _.isEmpty(response.data)) {
      user.loggedIn = false;
      user.isLaunchpadAdmin = false;
      user.data = {};
    } else {
      user.loggedIn = true;
      user.isLaunchpadAdmin = response.data.user.isLaunchpadAdmin;
      user.data = response.data.user;
    }
    return user;
  };

  const fetchAndUpdateUserDetails = async () => {
    try {
      const response = await getUserDetails();
      const formattedUserData = formatUserDetails(response);
      dispatch(updateCurrentUser(formattedUserData));
    } catch {
      console.error("Error fetching user details");
    }
  };

  return { fetchAndUpdateUserDetails };
};

export default useFetchUserDetails;
