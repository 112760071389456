import { getI18nConfig } from '../../helper/serverConfigHelper';

export interface ErrorPageProps {
  statusCode: string;
}

const ErrorPage = (props: ErrorPageProps) => {
  const { statusCode } = props;
  const { 
    not_found_error_message_label_text,
    incomplete_request_error_message_label_text
  } = getI18nConfig();

  return (
    <div className="error-page">
      <div className="error-content">
        {statusCode === '404' ? (
          <div className="message">{not_found_error_message_label_text}</div>
        ) : (
          <div className="message">{incomplete_request_error_message_label_text}</div>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
