import { createRef, useEffect, useState } from 'react';
import _ from 'lodash';
import {
	ForgeIcon,
	ForgeList,
	ForgeListItem,
	ForgePopover
} from '@tylertech/forge-react';
import { defaultSearchMappingField, getDefaultSearchEntry, getSearchDropdownEntries } from './helpers/searchHelper';
import { SearchEntryType, SearchEntryTypeOrUndefined } from 'types/configurationTypes';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchField } from '../../actions/searchActions';
import { RootState } from 'reducers/rootReducer';
import { ANIMATION_TYPE } from '../../appConstants';

const SearchTypesDropdown = () => {
	const dispatch = useDispatch();
	const dropdownRef = createRef<HTMLDivElement>();
	const filterPopupRef = createRef<HTMLDivElement>();
	const [isOpenDialog, setIsOpenDialog] = useState(false);
  const searchField = useSelector<RootState>((store) => store.search.searchField);
	const searchEntries = getSearchDropdownEntries();
	const defaultSearchEntries = getDefaultSearchEntry();
	const [selectedSearchFieldEntity, setSelectedSearchFieldEntity] = useState(defaultSearchEntries);

	useEffect(() => {
			setSelectedSearchFieldEntity(getSearchFieldEntrys(searchEntries));
	},[searchField]);

	const getSearchFieldEntrys = (searchEntries: SearchEntryTypeOrUndefined) => {
		const searchFieldEntry = _.find(searchEntries, (entry) => entry.mapped_field_name === searchField);
		return searchFieldEntry ? searchFieldEntry : defaultSearchEntries;
	};

	const handleDialogDismiss = () => {
		setIsOpenDialog(false);
	};

	const onFilterDropDownClick = () => {
		setIsOpenDialog(!isOpenDialog);
	};

	const handleSearchFieldChange = (searchTypeEntity: SearchEntryType) => {
		setSelectedSearchFieldEntity(searchTypeEntity);
		setIsOpenDialog(false);
		const selectedMappingField = _.get(searchTypeEntity, 'mapped_field_name', '');
		const mappingField = selectedMappingField === defaultSearchMappingField ? defaultSearchMappingField : selectedMappingField;
		dispatch(updateSearchField(mappingField));
	};

	const renderSelectFilterByDropdownItems = () => {
		const searchEntries = getSearchDropdownEntries();
		return (
			<ForgeList dense="true" leadingSlot="Icon" trailingSlot="Icon">
				{_.map(searchEntries, (searchField, index) => {
					const label = _.get(searchField, 'name', '');
					return (
						<ForgeListItem
							key={index}
							className='search-filter-list-item'
							onClick={() => handleSearchFieldChange(searchField)}>
								<button className="customers-button-label">{label}</button>
						</ForgeListItem>
					);
				})}
			</ForgeList>
		);
	};

	return (
		<>
			<div className='search-by'>Search by</div>
			<div ref={filterPopupRef} className='select-customers'
				onClick={onFilterDropDownClick} role="button">
				<span>{String(_.get(selectedSearchFieldEntity, 'name', ''))}</span>
				<ForgeIcon name='keyboard_arrow_down' className='admin-customers-icon' />
			</div>
			<ForgePopover
				open={isOpenDialog}
				ref={dropdownRef}
				targetElementRef={filterPopupRef}
				onDismiss={handleDialogDismiss}
				options={{
					placement: 'bottom', ANIMATION_TYPE, static: 'false',
				}}>
				<div className="custom-search-popup">
					{renderSelectFilterByDropdownItems()}
				</div>
			</ForgePopover>
		</>
	);
};

export default SearchTypesDropdown;
