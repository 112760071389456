import { useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { getLineChartData } from "../components/Visualization/plotlyHelper";
import { getCurrencySymbol, getLineChartPrimaryColor, getLineChartSecondaryColor, getLineChartTeriaryColor, getProjectFinancialConfig } from "../helper/configHelper";
import { RootState } from "reducers/rootReducer";
import { useSelector } from "react-redux";
import { Project } from "types/projectTypes";

export const useLineChart = () => {
  const financialConfig = getProjectFinancialConfig();
  const currencySymbol = getCurrencySymbol();
  const project = useSelector<RootState>((store) => store.projects.currentProject) as Project;

  const lineChartPrimaryColor = getLineChartPrimaryColor();
  const lineChartSecondaryColor = getLineChartSecondaryColor();
  const lineChartTeriaryColor = getLineChartTeriaryColor();

  const {
    lineChartLineOne,
    lineChartLineTwo,
    lineChartLineThree,
    lineChartLineOneLabel,
    lineChartLineTwoLabel,
    lineChartLineThreeLabel,
    lineChartTitle,
  } = useMemo(() => ({
    lineChartLineOne: _.get(financialConfig, "line_chart_line_one", ""),
    lineChartLineTwo: _.get(financialConfig, "line_chart_line_two", ""),
    lineChartLineThree: _.get(financialConfig, "line_chart_line_three", ""),
    lineChartLineOneLabel: _.get(financialConfig, "line_chart_line_one_label", ""),
    lineChartLineTwoLabel: _.get(financialConfig, "line_chart_line_two_label", ""),
    lineChartLineThreeLabel: _.get(financialConfig, "line_chart_line_three_label", ""),
    lineChartTitle: _.get(financialConfig, "line_chart_title", ""),
  }), [financialConfig]);

  const { dateXValues, actualYAxisValues, approveYAxisValues, spentYAxisValues } = useMemo(() => {
    const dateXValues = _.map(
      project.financials,
      (data) => moment(data.date, "YYYY-MM-DD").format("MMM DD, YYYY")
    );
    return {
      dateXValues,
      actualYAxisValues: _.compact(_.map(project?.financials, (data) => data[lineChartLineOne])),
      approveYAxisValues: _.compact(_.map(project?.financials, (data) => data[lineChartLineTwo])),
      spentYAxisValues: _.compact(_.map(project?.financials, (data) => data[lineChartLineThree])),
    };
  }, [project.financials, lineChartLineOne, lineChartLineTwo, lineChartLineThree]);

  const plotData = useMemo(() => [
    getLineChartData(dateXValues, actualYAxisValues, lineChartLineOneLabel, lineChartPrimaryColor),
    getLineChartData(dateXValues, approveYAxisValues, lineChartLineTwoLabel, lineChartSecondaryColor),
    getLineChartData(dateXValues, spentYAxisValues, lineChartLineThreeLabel, lineChartTeriaryColor),
  ], [dateXValues, actualYAxisValues, approveYAxisValues, spentYAxisValues, 
    lineChartLineOneLabel, lineChartLineTwoLabel, lineChartLineThreeLabel]);

  const layout = useMemo(() => ({
    title: {
      // text: lineChartTitle,
      x: 0,
      xanchor: "left",
    } as any,
    margin: { l: 0, r: 10, t: 0, b: 80 },
    showlegend: false,

    xaxis: {
      tickangle: 90,
      tickfont: {
        size: 10,
      },
        showgrid: true,
        gridcolor: 'lightgray',
        gridwidth: 0.5,
        griddash: 'dot'

    } as any,
    height: 600,
    yaxis: {
      automargin: true,
      zeroline: true,
      tickprefix: _.isEmpty(currencySymbol) ? "" : currencySymbol,
      showgrid: true,
      gridcolor: 'lightgray',
      gridwidth: 0.5,
      griddash: 'dot'
    } as any,
   
  }), [lineChartTitle, currencySymbol]);

  return { 
    plotData, 
    layout, 
    actualYAxisValues, 
    approveYAxisValues, 
    spentYAxisValues, 
    lineChartLineOneLabel, 
    lineChartLineTwoLabel, 
    lineChartLineThreeLabel 
  };
};
