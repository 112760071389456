import _ from 'lodash';

export const addHttp = (domain: any): string | undefined => {
  if (!_.isUndefined(domain) || !_.isEmpty(domain)) {
    const urlDomain = domain['url'] || domain;
    let ret = '';
    if (!/^https?:\/\//i.test(urlDomain)) {
      if (/^mailto:/i.test(urlDomain) || urlDomain.startsWith('#!') || urlDomain.startsWith('/#!') || urlDomain.startsWith('#') || urlDomain.startsWith('/')) {
        ret = urlDomain;
      } else {
        ret = 'http://' + urlDomain;
      }
      return ret;
    } else {
      return urlDomain;
    }
  }
};

export const formatEmail = (email: string): string | undefined => {
  if (!_.isEmpty(email)) {
    let ret = '';
    if (/^mailto:/i.test(email)) {
      ret = email.split(':')[1];
    } else {
      ret = email;
    }
    return ret;
  }
};

export const isValidateUrl = (url: string | undefined): boolean => {
  if (_.isUndefined(url)) {
    return false;
  } else {
    return url.startsWith('/#!') || url.startsWith('#!');
  }
};

export  const formatUrl =  (url: string) => {
  return addHttp(url);
};
