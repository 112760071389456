import { useEffect, useState } from "react";
import GlobalEvents from "../components/GlobalEvents";

const useRecentreButton = (isSingleProject: boolean, mapId: string) => {
  const [showRecenterButton, setShowRecenterButton] = useState(false);

  useEffect(() => {
    GlobalEvents.on(`Map::CenterOrZoomChanged::${mapId}`, function (showRecenterButton) {
      setShowRecenterButton(showRecenterButton);
    });
    return () => {
      // GlobalEvents.off("Map::CenterOrZoomChanged", setShowRecenterButton);
      setShowRecenterButton(false);
    };
  }, [mapId]);

  const onReCenterBtnClick = () => {
    setShowRecenterButton(false);
    GlobalEvents.emit(`ResetMapBounds::${mapId}`, isSingleProject);
  };

  return { showRecenterButton, onReCenterBtnClick };
};

export default useRecentreButton;
