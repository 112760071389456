import _ from 'lodash';
import {
  checkIfIsAuthorized,
  getCurrentModeAngularConstants,
  getCurrentUser,
  getI18nConfig,
  getOpenDataMountPath
} from '../../helper/serverConfigHelper';
import useAuthLogin from '../../hooks/useAuthLogin';
import LoginHeader from '../../components/Header/LoginHeader';


const Login = () => {
  const { loggedIn } = getCurrentUser();
  const isAuthorized = checkIfIsAuthorized();
  const signinLabel = getI18nConfig().sign_in_label;
  const loginPageStrings = getI18nConfig().login;
  const hereLabelText = getI18nConfig().here_label_text;
  const { isIntegrated} = getCurrentModeAngularConstants();

  var rootUrl = '/';

  if(isIntegrated) {
    rootUrl = getOpenDataMountPath();
  }

  const authorizedDescription = loginPageStrings.authorized_description_text.replace(
    /{root_url}/g,
    `<a href="${rootUrl}">${hereLabelText}</a>`
  );;

  const { signIn } = useAuthLogin(_.noop);

  return (
    <>
      <LoginHeader />
      <div className="login-message col-md-6 col-md-offset-3">
        {loggedIn ? (
          <div className="login-message-content">
            {isAuthorized ? (
              <span dangerouslySetInnerHTML={{ __html: authorizedDescription }} />
            ) : (
              <span>{loginPageStrings.unauthorized_description_text}</span>
            )}
          </div>
        ) : (
          <div className="login-message-content">
            <span>{loginPageStrings.msg_content_text} </span>
            <a href="javascript:void(0)" onClick={() => signIn()} >{signinLabel}</a>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
