import _ from 'lodash';

import { getProjectFinancialEntries } from '../../helper/serverConfigHelper';
import { isFinancialEnabled } from '../../helper/configHelper';
import { formatProjectFinancialEntry } from '../../helper/projectFinancialEntryHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

const ProjectFinancialEntries = () => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject);
  const projectFinancialEntries = getProjectFinancialEntries();
  const isFinacial = _.size(projectFinancialEntries) > 0 && isFinancialEnabled();

  return isFinacial && (
          projectFinancialEntries.map(
            (projectFinancialEntry, index: number) => (
              <div key={index} className="col-xs-6">
                <h3>{projectFinancialEntry.display_name}</h3>
                <strong>
                  {formatProjectFinancialEntry(projectFinancialEntry, currentProject)}
                </strong>
              </div>
            )
          )
  );
};

export default ProjectFinancialEntries;
