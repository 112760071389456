import moment from "moment";
import _ from "lodash";

import { getEmptyDataPhrase } from "./configHelper";
import { getFormattedCurrencyValue, roundToNearestThousand } from "./projectUtilHelper";

export const formatProjectFinancialEntry = (projectEntry: any, project: any) => {
  const emptyDataPhrase: string = getEmptyDataPhrase() || "N/A";
  let formattedValue = '';

  if (!_.isEmpty(project.financials)) {
    const lastFinancial: any = _.last(project.financials);
    switch (projectEntry.format_type) {
      case 'date':
        const dateValue = lastFinancial[projectEntry.field_name];
        if (moment(dateValue).isValid()) {
          formattedValue = moment(dateValue).format('MMMM, yyyy');
        } else {
          formattedValue = dateValue;
        }
        break;
      case 'amount':
        formattedValue = getFormattedCurrencyValue(lastFinancial[projectEntry.field_name]);
        break;
      default:
        formattedValue = lastFinancial[projectEntry.field_name];
    }

    if (projectEntry.alternate_column && !_.isEmpty(lastFinancial[projectEntry.alternate_column])) {
      switch (projectEntry.format_type) {
        case 'date':
          formattedValue += ` - ${moment(lastFinancial[projectEntry.alternate_column]).format('MMMM, yyyy')}`;
          break;
        case 'amount':
          formattedValue += ` - ${roundToNearestThousand(lastFinancial[projectEntry.alternate_column])}`;
          break;
        default:
          formattedValue += ` - ${lastFinancial[projectEntry.alternate_column]}`;
      }
    }

    return _.isEmpty(formattedValue) ? emptyDataPhrase : formattedValue;
  }
  return formattedValue || emptyDataPhrase;
};
