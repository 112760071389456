import { Project, Stage } from '../../types/projectTypes';
import {
  getEndDateTypeLabelOne,
  getEndDateTypeLabelThree,
  getEndDateTypeLabelTwo,
  getProjectStagesFilters,
  getStartDateTypeLabelOne,
  getStartDateTypeLabelThree,
  getStartDateTypeLabelTwo,
  isFinancialEnabled,
} from '../../helper/configHelper';
import { getConfig, getI18nConfig } from '../../helper/serverConfigHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { getProjectStageData } from '../../helper/projectDetailEntryHelper';


const ProjectStageTable = () => {
  const project = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const advanceProjectList = getConfig().show_advanced_projects_list_metrics === 'show' && isFinancialEnabled();
  const projectFilterStages = getProjectStagesFilters();
  const startDateLabels = [
    getStartDateTypeLabelOne(),
    getStartDateTypeLabelTwo(),
    getStartDateTypeLabelThree(),
  ];
  const endDateLabels = [
    getEndDateTypeLabelOne(),
    getEndDateTypeLabelTwo(),
    getEndDateTypeLabelThree(),
  ];
  const {
    start_date_label_text: startLabelText,
    end_date_label_text: endLabelText,
  } = getI18nConfig();

  const renderRow = (label: string, dateType: keyof Stage) =>
    projectFilterStages.map((stage: Stage, index: number) => (
      <td key={index}>
        <div className="project-stage">
          <span>{getProjectStageData(stage, dateType)}</span>
        </div>
      </td>
    ));

  const renderTableRows = (labels: string[], dateTypes: (keyof Stage)[], labelText: string) =>
    labels.map((label, index) => (
      <tr key={index}>
        <td>{`${label} ${labelText}`}</td>
        {renderRow(label, dateTypes[index])}
      </tr>
    ));

  const renderProjectFilterStages = () => {
    return (
      <>
        {
          advanceProjectList && (
            <td style={{ width: '135px', display: 'block' }}></td>
          )
        }
        {projectFilterStages.map((stage: Stage, index: number) => (
          <td key={index}>
              <h3>{stage.name}</h3>
          </td>
        ))
      }
      </>
    )
  };

  return (
    <tbody>
      <tr>{renderProjectFilterStages()}</tr>
      {project.stages && project.stages.length > 0 && (
        <>
          {renderTableRows(startDateLabels, ['estimated_start_date', 'forecast_start_date', 'actual_start_date'], startLabelText)}
          {renderTableRows(endDateLabels, ['estimated_end_date', 'forecast_end_date', 'actual_end_date'], endLabelText)}
        </>
      )}
    </tbody>
  );
};

export default ProjectStageTable;
