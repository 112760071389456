import { checkIfLoginPage, getConfig } from '../../helper/serverConfigHelper';

const CopyrightText = () => {
  const isLoginPage = checkIfLoginPage();
  const config = getConfig();
  const copyrightText = (config.footer || {}).copyright_text;


  if (isLoginPage) return null;

  return copyrightText && (
    <li className="d-md-none nav-copyright">
      <span>{copyrightText}</span>
    </li>
  );
};

export default CopyrightText;
