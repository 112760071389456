import { getEmptyDataPhrase } from '../../helper/configHelper';

interface ProjectDetailLinkProps {
  projectDetail: { link_text?: string };
  formattedValue: string;
}

const ProjectDetailLink = ({ projectDetail, formattedValue }: ProjectDetailLinkProps) => {
  const emptyDataPhrase = getEmptyDataPhrase() || 'N/A';
  const hasValidValue = formattedValue !== emptyDataPhrase;

  return hasValidValue ? (
    <a href={formattedValue} target="_blank" rel="noopener noreferrer">
      <strong>{projectDetail.link_text || formattedValue}</strong>
    </a>
  ) : (
    <strong>{emptyDataPhrase}</strong>
  );
};

export default ProjectDetailLink;
