// import useUrlParamAdapter from "../../../util/urlParamAdapter";
import _ from "lodash";
import { SearchEntryType } from "types/configurationTypes";
import {
  getConfiguredSearchEntries,
  getI18nConfig,
} from "../../../helper/serverConfigHelper";

export const defaultSearchMappingField = "project_name";

export const getDefaultSearchEntry = (): SearchEntryType => {
  const projectsLabelText = getI18nConfig().projects_label_text;
  return {
    name: projectsLabelText,
    isProject: true,
    column: "project_name",
    mapped_field_name: "project_name",
  };
};

export const getSearchDropdownEntries = () => {
  // const { getParams } = useUrlParamAdapter();
  const configuredSearchEntries = getConfiguredSearchEntries();
  let searchEntries: SearchEntryType[] = getConfiguredSearchEntries();
  const defaultSearchEntry = getDefaultSearchEntry();
  // const urlParams = getParams();
  searchEntries = [defaultSearchEntry];
  // let selectedSearchEntry = defaultSearchEntry;

  if (!_.isEmpty(configuredSearchEntries)) {
    searchEntries = [defaultSearchEntry].concat(configuredSearchEntries);
    // let searchField = urlParams["searchField"];
    // if (!_.isEmpty(searchField)) {
    //   selectedSearchEntry = _.find(searchEntries, (entry) => {
    //       return entry["mapped_field_name"] == searchField;
    //     }) || defaultSearchEntry;
    // }
  }

  return searchEntries;
};
