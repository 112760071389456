import { getI18nConfig } from '../../helper/serverConfigHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import _ from 'lodash';
import { resetLeftPanelFilters, updateCurrentTab } from '../../actions/projectFilterActions';
import { updateSearchText } from '../../actions/searchActions';
import { updateCurrentProject } from '../../actions/projectListActions';
import useUrlParamAdapter from '../../util/urlParamAdapter';
import { Project } from 'types/projectTypes';

const BreadCrumb = () => {
  const dispatch = useDispatch();
  const {
    home_link_label_text: homeLinkLabelText,
    all_projects_link_label_text: allProjectsLinkLabelText
  } = getI18nConfig();
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const allProjectsFilter = useSelector<RootState>((store) => store.projectFilter.allProjectFilters);
  const selectedProjectId = currentProject?.project_id;
  const hasActiveFilters = _.some(allProjectsFilter as object, Boolean);
  const filterHeader = hasActiveFilters ? 'Filtered Projects' : '';
  const { gotoState } = useUrlParamAdapter();

  const onAllProjectSelect = () => {
    dispatch(updateCurrentTab('projects'));
    dispatch(resetLeftPanelFilters(false));
    dispatch(updateSearchText(''));
    dispatch(updateCurrentProject({}));
    gotoState('/projects', {}, false);
  };

  const onHomeBtnClick = () => {
    dispatch(updateCurrentTab('home'));
    gotoState('/projects', {}, false);
  };

  return (
    <div className="breadcrumb d-md-none">
      <ol>
        <li onClick={onHomeBtnClick}>{homeLinkLabelText}</li>
        {filterHeader || selectedProjectId ? (
          <li onClick={onAllProjectSelect}>
            {allProjectsLinkLabelText}
          </li>
        ) : (
          <li className="active"> {allProjectsLinkLabelText} </li>
        )}
        {filterHeader && !selectedProjectId && (
          <li className="active">{filterHeader}</li>
        )}
        {selectedProjectId && (
          <li className="active">{selectedProjectId}</li>
        )}
      </ol>
    </div>
  );
};

export default BreadCrumb;
