import _ from 'lodash';
import {
  ForgeExpansionPanel,
  ForgeList,
  ForgeListItem,
} from '@tylertech/forge-react';
import FilterOption from './FilterOption';
import classNames from 'classnames';
import useProjectColumnFilter from '../../hooks/useProjectColumnFilter';
import { getI18nConfig } from '../../helper/serverConfigHelper';
import { IProjectColumnFiltersProps } from './ProjectColumnFilters';

interface ColumnFilterProps {
  config: IProjectColumnFiltersProps
}

const ColumnFilter = (props: ColumnFilterProps) => {
  const { config } = props;
  const filterTitle = _.get(config, 'title', '');
  const { select_all_label_text: selectAllLabelText } = getI18nConfig();

  const {
    distinctValues,
    getSelectedValueCount,
    isAllFiltersSelected,
    handleSelectColumnFilter,
    handleSelectAllColumnFilters
  } = useProjectColumnFilter(config);

  const filterClass = classNames('filter-selector-wrapper', {
    'filter-selector-wrapper-active': isAllFiltersSelected()
  });

  const handleEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSelectAllColumnFilters();
    }
  };

  return (
    <ForgeExpansionPanel className='column-filter'>
      <div title={filterTitle} slot="header" className="filter-header" role="button" tabIndex={0}>
        <h2 className="section-heading">{`${filterTitle} ${getSelectedValueCount()}`}</h2>
        <forge-open-icon className="open-icon"></forge-open-icon>
      </div>
      <div className="project-type-filter">
        <ForgeList className="project-type-list" dense={true}>
          <ForgeListItem className={filterClass} onClick={handleSelectAllColumnFilters}>
            <div className="filter-selector-list" title={selectAllLabelText}>
              <label className="all-projects-selector" htmlFor="select-all">
                <span className="fa fa-check fa-icon-checked" role="checkbox" tabIndex={0} onKeyDown={(e) => handleEnter(e)} aria-label="select-all" aria-checked={isAllFiltersSelected()}></span>
              </label>
              <span className="category-name" id="select-all">
                {selectAllLabelText}
              </span>
            </div>
          </ForgeListItem>
          {_.sortBy(distinctValues).map((configDatum, index) => {
            return (
              <FilterOption
                config={configDatum}
                index={index}
                isOptionSelected={configDatum.isChecked}
                onOptionSelect={handleSelectColumnFilter}
              />
            );
          })}
        </ForgeList>
      </div>
    </ForgeExpansionPanel>
  );
};

export default ColumnFilter;
