import {
	checkIfLoginPage,
	getCurrentModeAngularConstants,
	getI18nConfig
} from '../../helper/serverConfigHelper';
import {
	getCurrentUrl,
	getTwitterShareText,
	showFacebookLogo,
	showTwitterLogo
} from '../../helper/configHelper';

const SocialMediaLinks = () => {
	const i18nStrings = getI18nConfig();
	const currentUrl = getCurrentUrl();
	const twitterShareText = getTwitterShareText();
	const currentModeConstants = getCurrentModeAngularConstants();
	const isLoginPage = checkIfLoginPage();

	if (!currentModeConstants.showShareIcons || isLoginPage) {
		return null;
	}

	const facebookLogo = showFacebookLogo();
	const twitterLogo = showTwitterLogo();

	if (!facebookLogo && !twitterLogo) {
		return null;
	}

	return (
		<>
			{facebookLogo && (
				<li className="facebook-icon">
					<a
					  aria-label="Share on Facebook"
						href={`http://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
						target="_blank"
						title={i18nStrings.share_on_facebook_label}
						tabIndex={0}
						className="nav-links"
					>
						<i className="fa fa-facebook" aria-hidden="true" role="img"></i>
					</a>
				</li>
			)}
			{twitterLogo && (
				<li className="twitter-icon">
					<a
						aria-label="Share on Twitter"
						href={`https://twitter.com/intent/tweet?url=${twitterShareText}`}
						target="_blank"
						title={i18nStrings.twitter_share_tooltip_label}
						tabIndex={0}
						className="nav-links"
					>
						<i className="icons icon-twitter-new" aria-hidden="true" role="img"></i>
					</a>
				</li>
			)}
		</>
	);
};

export default SocialMediaLinks;
