import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';

interface HomePageContentProps {
  htmlString: string;
}

const HomePageContent = ({ htmlString }: HomePageContentProps) => {
  const containerRef = useRef<HTMLParagraphElement>(null);
  const currentTab = useSelector<RootState>(
    (store) => store.projectFilter.currentTab
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      // Replace self-closing tags with full closing tags
      const fixedHtmlString = htmlString.replace(/<(\w+)([^>]*)\/>/g, '<$1$2></$1>');
      container.innerHTML = fixedHtmlString;

      // Handle style tags
      const styles = container.querySelectorAll('style');
      let combinedStyles = '';
      styles.forEach((style) => {
        if (style.textContent) {
          combinedStyles += style.textContent.replace(
            /([^;{}]+)(;|(?=\s*}))/g,
            (match, p1) => {
              return p1.includes('!important')
                ? match
                : `${p1.trim()} !important;`;
            }
          );
        }
      });

      if (combinedStyles) {
        const newStyle = document.createElement('style');
        newStyle.textContent = `.home-page-content { ${combinedStyles} }`;
        document.head.appendChild(newStyle);
      }

      // Handle script tags
      const scripts = container.querySelectorAll('script');
      scripts.forEach((script) => {
        const newScript = document.createElement('script');
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.body.appendChild(newScript);
      });

      // Observe changes to the DOM to handle styles applied by scripts
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            const target = mutation.target as HTMLElement;
            const style = target.getAttribute('style');
            if (style && !style.includes('!important')) {
              target.setAttribute(
                'style',
                style.replace(/([^;{}]+)(;|(?=\s*}))/g, (match, p1) => {
                  return p1.includes('!important')
                    ? match
                    : `${p1.trim()} !important;`;
                })
              );
            }
          }
        });
      });

      observer.observe(container, {
        attributes: true,
        subtree: true,
        attributeFilter: ['style'],
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [containerRef, currentTab, htmlString]);

  return (
    <div className="home-page-content">
      <p
        ref={containerRef}
        dangerouslySetInnerHTML={{ __html: htmlString }}
      ></p>
    </div>
  );
};

export default HomePageContent;
