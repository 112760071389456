import { getConfig } from '../helper/serverConfigHelper';

type TextAnchor = "center" | "left" | "right" | "top" | "bottom" | 
                  "top-left" | "top-right" | "bottom-left" | "bottom-right";
                  
const useMapPinsLayer = () => {
  const customerConfigurations = getConfig();
  const pinSize = parseInt(customerConfigurations.map_pin_size);
  const clusterZoomLevelConfig = parseInt(customerConfigurations.cluster_on_zoom_level);
  const clusterZoomLevel = (clusterZoomLevelConfig > 22 || clusterZoomLevelConfig <= 0) ? 1 : clusterZoomLevelConfig;

  const addProjectPinsLayer = (map: mapboxgl.Map) => {
    map.addSource('project-pins', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addLayer({
      'id': 'pins',
      'type': 'symbol',
      'source': 'project-pins',
      'minzoom': clusterZoomLevel,
      'layout': {
        'text-offset': [-0.2, 0.5],
        'text-anchor': getIconPosition() as TextAnchor,
        'text-field': getFontIcon(),
        'text-font': [
          'FontAwesome Regular',
          'DIN Offc Pro Medium'
        ],
        'text-allow-overlap': true,
        'text-size': pinSize
      },
      'paint': {
        'text-color': {
          'type': 'identity',
          'property': 'icon_color'
        }
      }
    });
  };

  const addProjectMultiPinsLayer = (map: mapboxgl.Map) => {
    map.addSource('project-multi-pins', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addLayer({
      'id': 'project-multi-pins',
      'type': 'symbol',
      'source': 'project-multi-pins',
      'minzoom': clusterZoomLevel,
      'layout': {
        'text-offset': [-0.2, 0.5],
        'text-anchor': getIconPosition() as TextAnchor,
        'text-field': getFontIcon(),
        'text-font': [
          'FontAwesome Regular',
          'DIN Offc Pro Medium'
        ],
        'text-allow-overlap': true,
        'text-size': pinSize
      },
      'paint': {
        'text-color': {
          'type': 'identity',
          'property': 'icon_color'
        }
      }
    });
  };

  const addProjectClusterLayer = (map: mapboxgl.Map) => {
    map.addSource('project-cluster', {
      'type': 'geojson',
      'cluster': true,
      'clusterRadius': 100,
      'data': {
        'type': 'FeatureCollection',
        'features': []
      }
    });

    map.addLayer({
      'id': 'cluster-outer-circle',
      'type': 'circle',
      'source': 'project-cluster',
      'maxzoom': clusterZoomLevel,
      'paint': {
        'circle-color': {
          'type': 'interval',
          'property': 'point_count',
          'stops': [
            [0, '#87c74e'],
            [10, '#f0c20c']
          ]
        },
        'circle-radius': 18,
        'circle-opacity': 0.4
      }
    });

    map.addLayer({
      'id': 'cluster-inner-circle',
      'type': 'circle',
      'source': 'project-cluster',
      'maxzoom': clusterZoomLevel,
      'paint': {
        'circle-color': {
          'type': 'interval',
          'property': 'point_count',
          'stops': [
            [0, '#87c74e'],
            [10, '#f0c20c']
          ]
        },
        'circle-radius': 14,
        'circle-opacity': 0.7
      }
    });

    map.addLayer({
      'id': 'cluster-pins-count',
      'type': 'symbol',
      'source': 'project-cluster',
      'maxzoom': clusterZoomLevel,
      'filter': ['has', 'point_count'],
      'layout': {
        'text-field': '{point_count}',
        'text-allow-overlap': true,
        'text-size': 12
      },
      'paint': {
        'text-color': '#000000',
        'text-opacity': 0.6
      }
    });
  };

  const getFontIcon = () => {
    const DEFAULT_PIN_ICON = "f041";
    let font_content;

    if (customerConfigurations.enable_customizable_map_icons === 'show') {
      const pinsIcon = JSON.parse(customerConfigurations.customized_map_icons).content;
      font_content = String.fromCharCode(parseInt(pinsIcon, 16));
    } else {
      font_content = String.fromCharCode(parseInt(DEFAULT_PIN_ICON, 16));
    }
    return font_content;
  };

  const getIconPosition = () => {
    const isCustomMapIconsEnabled = customerConfigurations.enable_customizable_map_icons === 'show';
    return isCustomMapIconsEnabled ? customerConfigurations.map_pin_position : "bottom";
  };

  return {
    addProjectPinsLayer,
    addProjectMultiPinsLayer,
    addProjectClusterLayer
  };
};

export default useMapPinsLayer;
