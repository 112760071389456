import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer';
import store from '../frontend/store';
import { ReduxState } from 'types/stateTypes';
import Header from './components/Header';
import ResponsiveFooter from './ResponsiveFooter';
import AppRoutes from './AppRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.scss';
import './Icons.js';
import { Slide, ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const Main = (props: ReduxState) => {
  console.log('Main props', props);
  const queryClient = new QueryClient();

  return (
    <Provider store={store(props)}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Header />
          <AppRoutes />
          <Footer />
          <ResponsiveFooter />
          <ToastContainer
            className="custom-toast"
            position="top-right"
            theme="colored"
            transition={Slide}
          />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
};

export default Main;
