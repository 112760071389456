import { Navigate, Route, Routes } from 'react-router-dom';
import ProjectPage from './components/ProjectPage';
import AdminPage from './components/Administrator/AdminPage';
import Layout from './Layout';
import ErrorBoundary from './components/ErrorBoundary';

const AppRoutes = () => {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={<Navigate to="/projects" replace />} />
        <Route path="/projects" element={<Layout />}>
          <Route path=":project_id" element={<ProjectPage />} />
        </Route>

        <Route path="/admin" element={<AdminPage />}>
          <Route path=":menu_type" element={<AdminPage />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;
