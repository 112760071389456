import { useEffect, useState } from 'react';
import _ from 'lodash';
import { COLOR_CODES } from '../appConstants';
import { getCurrencySymbol, getProjectFinancialConfig } from '../helper/configHelper';
import { Project } from '../types/projectTypes';
import { getBudgetIndicatorByValue } from '../helper/projectIndicatorHelper';
import { getFormattedCurrencyValue } from '../helper/projectUtilHelper';

type ChartData = {
  ranges: number[];
  measures: number[];
  rangeLabels: string[];
  measureLabels: string[];
};

const useBarChartData = (project: Project, isTableView:boolean, toggleCostVariance:boolean ) => {
  const [chartData, setChartData] = useState<Partial<Plotly.Data>[]>([]);
  const [layout, setLayout] = useState<Partial<Plotly.Layout>>({});

  const financeConfig = getProjectFinancialConfig();
  const financialData: { [key: string]: number } = _.last(project.financials) || {};

  const { icon_color: budgetIconColor } = getBudgetIndicatorByValue(project.budget_indicator);

  const currencySymbol = getCurrencySymbol();
  const bulletChartData: ChartData = {
    ranges: _.compact([
      financialData?.[financeConfig.metric_one],
      financialData?.[financeConfig.metric_two],
      financialData?.[financeConfig.metric_three],
    ]),
    measures: [financialData?.[financeConfig.current_metric] || 0],
    rangeLabels: _.compact([
      financeConfig?.metric_one_label,
      financeConfig?.metric_two_label,
      financeConfig?.metric_three_label,
    ]),
    measureLabels: [financeConfig.current_metric_label]
  };

  const maxValue = Math.max(...bulletChartData.ranges.concat(bulletChartData.measures));
  const costVariance = toggleCostVariance ? project.percent_cost_variance : project.percent_schedule_variance; 

  useEffect(() => {
    if (!bulletChartData) return;

    let formattedData = formatPlotlyData(bulletChartData);
    let layoutConfig: any = getLayout();

    // if(isTableView) {
    //   layoutConfig['xaxis']['dtick'] = 0;
    //   layoutConfig['xaxis']['tick0'] = 0;
    // }
     
    setChartData(formattedData);
    setLayout(layoutConfig);
  }, [project, toggleCostVariance]);

  function getLayout(): Partial<Plotly.Layout> {
    return {
      hovermode: 'closest',
      xaxis: {
        range: isTableView ? [0, costVariance] : [0, maxValue],
        zeroline: false,
        tickformat: isTableView ? `.0f` : `${currencySymbol},.0f`,
        ticksuffix: isTableView ? "%" : '',
        tickfont: {
          size: isTableView ? 10 : 12 
        },
      },
      yaxis: {
        visible: false,
      },
      barmode: 'overlay',
      showlegend: false,
      margin: { l: 10, r: 0, t: 0, b: isTableView ? 20: 40 },
      height: isTableView ? 45 : 80,
    };
  }

  const formatPlotlyData = (newData: ChartData): Partial<Plotly.Data>[] => {
    const color = isTableView ? getMatrixColor(project.matrix_id || 0) : getHexColor(budgetIconColor);
    const ranges = newData.ranges.map((range, index) => createBarData(range, newData.rangeLabels[index], "rgba(200, 200, 200, 0.9)", true));
    const measures = newData.measures.map((measure, index) => createBarData(measure, newData.measureLabels[index], color));
    return [...ranges, ...measures];
  };

  const createBarData = (value: number, label: string, color: string, isRange?: boolean): Partial<Plotly.Data> => {
    // x: isMultiple ? [(value / maxValue) * (costVariance || 0)] : [value],

    const xValue: any = isTableView && isRange ? 100 : (isTableView && !isRange) ? costVariance : value;
    const toolTipInfo = isTableView ? `(${xValue}%)` : '';
    let barWidth = isRange ? 0.6 : 0.25;
    if (isTableView) {
      barWidth = isRange ? 0.5 : 0.2;
    }

    return {
      x: [xValue],
      y: [1],
      type: 'bar',
      orientation: 'h',
      marker: { color },
      name: label,
      width: barWidth,
      customdata: [{...getCustomData(label, value, color), toolTipInfo}] as any,
      hovertemplate: `<span style="color: ${color};"> ■ </span>` +
        `<b>${label} %{customdata.value} %{customdata.toolTipInfo}</b> <extra></extra> `,
      hoverlabel: getTooltipLabel(),
    };
  };

  function getCustomData(label: string, value: number, color: string) {
    return {
      name: label,
      value: getFormattedCurrencyValue(value),
      percentage: `${costVariance}%`,
      color: color
    };
  }

  function getTooltipLabel() {
    return {
      bgcolor: '#166289',
      bordercolor: 'gray',
      font: {
        size: isTableView ? 12 : 18,
        color: 'white',
      },
    };
  }

  function getMatrixColor(matrixId: number): string {
    const color: Record<number, string> = {
      1: '#e06666',
      2: '#990000',
      3: '#610505',
      4: '#e5af25',
      5: '#e06666',
      6: '#990000',
      7: '#38761e',
      8: '#e5af25',
      9: '#e06666'
    };

    return color[matrixId] || '#000000';
  }

  function getHexColor(color: string): string {
    let isValidHexCode = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

    if (isValidHexCode) {
      return color;
    }

    let colorWordOverrides: { [key: string]: string } = {
      'green': COLOR_CODES.green,
      'yellow': COLOR_CODES.yellow,
      'red':  COLOR_CODES.red,
    };

    return colorWordOverrides[_.lowerCase(color)] || color;
  }

  return { chartData, layout };
};

export default useBarChartData;
