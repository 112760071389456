import { isColumnFilterPositionedAtTop } from '../../helper/filterHelper';
import MiniMapFilter from './MiniMapFilter';
import NavigationList from './NavigationList';
import ProjectColumnFilters from './ProjectColumnFilters';
import ProjectFundFilter from './ProjectFundFilter';
import ProjectStageFilter from './ProjectStageFilter';
import ProjectTypeFilter from './ProjectTypeFilter';

const LeftPanel = () => {
  const isProjectColumnFilterOnTop = isColumnFilterPositionedAtTop();

  return (
    <div className="base-page">
      <div className="project-explorer" id="filters-section"> 
        <div className="filters-container">
          <NavigationList />
          { isProjectColumnFilterOnTop && <ProjectColumnFilters /> }
          <ProjectTypeFilter />
          <MiniMapFilter />
          <ProjectFundFilter />
          <ProjectStageFilter />
          { !isProjectColumnFilterOnTop && <ProjectColumnFilters /> }
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
