import React from 'react';
import {
  getProjectDetailsEntries,
  getProjectFinancialEntries,
} from '../../helper/serverConfigHelper';
import { Project } from '../../types/projectTypes';
import { isFinancialEnabled, isShowFinancialChart } from '../../helper/configHelper';
import _ from 'lodash';
import BulletChart from '../../components/Visualization/BulletChart';
import ProjectStages from './ProjectStages';
import ProjectFinancialEntries from './ProjectFinancialEntries';
import ProjectDetailEntries from './ProjectDetailEntries';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

export interface ProjectInfoProps {
  display_name: string;
  field_name: string;
  format_type: string;
  link_text: string;
  mapped_field_name: string;
}

const ProjectInfo = () => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const projectFinancialEntries = getProjectFinancialEntries();
  const projectDetails: ProjectInfoProps[] = getProjectDetailsEntries();
  const financialChart = isShowFinancialChart() && isFinancialEnabled();

  const showFinancialData = _.size(projectFinancialEntries) > 0 && isFinancialEnabled();
  const checkFinanceClassName = showFinancialData
    ? 'finance-details-entry'
    : null;

  const renderProjectDetails = () => {
    if (_.isEmpty(projectDetails)) return null;

    return projectDetails.map((projectDetail) => (
      <ProjectDetailEntries
        key={projectDetail.field_name}
        projectDetail={projectDetail}
      />
    ));
  };

  return (
    <div>
      <div className="project-info-container">
        <div className={`project-info-wrapper ${checkFinanceClassName}`}>
          {showFinancialData && <ProjectFinancialEntries />}
        </div>
      </div>
      { financialChart && (
        <div className="project-bullet-chart mt-5">
          <BulletChart project={currentProject} />
        </div>
      )}
        <div className="project-info-container">
          <div className="project-info-wrapper">
            {renderProjectDetails()}
          </div>
        </div>
      <ProjectStages />
    </div>
  );
};

export default ProjectInfo;
