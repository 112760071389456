import * as turf from '@turf/turf';
import * as _ from 'lodash';
import {
  getProjectStageColors,
  getProjectTypeColors,
  getShapeColors
} from "./configHelper";
import { dimensionValueFromProjectId,
  getDefaultColor,
  getDistrictMarkerColor,
  getProjectStageMarkerColor,
  getProjectTypeMarkerColor
} from "./projectMapMarkerHelper";
import { getConfig } from "./serverConfigHelper";
import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';


interface GeoJsonFeature {
  type: 'Feature';
  properties?:  { [key: string]: any }
  geometry?:  Geometry
}

interface GeoJson {
  type: 'FeatureCollection';
  features: GeoJsonFeature[];
}

interface Marker {
  [key: string]: any;
  the_geom?: {
    type: string;
    coordinates: any[];
  };
}

interface ShapesDatum {
  the_geom: Geometry;
  district_id: string;
  district_name: string;
}

export const formatShapes = (shapesData: ShapesDatum[], name_column: string): FeatureCollection<Geometry, GeoJsonProperties> => {
  return {
    type: 'FeatureCollection',
    features: shapesData.map((shape: ShapesDatum) => ({
      type: 'Feature',
      geometry: shape.the_geom as Geometry,
      properties: {
        name: _.get(shape, name_column),
        id: shape.district_id
      }
    }))
  };
};

export const formatPins = (markers: Marker[]): GeoJson => {
  const markersGeoJson: GeoJson = {
    type: 'FeatureCollection',
    features: []
  };

  _.each(markers, (marker: Marker) => {
    if (!_.isUndefined(marker.the_geom)) {
      var coords = _.flatten(marker.the_geom.coordinates) || [];
      var marker_properties = _.omit(marker, 'the_geom');
      marker_properties['icon_color'] = getColorByDimension(marker, 'pin');
      if (coords.length !== 2 || !_.isNumber(coords[0]) || !_.isNumber(coords[1])) {
        return;
      }

      markersGeoJson.features?.push({
        type: 'Feature',
        properties: marker_properties,
        geometry: {
          type: 'Point',
          coordinates: coords
        }
      });
    }
  });

  return markersGeoJson;
};


export const formatMultiPointPins = (markers: Marker[]): GeoJson => {
  const markersGeoJson: GeoJson = {
    type: 'FeatureCollection',
    features: []
  };

  _.each(markers, (marker: Marker) => {
    if (!_.isUndefined(marker.the_geom)) {
      // const coords = marker.the_geom.coordinates || [];
      const marker_properties = _.omit(marker, 'the_geom');
      marker_properties['icon_color'] = getColorByDimension(marker, 'pin');

      if (marker.the_geom.type !== 'MultiPoint') {
        return;
      }
      markersGeoJson.features?.push({
        type: 'Feature',
        properties: marker_properties,
        geometry: {
          type: 'MultiPoint',
          coordinates: marker.the_geom.coordinates
        }
      });
    }
  });
  return markersGeoJson;
};

export const formatProjectShapes = (markers: Marker[]): GeoJson => {
  const shapesMarkersGeoJson: GeoJson = {
    type: 'FeatureCollection',
    features: []
  };
  const supportedShapeTypes = ['Polygon', 'MultiPolygon'];

  _.each(markers, (marker: Marker) => {
    if (!_.isUndefined(marker.the_geom)) {
      // const coords = marker.the_geom.coordinates || [];
      const marker_properties = _.omit(marker, 'the_geom');
      const color = getColorByDimension(marker, 'shape');
      marker_properties['fill_color'] = color;
      marker_properties['border_color'] = color;
      if (_.includes(supportedShapeTypes, marker.the_geom.type)) {
        shapesMarkersGeoJson.features?.push({
          type: 'Feature',
          properties: marker_properties,
          geometry: marker.the_geom as Geometry
        });
      }
    }
  });
  return shapesMarkersGeoJson;
};

export const formatProjectLineString = (markers: Marker[]): GeoJson => {
  const shapesMarkersGeoJson: GeoJson = {
    type: 'FeatureCollection',
    features: []
  };
  const supportedLineTypes = ['LineString', 'MultiLineString'];

  _.each(markers, (marker: Marker) => {
    if (!_.isUndefined(marker.the_geom)) {
      // const coords = marker.the_geom.coordinates || [];
      const marker_properties = _.omit(marker, 'the_geom');
      marker_properties['border_color'] = getColorByDimension(marker, 'line');
      if (_.includes(supportedLineTypes, marker.the_geom.type)) {
        shapesMarkersGeoJson.features?.push({
          type: 'Feature',
          properties: marker_properties,
          geometry: marker.the_geom as Geometry
        });
      }
    }
  });
  return shapesMarkersGeoJson;
};

export const formattedClusterData = (markers: Marker[]): GeoJson => {
  const markersGeoJson: GeoJson = {
    type: 'FeatureCollection',
    features: []
  };

  _.each(markers, (marker: Marker) => {
    if (!_.isUndefined(marker.the_geom)) {
      const coords = marker.the_geom.coordinates || [];
      if (marker.the_geom.type !== 'Point') {
        const polygonFeatures = turf.center(marker.the_geom as turf.AllGeoJSON) as GeoJsonFeature;
        polygonFeatures['properties'] = polygonFeatures['properties'] || {};
        polygonFeatures['properties']['point_count'] = 1;
        markersGeoJson.features?.push(polygonFeatures);
      } else {
        markersGeoJson.features?.push({
          type: 'Feature',
          properties: { 'point_count': 1 },
          geometry: {
            type: 'Point',
            coordinates: coords
          }
        });
      }
    }
  });
  return markersGeoJson;
};

export const getColorByDimension = (marker: Marker, geoType: string): string => {
  const isProjectType = getConfig().dimension_color_type === 'project_type';
  const isDistrict = getConfig().dimension_color_type === 'district_id';
  const isProjectStage = getConfig().dimension_color_type === 'project_stage';
  const isOverrideDefaultColor = getConfig().override_default_color_selections === 'show';

  if (!isOverrideDefaultColor) {
    return getDefaultColor(geoType);
  }

  if (isProjectStage) {
    return getProjectStageColor(marker, geoType);
  } else if (isProjectType) {
    return getProjectTypeColor(marker, geoType);
  } else if (isDistrict) {
    return getDistrictColor(marker, geoType);
  } else {
    return getDistrictMarkerColor(null, geoType);
  }
};

export const getProjectStageColor = (marker: Marker, geoType: string): string => {
  const isColorsForProjectStageConfigured = getProjectStageColors().length > 0;
  if (!isColorsForProjectStageConfigured) {
    return getProjectStageMarkerColor(null, geoType);
  } else {
    const projectStageId = dimensionValueFromProjectId(marker, 'project_stage_id');
    return getProjectStageMarkerColor(projectStageId, geoType);
  }
};

export const getProjectTypeColor = (marker: Marker, geoType: string): string => {
  const isColorsForProjectTypeConfigured = getProjectTypeColors().length > 0;
  if (!isColorsForProjectTypeConfigured) {
    return getProjectTypeMarkerColor(null, geoType);
  } else {
    const projectTypeId = dimensionValueFromProjectId(marker, 'category_id');
    return getProjectTypeMarkerColor(projectTypeId, geoType);
  }
};

export const getDistrictColor = (marker: Marker, geoType: string): string => {
  const isColorsForDistrictsConfigured = getShapeColors().length > 0;
  if (!isColorsForDistrictsConfigured) {
    return getDistrictMarkerColor(null, geoType);
  } else {
    const districtId = dimensionValueFromProjectId(marker, 'district_id');
    return getDistrictMarkerColor(districtId, geoType);
  }
};

export const getShapeDatasets = () => {
  var parsedShapeDatasets = JSON.parse(_.get(getConfig(), 'shape_datasets', '[]'));

  return _.filter(parsedShapeDatasets, function(shape_dataset){
    return (!_.isEmpty(shape_dataset.shape_dataset_domain) && !_.isEmpty(shape_dataset.shape_dataset_id))
  })
};