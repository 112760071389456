import { getI18nConfig } from "../helper/serverConfigHelper";
import {
  getProjectListConfig,
  isShowAdvancedProjectMetrics,
} from "../helper/configHelper";

export const useConfigHelpers = () => {
  const i18nStrings = getI18nConfig();
  const { facet_label_one: facetOneLabel, facet_label_two: facetTwoLabel } = getProjectListConfig();
  const showAdvanceProjectList = isShowAdvancedProjectMetrics();

  return {
    i18nStrings,
    facetOneLabel,
    facetTwoLabel,
    showAdvanceProjectList,
  };
};
