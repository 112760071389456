import { useEffect } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { getProjects } from "../../data/api";
import { ProjectFormatter } from "../../data/ProjectFormatter";
import { updateIsLoading, updateProjectList } from "../../actions/projectListActions";
import { checkIfLoginPage, getConfig } from "../../helper/serverConfigHelper";
import useReactQueryFetch from "./useReactQueryFetch";

const useProjectData = () => {
  const isLoginPage = checkIfLoginPage();
  const configuration = getConfig();
  const dispatch = useDispatch();

  if (isLoginPage) return;

  const { data: projectsData, isLoading: isProjectsLoading } = useReactQueryFetch({
    queryKey: "projects",
    fetchFn: getProjects,
  });

  useEffect(() => {
    dispatch(updateIsLoading(isProjectsLoading));
    const formattedProjects = formatProjects(projectsData);
    dispatch(updateProjectList(formattedProjects));
  },[projectsData, isProjectsLoading]);

  const formatProjects = (projects: any) => {
    if (_.isEmpty(projects)) return [];
    const projectFormatter = new ProjectFormatter(configuration);
    const formattedProjects = _.map(projects, projectFormatter.decorateProject);
    return formattedProjects;
  };
};

export default useProjectData;
