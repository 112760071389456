import _ from "lodash";
import { ForgeList, ForgeListItem } from "@tylertech/forge-react";
import { getProjectTypeFiltersTitle } from "../../helper/configHelper";
import classNames from "classnames";
import NestedFilterOption from "./NestedFilterOption";
import useProjectTypeFilter from "../../hooks/useProjectTypeFilter";
import { getI18nConfig } from "../../helper/serverConfigHelper";
import { useEffect, useRef, useState } from "react";

export interface ProjectTypeFilterProps {
  icon: string;
  type: string;
  name: string;
  project_icon: string;
}

const ProjectTypeFilter = () => {
  const title = getProjectTypeFiltersTitle();
  const { select_all_label_text: selectAllLabel } = getI18nConfig();

  const {
    filterSelectState,
    selectedFilters,
    handleFilterSelection,
    handleSelectAllFilters,
    handleSubFilterSelection,
    checkIfSubFilterSelected,
    hasChildConfigs,
    checkIfAnySubCategoriesSelected,
    projectTypes,
  } = useProjectTypeFilter();

  const checkIfAnyCSelected = () => _.size(_.omit(selectedFilters, "all")) > 0;
  const [isFocused, setIsFocused] = useState(false);

  const listItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (e: MouseEvent) => {
    if (listItemRef.current) {
      if (listItemRef.current.contains(e.target as Node)) {
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
    }
  };

  const filterClass = classNames("filter-selector-wrapper", {
    "filter-selector-wrapper-active": checkIfAnyCSelected(),
    "focused": isFocused,
  });

  const icon =
    filterSelectState === "all" ? "fa fa-check fa-icon-checked" : "fa fa-minus";

  const handleKeyEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSelectAllFilters();
    }
  };

  return (
    <div className="project-type-wrapper">
      <h2 className="section-heading" title={title}>
        {title}
      </h2>
      <div className="project-type-filter">
        <ForgeList className="project-type-list" dense={true}>
          <div className="filter-selector-container">
            <ForgeListItem
              className={filterClass}
              onClick={handleSelectAllFilters}
              ref={listItemRef}
            >
              <div className="filter-selector-list">
                <label className="all-projects-selector" htmlFor="Select-All">
                  <span
                    className={icon}
                    tabIndex={0}
                    onKeyDown={handleKeyEnter}
                    role="checkbox"
                    aria-label="Select-All"
                    aria-checked="false"
                  ></span>
                </label>
                <span className="category-name" id="Select-All">
                  {selectAllLabel}
                </span>
              </div>
            </ForgeListItem>
          </div>
          {projectTypes.map((config: ProjectTypeFilterProps, index: number) => (
            <NestedFilterOption
              key={index}
              config={config}
              index={index}
              selectedFilters={selectedFilters}
              handleFilterSelection={handleFilterSelection}
              handleSubFilterSelection={handleSubFilterSelection}
              checkIfSubFilterSelected={checkIfSubFilterSelected}
              hasChildConfigs={hasChildConfigs(config)}
              ifAnySubCategoriesSelected={checkIfAnySubCategoriesSelected(
                config
              )}
            />
          ))}
        </ForgeList>
      </div>
    </div>
  );
};

export default ProjectTypeFilter;
