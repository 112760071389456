import _ from "lodash";
import { getBudgetIndicators, getOptionalIndicators, getScheduleIndicators } from "./configHelper";


export const getBudgetStatusValue = (projects: string[]) => {
  const budgetIndicators = getBudgetIndicators();
  return getOnTrackIndicatorPercent(
    budgetIndicators,
    projects,
    "budget_indicator" as keyof (typeof projects)[number]
  );
};

export const getScheduleStatusValue = (projects: string[]) => {
  const scheduleIndicators = getScheduleIndicators();
  return getOnTrackIndicatorPercent(
    scheduleIndicators,
    projects,
    "schedule_indicator" as keyof (typeof projects)[number]
  );
};

export const getOnTrackIndicatorPercent = (indicatorConfigs: string[], projects: string[], indicatorKey: keyof typeof projects[number]) => {

  const onTrackIndicators = _.map(_.filter(indicatorConfigs, { 'enable_on_track_status': 'true' }), 'indicator_value');
  const projectCountIndicator = _.map(_.reject(indicatorConfigs, { 'enable_on_track_status': 'ignore' }), 'indicator_value');

  let totalProjects = _.filter(projects, function (project) {
    return _.includes(projectCountIndicator, project[indicatorKey]);
  });

  if (_.isEmpty(totalProjects)) {
    return 0;
  };
  const onTrackProjects = _.filter(projects, function (project) {
    return _.includes(onTrackIndicators, project[indicatorKey]);
  });
  return Math.round((onTrackProjects.length / totalProjects.length) * 100);
};

export const getBudgetIndicatorByValue = (indicatorValue: any) => {
  const budgetIndicators = getBudgetIndicators();
  if (_.isUndefined(indicatorValue)) {
    return {};
  }
  return _.find(budgetIndicators, function (indicator) {
    return indicator['indicator_value'] == indicatorValue;
  }) || {};
};

export const getScheduleIndicatorByValue = (indicatorValue: any) => {
  const scheduleIndicators = getScheduleIndicators();
  if (_.isUndefined(indicatorValue)) {
    return {};
  }
  return _.find(scheduleIndicators, function (indicator) {
    return indicator['indicator_value'] == indicatorValue;
  }) || {};
};

export const getOptionalIndicatorByValue = (indicatorValue: any) => {
  const scheduleIndicators = getOptionalIndicators();
  if (_.isUndefined(indicatorValue)) {
    return {};
  }
  return _.find(scheduleIndicators, function (indicator) {
    return indicator['indicator_value'] == indicatorValue;
  }) || {};
};
