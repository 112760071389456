import { useSelector } from 'react-redux';
import { getConfig, getI18nConfig } from '../../helper/serverConfigHelper';
import { RootState } from 'reducers/rootReducer';
import {
  getBudgetStatusValue,
  getScheduleStatusValue
} from '../../helper/projectIndicatorHelper';

const projectIndicator = () => {
  const isAdminPage = window.location.href.includes('admin');
  const { projects_label_text: projectLabelText, on_budget_label_text: onBudgetLabelText, on_schedule_label_text: onScheduleLabelText} = getI18nConfig();
  const filteredProjects = useSelector<RootState>((store) => store.projects.filteredProjects) as string[];
  const showVarianceMatrix = getConfig().show_advanced_project_metrics == 'show';
  const showStatusAndBudgetHeader = getConfig().show_status_and_budget_header == 'show';
  const projectCount = filteredProjects.length;
  const budgetIndicatorsPercent = getBudgetStatusValue(filteredProjects) + '%';
  const scheduleIndicatorsPercent = getScheduleStatusValue(filteredProjects) + '%';

  return (
    <section className="base-page">
      {!showVarianceMatrix && showStatusAndBudgetHeader && !isAdminPage && (
        <ul className="ovarall-status">
          <li>
            <strong className="dynamic">{projectCount} </strong>
            <span>{projectLabelText}</span>
          </li>
          <li>
            <strong className="dynamic">{budgetIndicatorsPercent} </strong>
            <span>{onBudgetLabelText}</span>
          </li>
          <li>
            <strong className="dynamic">{scheduleIndicatorsPercent} </strong>
            <span>{onScheduleLabelText}</span>
          </li>
        </ul>
      )}
    </section>
  );
};

export default projectIndicator;
