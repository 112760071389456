import {
  checkIfLoginPage,
  getCurrentModeAngularConstants,
  getOpenDataMountPath
} from "../helper/serverConfigHelper";
import GlobalEvents from "../components/GlobalEvents";
import useToast from "./useToast";

const useAuthLogin = (updateCurrentUserDetails: any) => {
  const showMessage = useToast();

  const checkLoginWindow = (loginWindow: any) => {
    const intervalId = setInterval(() => {
      if (getWindowProperty(loginWindow, 'complete') || getWindowProperty(loginWindow, 'closed')) {
        loginWindow.close();
        if (getWindowProperty(loginWindow, 'complete')) {
          if (getWindowProperty(loginWindow, 'error')) {
            showMessage('Signin Failed. If you were logged in another domain, your session there would have expired. Please try again.', 'error');
            console.log('Signin Failed. If you were logged in another domain, your session there would have expired. Please try again.');
          } else {
            showMessage('You signed in successfully!', 'success');
          }
        }
        onLogin();
        clearInterval(intervalId);
      }
    }, 250);
  };

  const onLogin = async () => {
    const isLoginPage = checkIfLoginPage();
    if (isLoginPage) {
      // GlobalEvents.emit('LoginWindow::Closed');
      window.location.href = '/';
      return;
    }
    updateCurrentUserDetails();
  };

  const getWindowProperty = (window: any, propertyName: any) => {
    try {
      return window[propertyName];
    } catch (err) {
      console.warn('caught error' + err);
    }
  };

  const signIn = () => {
    const { loginPath, isIntegrated } = getCurrentModeAngularConstants();
    const openDataMountPath = getOpenDataMountPath();
    let loginWindow;

    if (isIntegrated) {
      loginWindow = window.open(loginPath + '?return_to=' + openDataMountPath, '_self', 'height=570,width=1020,scrollbars=yes,status=yes,modal=yes,alwaysRaised=yes');
    } else {
      loginWindow = window.open(loginPath, 'newWindow', 'height=570,width=1020,scrollbars=yes,status=yes,modal=yes,alwaysRaised=yes');
    }
    checkLoginWindow(loginWindow);
    GlobalEvents.emit('LoginWindow::Opened');
  };

  const signOut = () => {
    localStorage.clear();
    window.location.href = '/auth/signout';
  };

  return { signIn, signOut };
};

export default useAuthLogin;
