import { UPDATE_CURRENT_USER } from "../actions/userActions";
import { UserReducer } from "../types/stateTypes";

interface Action extends UserReducer {
  type: string;
  currentUser?: Partial<UserReducer>;
}

const initialState: UserReducer = {
    data: {},
    loggedIn: false,
    isLaunchpadAdmin: false
};

const userReducer = (state = initialState, action: Action): UserReducer => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        ...action.currentUser
      };
    default:
      return state;
  }
};

export default userReducer;
