import { ForgeButton, ForgeTextField } from '@tylertech/forge-react';
import _ from 'lodash';

interface AddUserEmailProps {
  email: string;
  onChangeEmail: (value: string) => void;
  onClickSave: () => void;
}

function AddUserEmail({ email, onChangeEmail, onClickSave }: AddUserEmailProps) {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeEmail(e.target.value);
  };

  const renderAddButton = () => { 
    return (
      <ForgeButton variant="raised" disabled={_.isEmpty(email)}  onClick={ onClickSave }>
        Add user
      </ForgeButton>
    );
  };

  return (
    <div className="add-user-section col-md-7">
      <ForgeTextField
        density="default"
        floatLabelType="auto"
        className="user-email-search">
        <input
          value={email}
          onChange={onChange}
          autoComplete="off"
          type="email"
          id="add-user-by-email"
          placeholder="By socrata id email address"
        />
      </ForgeTextField>
      {renderAddButton()}
    </div>
  );
}

export default AddUserEmail;
