import { BRANDING_COLORS } from '../../appConstants';
import ForgeInputTextField from '../../components/Common/ForgeInputTextField';
import useBranding from '../../hooks/useBranding';
import { ForgeButton } from '@tylertech/forge-react';
import ForgeColorInput from '../../components/Common/ForgeColorInput';
import LoadingSpinner from '../../components/LoadingSpinner';

const Branding = () => {
  const {
    isLoading,
    isConfigChanges,
    brandingData,
    handleInputChange,
    updateBrandingConfig,
    onSelectColorValue
  } = useBranding();

  const renderBrandingHeader = () => {
    return (
      <div className="branding-header">
        <h1 className="title forge-typography--subheading4">Browser Tab</h1>
        <ForgeButton
          variant="raised"
          onClick={updateBrandingConfig}
          disabled={!isConfigChanges}
        >
          Save
        </ForgeButton>
      </div>
    );
  };

  return (
    <div className="branding-container">
      <LoadingSpinner isLoading={isLoading} />
      {renderBrandingHeader()}
      <div className="branding-items w-50">
        <ForgeInputTextField
          type="text"
          className=""
          label="Browser title"
          id="browser-title"
          value={brandingData?.browserTitle}
          hasHelperText={true}
          helperText="Enter the name that appears in the browser tab."
          handleOnChange={(e) => handleInputChange(e, 'browserTitle')}
        />
      </div>

      <div className="branding-items w-75">
        <ForgeInputTextField
          type="text"
          className=""
          label="Browser Favicon"
          id="customer_fav_icon"
          value={brandingData?.customerFavIcon}
          hasHelperText={false}
          helperText=""
          handleOnChange={(e) => handleInputChange(e, 'customerFavIcon')}
        />
      </div>
      <h1 className="title forge-typography--subheading4 mt-5 mb-4">Header Bar</h1>

      <div className="branding-items w-50">
        <ForgeInputTextField
          className=""
          type="text"
          label="Header Title"
          id="header_title"
          value={brandingData?.title}
          hasHelperText={true}
          helperText="Text to be displayed after the logo."
          handleOnChange={(e) => handleInputChange(e, 'title')}
        />
      </div>

      <div className="branding-items w-50">
        <label htmlFor="branding-bar-color">Header Title Color</label>
        <ForgeColorInput
          title="Header Title Color"
          uniqueId={'branding-bar-color'}
          colorOptions={BRANDING_COLORS}
          colorInputValue={brandingData.headerTitleColor || ''}
          onChangeColorValue={(result) => onSelectColorValue(result, 'headerTitleColor')} />
      </div>

      <div className="branding-items w-50">
        <label htmlFor="header_bar_background_color">Header Bar Background Color</label>
        <ForgeColorInput
          title="Header Bar Background Color"
          uniqueId={'header_bar_background_color'}
          colorOptions={BRANDING_COLORS}
          colorInputValue={brandingData.headerBarBackgroundColor || ''}
          onChangeColorValue={(result) => onSelectColorValue(result, 'headerBarBackgroundColor')} />
      </div>

      <div className="branding-items w-75">
        <ForgeInputTextField
          className=""
          type="text"
          label="Header Icon"
          id="branding_logo"
          value={brandingData.logo}
          hasHelperText={false}
          helperText=""
          handleOnChange={(e) => handleInputChange(e, 'logo')}
        />
      </div>
    </div>
  );
};

export default Branding;
