import { ForgeIcon, ForgeList, ForgeListItem } from '@tylertech/forge-react';
import _ from 'lodash';
import { isEnterButtonPressed } from './mouseEventHelper';

interface CustomForgeListProps {
  className?: string;
  options: Array<any>;
  title?: React.ReactNode;
  onSelect: (entry: any) => void;
  optionDisplayField: string;
  showDescription?: boolean;
}

const CustomForgeList = (props: CustomForgeListProps) => {
  const {
    options,
    onSelect,
    optionDisplayField,
    showDescription,
  } = props;

  const onEnterListItem = (e: React.KeyboardEvent, entry: any) => {
    const isDisabled = _.get(entry, 'disabled', false);
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      if (!isDisabled) {
        onSelect(entry);
      }
    }
  };

  const renderWithDescription = (entry: any) => {
    const displayName = _.get(entry, optionDisplayField);
    const description = _.get(entry, 'description');

    return (
      <div className="dropdown-display-wrapper">
        <div className="dropdown-display-name">{displayName}</div>
        {showDescription && !_.isEmpty(description) && (
          <span className="dropdown-description mt-1">{description}</span>
        )}
      </div>
    );
  };

  const renderListItem = (entry: any, index: number) => {
    const leadingIcon = _.get(entry, 'icon_name');
    const isDisabled = _.get(entry, 'disabled', false);

    return (
      <ForgeListItem
        disabled={isDisabled}
        key={index}
        onClick={() => {
          if (!isDisabled) {
            onSelect(entry);
          }
        }}
        onKeyDown={(e: any) => onEnterListItem(e, entry)}
      >
        {!_.isEmpty(leadingIcon) && (
          <ForgeIcon slot="leading" name={leadingIcon} />
        )}
        <button type="button">{renderWithDescription(entry)}</button>
      </ForgeListItem>
    );
  };

  return (
    <div className={props.className}>
      <ForgeList className="">{_.map(options, renderListItem)}</ForgeList>
    </div>
  );
};

export default CustomForgeList;
