import { useRef } from 'react';
import { ForgeButton, ForgeIcon, ForgePopover } from '@tylertech/forge-react';
import CustomForgeList from './CustomForgeList';
import _ from 'lodash';
import { IPopoverComponent } from '@tylertech/forge';

interface CustomDropdownListProps {
  className?: string;
  id: string;
  options: any[];
  onSelect: (option: any) => void;
  disabled?: boolean;
  value: string;
  valueField: string;
  optionDisplayField: string;
  iconName?: string;
  placement?: string;
  showDescription?: boolean;
}

const CustomDropdownList = (props: CustomDropdownListProps) => {
  const {
    id,
    iconName,
    placement,
    options,
    onSelect,
    optionDisplayField,
    showDescription,
    valueField,
    value,
  } = props;
  const targetPopupRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<IPopoverComponent>(null);

  const title = _.find(options, (datum) => datum[valueField] === value)?.[
    optionDisplayField
  ];

  const onListOptionSelect = (option: any) => {
    handlePopoverClose();
    onSelect(option);
  };

  const renderListOptions = () => {
    return (
      <CustomForgeList
        options={options}
        onSelect={onListOptionSelect}
        optionDisplayField={optionDisplayField}
        showDescription={showDescription}
      />
    );
  };

  const handlePopoverClose = () => {
    if (popoverRef.current) {
      popoverRef.current.open = false;
    }
  };

  return (
    <>
      <div
        ref={targetPopupRef}
        className="dropdown-container forge-popup-host"
        title={title}
        id={'dropdown-custom' + id}
        role="dialog"
      >
        {iconName && <ForgeIcon name={iconName} />}
        <ForgeButton className="dropdown-placeholder forge-typography--body1">
          {title}
          <ForgeIcon name="keyboard_arrow_down" />
        </ForgeButton>
      </div>
      <ForgePopover
        ref={popoverRef}
        anchor={'dropdown-custom' + id}
        targetElementRef={targetPopupRef}
        options={{ placement }}
        className="custom-dropdown-list-popup"
      >
        <div className="custom-dropdown-list-popup-wrapper">
          {renderListOptions()}
        </div>
      </ForgePopover>
    </>
  );
};

export default CustomDropdownList;
