import { useEffect, useRef, useState } from "react";
import useLocationUpdateWithParams from "./useLocationUpdateWithParams";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "reducers/rootReducer";
import useAuthLogin from "./useAuthLogin";
import { ADMIN_MENU_ITEM } from "../appConstants";
import useUserAccessData from "./data/useUserAccessData";
import { getBellerophonUrl, getConfig, getI18nConfig } from "../helper/serverConfigHelper";
import useManageAccess from "./useManageAccess";
import { IPopoverComponent } from "@tylertech/forge";

const useHeaderProfile = () => {
  const [isAdminUser, setIsAdminUser] = useState(false);
  const { updateLocationWithParams } = useLocationUpdateWithParams();
  const currentUser: any = useSelector<RootState>((store) => store.currentUser);
  const { loggedIn, data, isLaunchpadAdmin } = currentUser;
  const { welcome_label_text: welcomeLabelText } = getI18nConfig();
  const { header_bar_text_color: headerBarTextColor } = getConfig();
  const { signOut } = useAuthLogin(_.noop);
  const nickname = `${welcomeLabelText}, ${_.get(data, "nickname", "")}`;
  const popoverRef = useRef<IPopoverComponent>(null);

  const [headerDropdownIcon, setHeaderDropdownIcon] = useState(
    "keyboard_arrow_down"
  );

  const userAccessData = useUserAccessData(currentUser);
  const { isManageBranding, isManageUser, isManageUserProfile } =
    useManageAccess();

  useEffect(() => {
    const { isAdmin } = userAccessData;
    setIsAdminUser(isAdmin ?? false);
  }, [userAccessData]);

  useEffect(() => {
    const popoverElement = popoverRef.current;
    if (popoverElement) {
      popoverElement.addEventListener(
        "forge-popover-beforetoggle",
        onForgePopoverBeforeToggle
      );
    }
    return () => {
      if (popoverElement) {
        popoverElement.removeEventListener(
          "forge-popover-beforetoggle",
          onForgePopoverBeforeToggle
        );
      }
    };
  }, [popoverRef]);

  const { org_id: organizationId, app_id: applicationId } = userAccessData;
  const onClickProfile = (menuOption: any) => {
    if (popoverRef.current) {
      popoverRef.current.open = false;
      setHeaderDropdownIcon("keyboard_arrow_down");
    }
    if (menuOption.value === ADMIN_MENU_ITEM.SIGN_OUT) {
      signOut();
      return;
    }

    if (menuOption.value === ADMIN_MENU_ITEM.ADMINISTRATION) {
      const bellerophonConfigurationUrl =
        getBellerophonUrl() +
        "/config/organization/" +
        organizationId +
        "/" +
        applicationId +
        "/capitalProjectsExplorer/dataset-settings";
      return window.open(bellerophonConfigurationUrl, "_blank");
    }

    updateLocationWithParams(`${menuOption.to}/${menuOption.value}`, {});
  };

  const menuItemAccessMap = {
    [ADMIN_MENU_ITEM.ADMINISTRATION]: isAdminUser,
    [ADMIN_MENU_ITEM.USER_PROFILE]: isManageUserProfile || isLaunchpadAdmin,
    [ADMIN_MENU_ITEM.BRANDING]: isManageBranding || isLaunchpadAdmin,
    [ADMIN_MENU_ITEM.USER_ROLES]: isManageUser || isLaunchpadAdmin,
    [ADMIN_MENU_ITEM.SIGN_OUT]: true,
  };

  const onForgePopoverBeforeToggle = (e: any) => {
    if (e.detail.newState === "closed") {
      setHeaderDropdownIcon("keyboard_arrow_down");
    } else {
      setHeaderDropdownIcon("keyboard_arrow_up");
    }
  };

  return {
    nickname,
    loggedIn,
    onClickProfile,
    isAdminUser,
    isLaunchpadAdmin,
    menuItemAccessMap,
    popoverRef,
    headerDropdownIcon,
    headerBarTextColor
  };
};

export default useHeaderProfile;
