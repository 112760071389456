import { getI18nConfig } from '../helper/serverConfigHelper';
import _ from 'lodash';

const smallAbbreviation = ['', 'k', 'm', 'b', 't', 'qd', 'qt'];
const i18nStrings = getI18nConfig();

const fullAbbreviation = (): string[] => {
  const fullCurrencyUnits = i18nStrings.full_currency_units;
  return ['', ...fullCurrencyUnits.split(',')];
};

const abbrNum = (
  number: number,
  decPlaces: number,
  abbrev: string[]
): string => {
  const decPlacesMultiplier = Math.pow(10, decPlaces);

  const index = _.findLastIndex(abbrev, (_, i) => number >= Math.pow(10, (i + 1) * 3));
  if (index === -1) {
    return number.toString();
  }

  let shortNumber = Math.round((number * decPlacesMultiplier) / Math.pow(10, (index + 1) * 3)) / decPlacesMultiplier;

  if (shortNumber % 1 === 0) {
    shortNumber = parseFloat(shortNumber + String(decPlacesMultiplier).replace('1', '.'));
  }

  if (shortNumber === 1000 && index < abbrev.length - 1) {
    shortNumber = 1;
    return shortNumber + abbrev[index + 1];
  }

  return shortNumber + abbrev[index];
};

export const formatNumber = (number: number | undefined, options: {
  currency?: string;
  fullTextMultiplier?: boolean;
  fixedPrecision?: number;
  addAbbr?: boolean;
} = {}): string => {
  const {
    currency = '',
    fullTextMultiplier = false,
    fixedPrecision,
    addAbbr = false
  } = options;

  if (_.isUndefined(number)) {
    return '0';
  }

  const isNegativeNumber = number < 0;
  if (isNegativeNumber) {
    number = Math.abs(number);
  }

  let formattedNumberString = '';

  if (number === 0) {
    formattedNumberString = '0';
  } else if (addAbbr) {
    const abbrev = fullTextMultiplier
      ? fullAbbreviation()
      : smallAbbreviation.slice();
    abbrev.shift();
    formattedNumberString = abbrNum(number, fixedPrecision || 2, abbrev);
  } else if (fixedPrecision !== undefined && fixedPrecision > 0) {
    const value = Number(number.toFixed(fixedPrecision));
    formattedNumberString = new Intl.NumberFormat().format(value);
  } else {
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
    formattedNumberString = formatter.format(number);
  }

  return `${isNegativeNumber ? '-' : ''}${currency}${formattedNumberString}`;
};
