import { getConfig } from '../helper/serverConfigHelper';

const useMapDistrictMapShapeLayer = () => {
  const customerConfigurations = getConfig();

  const lableColor = customerConfigurations.district_map_label_color;
  const shapeFillColor = customerConfigurations.district_map_shape_fill_color;
  const shapeFillHighlightColor = customerConfigurations.filter_section_active_state_color || "#1DAB3A";
  const shapeOutlineColor = customerConfigurations.district_map_shape_outline_color;


  const addDistrictShapeLayer = (map: mapboxgl.Map) => {
    map.addSource('district-map', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addLayer({
      'id': 'district-map-fill',
      'type': 'fill',
      'source': 'district-map',
      'paint': {
        'fill-opacity': 0.5,
        'fill-color': shapeFillColor
      }
    });

    map.addLayer({
      'id': 'district-map-fill-highlight',
      'type': 'fill',
      'source': 'district-map',
      'filter': ['all', ['==', 'id', '']],
      'paint': {
        'fill-opacity': 1,
        'fill-color': shapeFillHighlightColor
      }
    });

    map.addLayer({
      'id': 'district-map-outlines',
      'type': 'line',
      'source': 'district-map',
      'paint': {
        'line-color': shapeOutlineColor,
        'line-width': 1.5
      }
    });
  };

  const addDistrictLableLayer = (map: mapboxgl.Map) => {
    map.addSource('district-label', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addLayer({
      'id': 'district-map-label',
      'type': 'symbol',
      'source': 'district-label',
      'layout': {
        'text-field': '{name}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-allow-overlap': true,
        'text-size': 13
      },
      'paint': {
        'text-color': lableColor
      }
    });
  };

  return {
    addDistrictShapeLayer,
    addDistrictLableLayer
  };
};

export default useMapDistrictMapShapeLayer;
