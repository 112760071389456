import SearchBar from './SearchBar';
import SearchTypesDropdown from './SearchTypesDropdown';
import { checkIfLoginPage } from '../../helper/serverConfigHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { updateShowSearchContainer } from '../../actions/searchActions';

const SearchContainer = () => {
  const showSearchContainer = useSelector<RootState, boolean>((store) => store.search.showSearchContainer);
  const isLoginPage = checkIfLoginPage();
  const dispatch = useDispatch();

  const handleToggleSearch = () => {
    if (!showSearchContainer) {
      dispatch(updateShowSearchContainer(true));
    }
  };

  if (isLoginPage) {
    return null;
  }

  return (
    <li className="search-type-container">
      <div className="search-type-input-container">
        {showSearchContainer && (
          <>
            <SearchTypesDropdown />
            <SearchBar />
          </>
        )}
      </div>
      <button
        type="button"
        className="search-btn"
        onClick={handleToggleSearch} >
        <span
          className={`fa fa-search search-icon ${showSearchContainer ? 'hide' : ''}`}>
        </span>
        Search
      </button>
    </li>
  );
};

export default SearchContainer;
