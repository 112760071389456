import { useState, useMemo, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { PROJECT_SORT_TYPE } from "../appConstants";
import useProjectDetail from "./useProjectDetail";
import { getSearchDropdownEntries } from "../components/Header/helpers/searchHelper";
import { RootState } from "../reducers/rootReducer";
import { getItemPerPage, isPaginateProject } from "../helper/configHelper";

export const useProjectList = () => {
  const filteredProjects: any = useSelector<any>((store) => store.projects.filteredProjects);
  const itemPerPage = getItemPerPage();
  const [pageEntry, setPageLimit] = useState<Record<string, any>>({ limit: itemPerPage, index: 0 });
  const [projectListData, setProjectListData] = useState(filteredProjects);
  const [sortType, setSortType] = useState<string | null>(PROJECT_SORT_TYPE.COST_VARIANCE);
  const [isCostSortAsc, setIsCostSortAsc] = useState<boolean>(false);
  const [isNameSortAsc, setIsNameSortAsc] = useState<boolean>(false);
  const [toggleCostVariance, setToggleCostVariance] = useState(true);

  const { onProjectSelect } = useProjectDetail();

  const start = useMemo(() => pageEntry.index * pageEntry.limit, [pageEntry]);
  const end = useMemo(() => start + pageEntry.limit, [start, pageEntry]);

  useEffect(() => {
    const fieldName = sortType === PROJECT_SORT_TYPE.NAME ? 'project_name' :
      (sortType === PROJECT_SORT_TYPE.COST_VARIANCE ? 'percent_cost_variance' : 'percent_schedule_variance');
    const isSortType = sortType === PROJECT_SORT_TYPE.NAME ? isNameSortAsc : isCostSortAsc;
    const sortableData = getSortProjectsByOrder(fieldName, isSortType);
    setProjectListData(sortableData);
    setPageLimit({ limit: itemPerPage, index: 0 });
  }, [filteredProjects]);

  const handlePaginatorChange = (limit: number, index: number) => {
    setPageLimit({ limit, index });
  };

  const searchField = useSelector<RootState>((store) => store.search.searchField);
  const searchEntries = getSearchDropdownEntries();
  const searchEntry = _.find(
    searchEntries,
    (entry) => entry.mapped_field_name === searchField
  );

  const showPagination = isPaginateProject();

  const handleRowClick = (project: any) => {
    const projectId = _.get(project, "project_id", "");
    onProjectSelect(projectId);
  };

  const sortByProjects = (type: string, fieldName = 'project_name') => {
    setIsNameSortAsc(!isNameSortAsc);
    setSortType(type);
    const sortableData = getSortProjectsByOrder(fieldName, !isNameSortAsc);
    setProjectListData(sortableData);
  };

  const sortByProjectCost = (fieldName = 'percent_cost_variance', isAscending: boolean) => {
    const sortableData = getSortProjectsByOrder(fieldName, isAscending);
    setProjectListData(sortableData);
  };

  const getSortProjectsByOrder = (fieldName = 'project_name', isAscending: boolean) => {
    const sortableData = _.orderBy(
      filteredProjects,
      [
        (project: any) =>
          typeof project[fieldName] === 'string'
            ? project[fieldName].toLowerCase()
            : project[fieldName],
      ],
      [isAscending ? 'asc' : 'desc']
    );
    return sortableData;
  };

  const toggleVariance = (type: string) => {
    let sortByField = "percent_schedule_variance";
    if (type === PROJECT_SORT_TYPE.COST_VARIANCE) {
      sortByField = "percent_cost_variance";
    }
    if (toggleCostVariance && type === PROJECT_SORT_TYPE.COST_VARIANCE) {
      setToggleCostVariance(false);
      setSortType(PROJECT_SORT_TYPE.SCHEDULE_VARIANCE);
      sortByField = "percent_schedule_variance";
    } else if (!toggleCostVariance && type === PROJECT_SORT_TYPE.SCHEDULE_VARIANCE) {
      setToggleCostVariance(true);
      setSortType(PROJECT_SORT_TYPE.COST_VARIANCE);
      sortByField = "percent_cost_variance";
    } else {
      setToggleCostVariance(type === PROJECT_SORT_TYPE.COST_VARIANCE);
      setSortType(type);
    }

    sortByProjectCost(sortByField, isCostSortAsc);
  };

  const sortByVariance = () => {
    setIsCostSortAsc(!isCostSortAsc);
    setSortType(PROJECT_SORT_TYPE.VARIANCE);
    let sortByField = "percent_schedule_variance";
    if (toggleCostVariance) {
      sortByField = "percent_cost_variance";
    }
    sortByProjectCost(sortByField, !isCostSortAsc);
  };

  const paginatedProjects = useMemo(
    () => projectListData.slice(start, end),
    [projectListData, start, end]
  );

  const projectData = showPagination ? paginatedProjects : projectListData;

  return {
    toggleCostVariance,
    handlePaginatorChange,
    handleRowClick,
    sortProjects: sortByProjects,
    toggleVariance,
    sortByVariance,
    paginatedProjects,
    pageEntry,
    isCostSortAsc,
    isNameSortAsc,
    sortType,
    projectListData,
    projectData,
    searchEntry,
    searchField
  };
};
