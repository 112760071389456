import { RefObject, useEffect, useState } from "react";

interface UseHeaderOverflowProps {
  appBarRef: RefObject<HTMLDivElement>;
  listsRef: RefObject<HTMLUListElement>;
  isNavBarOpen: boolean;
}

export const useHeaderOverflow = ({
  appBarRef,
  listsRef,
  isNavBarOpen,
}: UseHeaderOverflowProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const lists = listsRef.current;
    const appBar = appBarRef.current;
    const threshold = 300;

    const checkOverflow = () => {
      if (lists && appBar) {
        const appBarWidth = appBar.clientWidth || 0;
        const listsWidth = lists.clientWidth || 0;
        const isOverflowing = listsWidth + threshold > appBarWidth;
        if (isOverflowing) {
          setIsOverflowing(true);
          appBar.classList.add("overflowing");
        } else {
          setIsOverflowing(false);
        }
      }
    };
    setTimeout(checkOverflow, 10);

    // Observe parent size changes
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });

    if (appBar) {
      resizeObserver.observe(appBar);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [appBarRef, listsRef, isNavBarOpen]);

  return { isOverflowing };
};
