import _ from 'lodash';
import { getConfig } from './serverConfigHelper';


export const getHomePageCarouselConfigs = () => {
	return JSON.parse(_.get(getConfig(), 'home_page_carousel_configs', '[]'));
};

export const getCurrentUrl = () => {
	const currentUrl = window.location.href.replace('/#/', '/');
	return currentUrl;
};

export const getHeaderLogoUrl = () => {
	const brandingConfig = _.get(getConfig(), 'branding', {});
	return _.get(brandingConfig, 'logo', '');
};

export const hideAdminOptions = () => {
    const isPublicApp = _.get(getConfig(), 'is_private', 'false') === 'true',
		isSignInHidden = _.get(getConfig(), 'show_sign_in', 'no') === 'yes';
    return  (isPublicApp && isSignInHidden);
};

export const isManageApp = (loginEmail:string, key:string ) => {
	const cpeAppUsers = JSON.parse(_.get(getConfig(), 'cpe_app_users', "[]"));
	return  _.some(cpeAppUsers, (appUser) =>  appUser.email === loginEmail && appUser[key] === 'true');
};

export const checkAdminUser = (loginEmail:string) => {
	const cpeAppUsers = JSON.parse(_.get(getConfig(), 'cpe_app_users', "[]"));
	return  _.some(cpeAppUsers, (appUser) =>  appUser.email === loginEmail 
		&& appUser['isAdminUser'] === 'true' && appUser['isManageUser'] === 'true');
};

export const isUserInSecurityList = (loginEmail:string) => {
	const cpeAppUsers = JSON.parse(_.get(getConfig(), 'cpe_app_users', "[]"));
	return  _.some(cpeAppUsers, (appUser) =>  appUser.email === loginEmail);
};

export const showFacebookLogo = () => {
	const socialConfig =  _.get(getConfig(), 'social', {});
	const canShowLogo = _.get(	socialConfig, 'show_facebook_share', '');
	return _.isEqual(canShowLogo, 'show');
};

export const showTwitterLogo = () => {
	const socialConfig =  _.get(getConfig(), 'social', {});
	const canShowLogo = _.get(socialConfig, 'show_twitter_share', '');
	return _.isEqual(canShowLogo, 'show');
};

export const getTwitterShareText = () => {
	const socialConfig =  _.get(getConfig(), 'social', {});
	const twitterShareText = _.get(socialConfig, 'twitter_share_text', '');

	if (twitterShareText) {
		return twitterShareText + ' ' + getCurrentUrl();
	} else {
		return getCurrentUrl();
	}
};

export const getShapeColors = () => {
	return JSON.parse(_.get(getConfig(), 'shape_colors', '[]'));
};

export const getProjectStagesFilters = () => {
	const configuration = getConfig();
	if (_.isUndefined((configuration.fields || {}).project_stage_id)) {
	  return "";
	}
	return JSON.parse(configuration.project_stages);
};

export const getProjectTypeFiltersTitle = () => {
	return _.get(getConfig(), 'project_type_filter_header_text', '');
};

export const getProjectStageFiltersTitle = () => {
	return _.get(getConfig(), 'project_stage_filter_header_text', '');
};

export const getProjectStageFilterEntries = () => {
	return JSON.parse(_.get(getConfig(), 'project_stages', '[]'));
};

export const getEnableMultiCategorySupportConfig = (): any => {
	return _.get(getConfig(), 'enable_muti_category_support', '') === "true";
};

export const getProjectFundTypeEntries = () => {
	return JSON.parse(_.get(getConfig(), 'fund_types', '[]'));
};

export const getProjectTypeFilterEntries = () => {
	return JSON.parse(_.get(getConfig(), 'project_types', '[]'));
};

export const getShowFundTypeFilterConfig = () => {
	const showFundTypeFilter =  _.get(getConfig(), 'show_fund_type_filter', 'yes');
	return _.isEqual(showFundTypeFilter, 'yes');
};

export const getFundFilterTitleConfig = () => {
	return _.get(getConfig(), 'fund_type_filter_header_text', '');
};

export const getProjectFiltersCondition = () => {
	return _.get(getConfig(), 'project_filters_condition', 'or');
};

export const getCurrencySymbol= (): string => {
	return _.get(getConfig(), 'currency_symbol', '$');
};

export const getFixedPrecision = ():  number => {
	return _.toNumber(_.get(getConfig(), 'fixed_precision', 1));
};

export const getRoundNumbers = (): string => {
	return _.get(getConfig(), 'rounding_options', '');
};

export const getInitiativeIcons= (): any => {
	return JSON.parse(_.get(getConfig(), 'initiative_icons','' ));
};

export const getDefaultImage= (): string => {
	return _.get(getConfig(), 'default_image', '');
};

export const getProjectLogoImg = (): string => {
	return _.get(getConfig(), 'project_website_logo_img', '');
};

export const getWebsiteLabelText = (): string => {
	return _.get(getConfig(), 'project_website_label', '');
};

export const getBudgetIndicators = (): any => {
	return JSON.parse(_.get(getConfig(), 'budget_indicators', '[{}]'));
};

export const getScheduleIndicators = (): any => {
	return JSON.parse(_.get(getConfig(), 'schedule_indicators', '[{}]'));
};

export const getOptionalIndicators = (): any => {
	return JSON.parse(_.get(getConfig(), 'optional_indicators', '[{}]'));
};

export const getProjectColumnFilterPosition = (): string => {
	return _.get(getConfig(), 'project_filters_position', 'top');
};

export const getEnableFinancialConfig = (): string => {
	return _.get(getConfig(), 'enable_financial', 'true');
};

export const getProjectFinancialConfig  = (): any => {
	return _.get(getConfig(), 'financial', {});
};

export const isFinancialEnabled  = (): boolean => {
	return _.get(getConfig(), 'enable_financial', 'false') === 'true';
};

export const pieChartSpentLabel  = (): string => {
	return _.get(getConfig(), 'project_stage_pie_chart_spent_label', 'Spent');
};

export const pieChartScheduleLabel  = (): string => {
	return _.get(getConfig(), 'project_stage_pie_chart_schedule_label', 'Schedule Complete');
};

export const pieChartWorkLabel  = (): string => {
	return _.get(getConfig(), 'project_stage_pie_chart_work_label', 'Work Complete');
};

export const getProjectId = (): boolean => {
	return _.get(getConfig(), 'show_project_id', "hide") === "show";
};

export const getProjectTypeHeader = (): string => {
	return _.get(getConfig(), 'project_type_filter_header_text', "");
};

export const getEmptyDataPhrase = (): string => {
	return _.get(getConfig(), 'empty_data_phrase', "");
};

export const getProjectStageColors = (): any => {
	return JSON.parse(_.get(getConfig(), 'project_stage_colors', '[]'));
};

export const getProjectTypeColors = (): any => {
	return JSON.parse(_.get(getConfig(), 'project_type_colors', '[]'));
};

export const getFinancialChartTitle = (): string => {
	return _.get(getConfig(), 'financial_chart_title', "Cost Variance");
};

export const getLineChartPrimaryColor = (): string => {
	return _.get(getConfig(), 'line_chart_primary_color', "black");
};

export const getLineChartSecondaryColor = (): string => {
	return _.get(getConfig(), 'line_chart_secondary_color', "gray");
};

export const getLineChartTeriaryColor = (): string => {
	return _.get(getConfig(), 'line_chart_tertiary_color', "blue");
};

export const isVarianceMatrix = (): boolean => {
	return _.get(getConfig(), 'show_advanced_project_metrics', 'hide') === 'show';
};

export const getStartDateTypeLabelOne = () => {
	return _.get(getConfig(), 'project_stage_start_date_type_label_one', 'Estimated');
};

export const getStartDateTypeLabelTwo = () => {
	return _.get(getConfig(), 'project_stage_start_date_type_label_two', 'Forecasted');
};

export const getStartDateTypeLabelThree = () => {
	return _.get(getConfig(), 'project_stage_start_date_type_label_three', 'Actual');
};

export const getEndDateTypeLabelOne = () => {
	return _.get(getConfig(), 'project_stage_end_date_type_label_one', 'Estimated');
};

export const getEndDateTypeLabelTwo = () => {
	return _.get(getConfig(), 'project_stage_end_date_type_label_two', 'Forecasted');
};

export const getEndDateTypeLabelThree = (): string => {
	return _.get(getConfig(), 'project_stage_end_date_type_label_three', 'Actual');
};

export const isShowAdvancedProjectMetrics = () => {
	return _.get(getConfig(), 'show_advanced_projects_list_metrics', '') === "show";
};

export const getProjectListConfig = (): any => {
	return _.get(getConfig(), 'project_list', {});
};

export const isPaginateProject = (): boolean => {
	return _.get(getConfig(), 'show_pagination', 'false') === "true";
};

export const getItemPerPage = (): string | number => {
	return _.get(getConfig(), 'pagination_list_limit', 50);
};

export const getProjectTypesMatrix = () => {
  return JSON.parse(_.get(getConfig(), 'project_types', '[]'));
};

export const getVarianceMatrixStrings = (): any => {
  return _.get(getConfig(), 'variance_matrix_strings', '{}');
};

export const  getProjectTypesForMatrix = () => {
	let projectTypes = getProjectTypesMatrix(),
		matrixProjectTypes = _.map(projectTypes,  (projectType) => {
			var hash : any = {name: projectType.name, categoryId: {}};
			hash['categoryId'][projectType.type] = _.compact(_.map(projectType.childConfigs,  (childConfig) => {
				return childConfig.type;
			}));
			return hash;
		});
	return [{ 'type': 'all', 'categoryId' : {all: []}, name: 'All Projects'}].concat(matrixProjectTypes);
};

export const getCustomerName = (): string => {
	return _.get(getConfig(), 'customer_name', '');
};

export const isShowFinancialChart = (): boolean => {
	return _.get(getConfig(), 'show_financial_charts',"") === "show";
};
