import { Project } from 'types/projectTypes';
import useMap from '../../../hooks/useMap';

interface MapProps {
  isSingleProject: boolean;
  zoom: number;
  center: { lng: number, lat: number };
  showShapes: boolean;
  projects: Project;
  mapId: string;
}

const Map = (props: MapProps) => {
  const { isSingleProject, zoom, center, showShapes, projects = [], mapId } = props;
  const {
    mapContainer
  } = useMap(isSingleProject, zoom, center, showShapes, projects, mapId);

  return (
    <>
      <div id={mapId} ref={mapContainer}></div>
    </>
  );
};

Map.defaultProps = {
  isSingleProject: false,
  showPopup: false,
  showShapes: false,
  onShapeSelect: () => { },
  projects: []
};

export default Map;
