import { useEffect, useState } from 'react';
import { ForgeAutocomplete, ForgeTextField } from '@tylertech/forge-react';
import _ from 'lodash';

interface ColorOption {
  name: string;
  value: string;
}

interface ForgeColorInputProps {
  title: string;
  uniqueId: string;
  colorOptions: ColorOption[];
  colorInputValue: string;
  onChangeColorValue: (selectedColor: string) => void;
}

const ForgeColorInput = ({
  title,
  uniqueId,
  colorOptions,
  colorInputValue,
  onChangeColorValue,
}: ForgeColorInputProps ) => {
  const [searchInputColor, setSearchInputColor] = useState<string>(colorInputValue);

  useEffect(() => {
    setSearchInputColor(colorInputValue);
  }, [colorInputValue]);

  const optionBuilder = (option: ColorOption) => {
    return `
      <div class='color-selector'>  
      <span class="square square-color" style="background-color: ${option.value}"></span>
      <span slot="title" class="list-subtitle">${option.name}</span>
      </div>
      `;
  };

  const handleChangeColorValue = (event: CustomEvent) => {
    const selectedColorText = _.get(event, 'detail', '');
    onChangeColorValue(selectedColorText);
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchColorText = e.target.value;
    setSearchInputColor(searchColorText);
  };

  return (
    <ForgeAutocomplete
      id={uniqueId}
      name={uniqueId}
      allowUnmatched={true}
      optionBuilder={optionBuilder}
      optionLimit={30}
      on-forge-autocomplete-change={handleChangeColorValue}
      filterText={colorInputValue}
      value={colorInputValue}
      filter={(filterText) => {
        return colorOptions.filter(
          (item) => item && item.value.toLowerCase().includes(filterText.toLowerCase())
        );
      }}
    >
      <ForgeTextField>
        <span
          className="square"
          style={{ backgroundColor: searchInputColor }}
        ></span>
        <input
          type="text"
          aria-label={title}
          value={searchInputColor}
          onChange={onSearchInputChange}
          placeholder={searchInputColor || 'Search color'}
        />
      </ForgeTextField>
    </ForgeAutocomplete>
  );
};

export default ForgeColorInput;
