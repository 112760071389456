import {
  tylIconStar,
  tylIconPerson,
  tylIconArrowDropDown,
  tylIconArrowRight,
  tylIconMoreVert,
  tylIconCheckCircle,
  tylIconAdd,
  tylIconDelete,
  tylIconFilterListAlt,
  tylIconOpenInNew,
  tylIconEvent,
  tylIconBookmark,
  tylIconDirections,
  tylIconAccessAlarm,
  tylIconArrowUpward,
  tylIconArrowDownward,
  tylIconArrowForward,
  tylIconKeyboardArrowDown,
  tylIconKeyboardArrowUp,
  tylIconAddCircleOutline,
  tylIconFilterList,
  tylIconClose,
  tylIconArrowBack,
  tylIconSettings,
  tylIconCheck,
  tylIconMap,
  tylIconMapsUgc,
  tylIconLocationPin,
  tylIconCompare,
  tylIconModeEdit,
  tylIconCategory,
  tylIconBarChart,
  tylIconArrowBackIos,
  tylIconArrowForwardIos,
  tylIconHelp,
  tylIconHelpOutline,
  tylIconPeople,
  tylIconSchool,
  tylIconHeadsetMic,
  tylIconInfoOutline,
  tylIconInfo,
  tylIconLightbulb,
  tylIconSearch,
  tylIconVpnKey,
  tylIconDeleteOutline,
  tylIconContentCopy,
  tylIconDragIndicator,
  tylIconWarning,
  tylIconSchedule,
  tylIconLock,
  tylIconPublic,
  tylIconLogout,
  tylIconLockOutline,
  tylIconDomain,
  tylIconNotificationImportant,
  tylIconReport,
  tylIconError,
  tylIconRefresh,
  tylIconGetApp,
  tylIconReplay,
  tylIconCompareArrows,
  tylIconKeyboardArrowRight,
  tylIconKeyboardArrowLeft,
  tylIconPlayArrow,
  tylIconPause,
  tylIconSave,
  tylIconMedicalServices
} from '@tylertech/tyler-icons/standard';
// Extended Icons
import {
  tylIconDatabase,
  tylIconArchive,
  tylIconCloseOctagon,
  tylIconToggleSwitchOutline,
  tylIconToggleSwitchOffOutline,
  tylIconWrenchOutline,
  tylIconCommentTextOutline,
  tylIconCheckboxBlankCircleOutline,
  tylIconCheckboxBlankCircle,
  tylIconAnimation,
  tylIconPlusThick,
  tylIconCalendar,
  tylIconMapMarkerMultiple,
  tylIconChartBar,
  tylIconChartScatterPlot,
  tylIconChartTimelineVariant,
  tylIconChartPie,
  tylIconChartAreaspline,
  tylIconTimeline,
  tylIconLink,
  tylIconFilterVariantPlus,
  tylIconLinkVariant,
  tylIconChevronDown,
  tylIconLightbulbOnOutline,
  tylIconEmail,
  tylIconListStatus,
  tylIconHammerWrench,
  tylIconTrashCan,
  tylIconTools,
  tylIconOfficeBuilding,
  tylIconRun,
  tylIconPlay,
  tylIconReload,
  tylIconAlertCircle,
  tylIconBriefcasePlus,
  tylIconDownload,
  tylIconFeather
} from '@tylertech/tyler-icons/extended';
// Some Custom Icons
import {
  tylIconFilterVariantCheck,
  tylIconTylerTalkingTLogo,
  tylIconActionLauncher,
  tylIconDecisionTree,
} from '@tylertech/tyler-icons/custom';
import { IconRegistry } from '@tylertech/forge';

IconRegistry.define([
  tylIconStar,
  tylIconDatabase,
  tylIconPerson,
  tylIconArrowDropDown,
  tylIconArrowRight,
  tylIconMoreVert,
  tylIconCheckCircle,
  tylIconCloseOctagon,
  tylIconToggleSwitchOutline,
  tylIconToggleSwitchOffOutline,
  tylIconWrenchOutline,
  tylIconCommentTextOutline,
  tylIconAdd,
  tylIconDelete,
  tylIconFilterListAlt,
  tylIconCheckboxBlankCircleOutline,
  tylIconCheckboxBlankCircle,
  tylIconOpenInNew,
  tylIconEvent,
  tylIconCheck,
  tylIconMap,
  tylIconMapMarkerMultiple,
  tylIconLocationPin,
  tylIconCompare,
  tylIconMapsUgc,
  tylIconBookmark,
  tylIconArrowUpward,
  tylIconArrowDownward,
  tylIconArrowForward,
  tylIconKeyboardArrowDown,
  tylIconKeyboardArrowUp,
  tylIconDirections,
  tylIconAccessAlarm,
  tylIconCalendar,
  tylIconAddCircleOutline,
  tylIconFilterList,
  tylIconAnimation,
  tylIconPlusThick,
  tylIconClose,
  tylIconArrowBack,
  tylIconSettings,
  tylIconModeEdit,
  tylIconChartBar,
  tylIconChartScatterPlot,
  tylIconChartTimelineVariant,
  tylIconChartPie,
  tylIconBarChart,
  tylIconChartAreaspline,
  tylIconLightbulb,
  tylIconLink,
  tylIconHelp,
  tylIconHelpOutline,
  tylIconPeople,
  tylIconSchool,
  tylIconHeadsetMic,
  tylIconInfoOutline,
  tylIconInfo,
  tylIconTylerTalkingTLogo,
  tylIconTimeline,
  tylIconCategory,
  tylIconArrowBackIos,
  tylIconArrowForwardIos,
  tylIconFilterVariantCheck,
  tylIconFilterVariantPlus,
  tylIconSearch,
  tylIconVpnKey,
  tylIconDeleteOutline,
  tylIconArrowForward,
  tylIconContentCopy,
  tylIconDragIndicator,
  tylIconWarning,
  tylIconSchedule,
  tylIconLinkVariant,
  tylIconChevronDown,
  tylIconLock,
  tylIconPublic,
  tylIconLogout,
  tylIconLockOutline,
  tylIconDomain,
  tylIconNotificationImportant,
  tylIconLightbulbOnOutline,
  tylIconEmail,
  tylIconListStatus,
  tylIconHammerWrench,
  tylIconCompareArrows,
  tylIconReport,
  tylIconArchive,
  tylIconError,
  tylIconRefresh,
  tylIconGetApp,
  tylIconReplay,
  tylIconTrashCan,
  tylIconDecisionTree,
  tylIconActionLauncher,
  tylIconTools,
  tylIconOfficeBuilding,
  tylIconKeyboardArrowRight,
  tylIconKeyboardArrowLeft,
  tylIconSchedule,
  tylIconPlayArrow,
  tylIconPause,
  tylIconRun,
  tylIconPlay,
  tylIconReload,
  tylIconAlertCircle,
  tylIconSave,
  tylIconBriefcasePlus,
  tylIconMedicalServices,
  tylIconDownload,
  tylIconFeather
]);
