
export const UPDATE_SEARCH_FIELD = "UPDATE_SEARCH_FIELD";
export const UPDATE_SEARCH_TEXT = "UPDATE_SEARCH_TEXT";
export const UPDATE_SHOW_SEARCH_CONTAINER = "UPDATE_SHOW_SEARCH_CONTAINER";

export const updateSearchField = (searchField: string) => ({
  type: UPDATE_SEARCH_FIELD,
  searchField
});

export const updateSearchText = (searchText: string) => ({
  type: UPDATE_SEARCH_TEXT,
  searchText
});

export const updateShowSearchContainer = (showSearchContainer: boolean) => ({
  type: UPDATE_SHOW_SEARCH_CONTAINER,
  showSearchContainer
});
