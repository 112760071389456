import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "reducers/rootReducer";
import { hideAdminOptions, isManageApp, isUserInSecurityList } from "../helper/configHelper";

const useManageAccess = () => {
  const currentUser: any = useSelector<RootState>((store) => store.currentUser);
  const { loggedIn, data } = currentUser;
  const email = _.get(data, "email", "");

  const isManageBranding = () => {
    if (!loggedIn || hideAdminOptions()) {
      return false;
    }
    return isManageApp(email, "isManageBranding");
  };

  const isManageUser = () => {
    if (!loggedIn || hideAdminOptions()) {
      return false;
    }
    return isManageApp(email, "isManageUser");
  };

  const isManageUserProfile = () => {
    if (!loggedIn || hideAdminOptions()) {
      return false;
    }

    return isUserInSecurityList(email);
  };
  

  return {
    isManageBranding : isManageBranding(), 
    isManageUser : isManageUser(), 
    isManageUserProfile : isManageUserProfile()
  };
};

export default useManageAccess;
