import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import _ from "lodash";
import { getBaseMapTheme, getConfig, getMapBoxglAccessToken } from "../helper/serverConfigHelper";
import useMapShapeOutlineLayer from "./useMapShapeOutlineLayer";
import useMapProjectsShapeLayer from "./useMapProjectsShapeLayer";
import useMapPinsLayer from "./useMapPinsLayer";
import {
  formatMultiPointPins,
  formatPins,
  formatProjectLineString,
  formatProjectShapes,
  formatShapes,
  formattedClusterData,
} from "../helper/geoJsonMapFormatter";
import { getDistrictShapes } from "../data/api";
import { getShapeGeomBounds } from "../helper/geometryMapHelper";
import { Shape } from "../types/mapVisualizationTypes";
import PopupHandler from "../handlers/PopupHandler";
import MouseInteractionHandler from "../handlers/MouseInteractionHandler";
import GlobalEvents from "../components/GlobalEvents";
import useProjectDetail from "./useProjectDetail";
import useReactQueryFetch from "./data/useReactQueryFetch";

const useMap = (
  isSingleProject: boolean,
  zoom: number,
  center: { lng: number; lat: number },
  showShapes: boolean,
  projects: any,
  mapId: string
) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isPinMap, setIsPinMap] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [centerOrBounds, setCenterOrBounds] = useState<
    [number, number] | mapboxgl.LngLatBounds
  >(() => [0, 0]);
  const customerConfigurations = getConfig();
  const { onProjectSelect } = useProjectDetail();
  const mapboxglAccessToken = getMapBoxglAccessToken();

  const { addMapOutlineLayer } = useMapShapeOutlineLayer();
  const { addProjectShapesLayer, addProjectLinesLayer } =
    useMapProjectsShapeLayer();
  const {
    addProjectClusterLayer,
    addProjectPinsLayer,
    addProjectMultiPinsLayer,
  } = useMapPinsLayer();

  const { data: shapesData } = useReactQueryFetch({
    queryKey: "districtShapes",
    fetchFn: getDistrictShapes
  });

  useEffect(() => {
    if (mapContainer.current && !_.isEmpty(center)) {
      mapboxgl.accessToken = mapboxglAccessToken;
      const mapInstance = new mapboxgl.Map({
        style: getBaseMapTheme(),
        container: mapContainer.current,
        zoom: zoom,
        maxZoom: 18,
        minZoom: 0,
        center: new mapboxgl.LngLat(center.lng, center.lat),
      });

      mapInstance.addControl(new mapboxgl.NavigationControl(), "top-left");

      mapInstance.on("style.load", () => {
        setIsMapLoaded(true);
        // Adding Layers
        addMapOutlineLayer(mapInstance);
        addProjectShapesLayer(mapInstance);
        addProjectLinesLayer(mapInstance);
        addProjectClusterLayer(mapInstance);
        addProjectPinsLayer(mapInstance);
        addProjectMultiPinsLayer(mapInstance);

        if (isSingleProject) {
          addProjectsToMap(mapInstance, projects);
        }
        resizeMap();
        setInitialLoad(true);
      });

      setMap(mapInstance);

      return () => {
        mapInstance.remove();
        GlobalEvents.off(`ResetMapBounds::${mapId}`, resizeMap);
        GlobalEvents.off(`Map::InvalidateSize::${mapId}`, resizeMap);
      };
    }
  }, [mapContainer]);

  useEffect(() => {
    if (map) {
      addMapEventListeners(map);
      if (!isSingleProject) {
        initializeHandlers(map);
      }
    }
  }, [map, isSingleProject]);

  const initializeHandlers = (mapInstance: mapboxgl.Map) => {
    const popupHandler = new PopupHandler(mapInstance);
    new MouseInteractionHandler(mapInstance, popupHandler);
  };

  useEffect(() => {
    if (map && shapesData && initialLoad) {
      addShapeBoundarytoMap(map);
    }
  }, [map, shapesData, initialLoad]);

  useEffect(() => {
    if (map && isMapLoaded) {
      addProjectsToMap(map, projects);
    }
  }, [projects, isMapLoaded]);

  const addMapEventListeners = (mapInstance: mapboxgl.Map) => {
    mapInstance.on("click", onProjectClickOrTouch);
    mapInstance.on("dragend", onDragEnd);
    mapInstance.on("zoomend", onZoomEnd);
    mapInstance.on("touch", onProjectClickOrTouch);
  };

  const onProjectClickOrTouch = (
    e: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent
  ) => {
    if (map && !isSingleProject) {
      const queryOptions = {
        layers: [
          "project-shape-outlines",
          "pins",
          "project-multi-pins",
          "project-shape-fill",
          "project-shape-line",
        ],
      };
      const features = map.queryRenderedFeatures(e.point, queryOptions);
      if (features.length) {
        const { properties } = features[0];
        const projectId = _.get(properties, "project_id", "");
        onProjectSelect(projectId);
      }
    }
  };

  const onDragEnd = () => {
    if (map) {
      const currentCenter = map.getCenter();
      const mapCenter = isSingleProject
        ? centerOrBounds
        : [center.lng, center.lat];
      if (
        Array.isArray(mapCenter) &&
        (currentCenter.lng !== mapCenter[0] ||
          currentCenter.lat !== mapCenter[1])
      ) {
        GlobalEvents.emit(`Map::CenterOrZoomChanged::${mapId}`, true);
      } else {
        GlobalEvents.emit(`Map::CenterOrZoomChanged::${mapId}`, false);
      }
      setInitialLoad(false);
    }
  };

  const onZoomEnd = () => {
    if (map) {
      const currentZoom = map.getZoom();
      if (!initialLoad || !isSingleProject) {
        if (currentZoom != zoom) {
          GlobalEvents.emit(`Map::CenterOrZoomChanged::${mapId}`, true);
        } else {
          GlobalEvents.emit(`Map::CenterOrZoomChanged::${mapId}`, false);
        }
      }
      setInitialLoad(false);
    }
  };

  const resizeMap = () => {
    setTimeout(() => {
      if (map) {
        map.resize();
      }
    }, 500);
  };

  const addShapeBoundarytoMap = async (mapInstance: mapboxgl.Map) => {
    if (customerConfigurations.city_boundary_show_option !== "none") {
      const outlinesSource = mapInstance.getSource(
        "outlines"
      ) as mapboxgl.GeoJSONSource;
      if (outlinesSource) {
        outlinesSource.setData(formatShapes(shapesData, "district_name"));
      }
      resizeMap();
    }
  };

  const addProjectsToMap = (mapInstance: mapboxgl.Map, projects: any[]) => {
    if (!projects) return;

    if (showShapes) {
      let shapes;
      if (!isSingleProject) {
        shapes = _.compact(_.flatten(_.map(_.clone(projects), "shapes")));
      } else {
        shapes = _.get(projects, "shapes", []);
      }

      const projectPins: GeoJSON.FeatureCollection<GeoJSON.Geometry> =
        formatPins(shapes) as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
      const isPinMap = _.size(_.get(projectPins, "features", [])) > 0;
      setIsPinMap(isPinMap);
      const projectPinsSource = mapInstance.getSource(
        "project-pins"
      ) as mapboxgl.GeoJSONSource;
      if (projectPinsSource) {
        projectPinsSource.setData(projectPins);
      }
      const projectMultiPinsSource = mapInstance.getSource(
        "project-multi-pins"
      ) as mapboxgl.GeoJSONSource;
      if (projectMultiPinsSource) {
        projectMultiPinsSource.setData(
          formatMultiPointPins(
            shapes
          ) as GeoJSON.FeatureCollection<GeoJSON.Geometry>
        );
      }
      const projectClusterSource = mapInstance.getSource(
        "project-cluster"
      ) as mapboxgl.GeoJSONSource;
      if (projectClusterSource) {
        projectClusterSource.setData(
          formattedClusterData(
            shapes
          ) as GeoJSON.FeatureCollection<GeoJSON.Geometry>
        );
      }
      const projectShapesSource = mapInstance.getSource(
        "project-shapes"
      ) as mapboxgl.GeoJSONSource;
      if (projectShapesSource) {
        projectShapesSource.setData(
          formatProjectShapes(
            shapes
          ) as GeoJSON.FeatureCollection<GeoJSON.Geometry>
        );
      }
      const projectLinesSource = mapInstance.getSource(
        "project-lines"
      ) as mapboxgl.GeoJSONSource;
      if (projectLinesSource) {
        projectLinesSource.setData(
          formatProjectLineString(
            shapes
          ) as GeoJSON.FeatureCollection<GeoJSON.Geometry>
        );
      }

      if (isSingleProject && shapes.length) {
        if (isPinMap) {
          setCenterOrBounds(
            (projectPins.features[0].geometry as GeoJSON.Point).coordinates as [
              number,
              number
            ]
          );
          const coordinates = (
            projectPins.features[0].geometry as GeoJSON.Point
          ).coordinates;
          if (coordinates.length === 2) {
            panMapToPoint(mapInstance, coordinates as [number, number]);
          }
        } else {
          panMapToShape(mapInstance);
        }
      }
      resizeMap();
    }
  };

  const panMapToPoint = (
    mapInstance: mapboxgl.Map,
    coordinates: [number, number]
  ) => {
    mapInstance.flyTo({ center: coordinates, zoom });
  };

  const panMapToShape = (mapInstance: mapboxgl.Map) => {
    const singleProjectShapes = _.get(projects, "shapes", []);
    const shapeWithGeom = singleProjectShapes.find(
      (shape: Shape) => shape.the_geom
    ) as Shape | undefined;
    if (!shapeWithGeom) return;

    const bounds = getShapeGeomBounds(shapeWithGeom.the_geom);
    if (bounds && bounds._sw && bounds._ne) {
      setCenterOrBounds(bounds);
      mapInstance.fitBounds(bounds, { padding: 40, maxZoom: zoom });
    }
  };

  GlobalEvents.on(
    `ResetMapBounds::${mapId}`,
    function (isProjectionController) {
      if (map && !isSingleProject && !isProjectionController) {
        map.flyTo({ center: center, zoom: zoom });
      }

      if (map && isProjectionController && isSingleProject) {
        if (isPinMap && Array.isArray(centerOrBounds)) {
          map.flyTo({ center: centerOrBounds as [number, number], zoom: zoom });
        } else {
          if (!Array.isArray(centerOrBounds)) {
            map.fitBounds(centerOrBounds, { padding: 40, maxZoom: zoom });
          }
        }
      }
    }
  );

  GlobalEvents.on(`Map::InvalidateSize::${mapId}`, function () {
    resizeMap();
  });

  return {
    mapContainer,
  };
};

export default useMap;
