import { ForgeOption, ForgeSelect } from '@tylertech/forge-react';
import { getI18nConfig } from '../../helper/serverConfigHelper';

const ScheduleVarianceHeader = ({onAllProjectSelect, matrixTypes, selectedMatrix, onMatrixFilterChange, matrixTotal}: any) => {
  const {
    schedule_cost_variance_title_text: scheduleCostVarianceTitleText,
    display_label_text: displayLabelText
  } = getI18nConfig();
  
  return (
    <>
      <h3 className='matrix-header'>
          <strong>
            <div className='matrix-header-title'>
              <span>{scheduleCostVarianceTitleText} |</span>
              <a href="#" className='dynamic' onClick={() => onAllProjectSelect({ categoryId: selectedMatrix.categoryId })}>{matrixTotal}</a>
            </div>
            <div className='matrix-header-filter'>
              <label>{displayLabelText}:</label>
              <ForgeSelect aria-label="Label" value={selectedMatrix.name} onChange={onMatrixFilterChange}>
                {matrixTypes.map((matrixType: any) => (
                  <ForgeOption key={matrixType.id} value={matrixType.name}>
                    {matrixType.name}
                  </ForgeOption>
                ))}
              </ForgeSelect>
            </div>
          </strong>
        </h3>
    </>
  );
};

export default ScheduleVarianceHeader;
