import _ from "lodash";
import {
	UPDATE_CURRENT_TAB,
	UPDATE_SELECTED_FILTER_CATEGORIES,
	UPDATE_SELECTED_FILTER_STAGES,
	UPDATE_SELECTED_FILTER_FUNDS,
	UPDATE_SELECTED_COLUMN_FILTERS,
	RESET_LEFT_PANEL_FILTERS,
	UPDATE_ALL_PROJECT_FILTERS,
	UPDATE_MATIX_ID,
	UPDATE_LOCATION_ID
} from "../actions/projectFilterActions";
import { ALL_PROJECTS_CATEGORY_ID } from "../appConstants";
import { ProjectFilterReducer } from "types/stateTypes";

export const getProjectFilterReducerState = (): ProjectFilterReducer => {
	return {
		currentTab: 'home',
		selectedFilterCategories: {},
		selectedFilterStages: [],
		selectedFilterFunds: [],
		selectedColumnFilters: {},
		matrixId: undefined,
		locationId: [],
		allProjectFilters: {
			columnFilters: false,
			projectTypeFilter: false,
			mapFilter: false,
			stageFilter: false,
			fundFilter: false,
			searchText: false
		}
	};
};

interface Action extends ProjectFilterReducer {
	type: string;
}

const projectFilterReducer = (state = getProjectFilterReducerState(), action: Action): ProjectFilterReducer => {
	switch (action.type) {
		case UPDATE_CURRENT_TAB:
			return {
				...state,
				currentTab: _.get(action, 'currentTab', '')
			};
		case UPDATE_MATIX_ID:
			return {
				...state,
				matrixId: _.get(action, 'matrixId', undefined)
			};
		case UPDATE_SELECTED_FILTER_CATEGORIES:
			return {
				...state,
				selectedFilterCategories: _.get(action, 'selectedFilterCategories')
			};
		case UPDATE_SELECTED_FILTER_STAGES:
			return {
				...state,
				selectedFilterStages: _.get(action, 'selectedFilterStages')
			};
		case UPDATE_SELECTED_FILTER_FUNDS:
			return {
				...state,
				selectedFilterFunds: _.get(action, 'selectedFilterFunds')
			};
		case UPDATE_SELECTED_COLUMN_FILTERS:
			return {
				...state,
				selectedColumnFilters: _.get(action, 'selectedColumnFilters')
			};
		case UPDATE_LOCATION_ID:
			return {
				...state,
				locationId: _.get(action, 'locationId', [])
			};
		case RESET_LEFT_PANEL_FILTERS:
			return {
				...state,
				selectedFilterCategories: _.get(action, 'resetCategories', false) ? {} : ALL_PROJECTS_CATEGORY_ID,
				selectedFilterStages: [],
				selectedFilterFunds: [],
				selectedColumnFilters: {},
				locationId: [],
				matrixId: undefined,
				allProjectFilters: {
					columnFilters: false,
					projectTypeFilter: false,
					mapFilter: false,
					stageFilter: false,
					fundFilter: false,
					searchText: false
				}
			};
		case UPDATE_ALL_PROJECT_FILTERS:
			return {
				...state,
				allProjectFilters: { ...state.allProjectFilters, [String(_.get(action, 'filterType'))]: Boolean(_.get(action, 'value')) }
			};
		default:
			return state;
	}
};

export default projectFilterReducer;
