import ImageSlider from '../ImageSlider/ImageSlider';
import { useSelector } from 'react-redux';
import useProjectData from '../../hooks/data/useProjectData';
import { HOME_TAB } from '../../appConstants';
import useFilterProjectsWithParams from '../../hooks/useFilterProjectsWithParams';
import { getHomePageContent } from '../../helper/serverConfigHelper';
import { RootState } from 'reducers/rootReducer';
import SearchTextContent from '../ProjectList/SearchTextContent';
import ProjectList from '../ProjectList';
import VarianceMatrix from '../../components/Matrix/VarianceMatrix';
import HomePageContent from './HomePageContent';
import { getHomePageCarouselConfigs } from '../../helper/configHelper';
import { CarouselOptionsTypes } from 'types/configurationTypes';
import _ from 'lodash';

const Home = () => {
  const homePageContent = getHomePageContent();
  const carouselConfigs: CarouselOptionsTypes[] = getHomePageCarouselConfigs();
  const currentTab = useSelector<RootState>((store) => store.projectFilter.currentTab);
  const isLoading = useSelector<RootState>((store) => store.projects.isLoading);
  useProjectData();
  useFilterProjectsWithParams();

  const renderHomePageContent = () => {
    return (
      <div className="home-page-wrapper">
        {!_.isEmpty(carouselConfigs) && <ImageSlider />}
        <VarianceMatrix />
        <HomePageContent htmlString={homePageContent} />
      </div>
    );
  };

  const renderProjectListContent = () => {
    if (isLoading) return <div className="loading-projects">Loading Projects...</div>;
    return (
      <div>
        <SearchTextContent />
        <ProjectList />
      </div>
    );
  };

  return (
    <>
      {currentTab == HOME_TAB ? renderHomePageContent() : renderProjectListContent()}
    </>
  );
};

export default Home;
