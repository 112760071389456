import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { getSearchDropdownEntries } from '../Header/helpers/searchHelper';
import { getI18nConfig } from '../../helper/serverConfigHelper';
import {  updateSearchText, updateShowSearchContainer } from '../../actions/searchActions';
import { resetLeftPanelFilters } from '../../actions/projectFilterActions';
import useUrlParamAdapter from '../../util/urlParamAdapter';
import { updateCurrentProject } from '../../actions/projectListActions';

const SearchTextDisplay = () => {
  const searchText = useSelector((store: RootState) => store.search.searchText);
  const searchField = useSelector<RootState>((store) => store.search.searchField);
  const {
    search_result_label_text: searchLabelText,
    projects_label_text: projectLabelText
  } = getI18nConfig();
  const dispatch = useDispatch();
  const searchEntries = getSearchDropdownEntries();
  const searchEntry = _.find(searchEntries, (entry) => entry.mapped_field_name === searchField);
  const { gotoState } = useUrlParamAdapter();

  if (_.isEmpty(searchText)) return null;

  const handleReset = () => {
    dispatch(updateSearchText(''));
    gotoState('/projects', {}, false);
    dispatch(resetLeftPanelFilters(false));
		dispatch(updateCurrentProject({}));
    dispatch(updateShowSearchContainer(false));
  };

  return (
    <p className="search-text-content">
      {searchLabelText} "{searchText}" by "{searchEntry?.name || projectLabelText}".{' '}
      <a href="#" onClick={handleReset} className="reset-button">Reset</a>
    </p>
  );
};

export default SearchTextDisplay;
