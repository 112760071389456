import axios from 'axios';

// const { get } = useSmartHttp();
 
export const getProjectMetaData = async () => {
  const response = await axios.get('/api/project_meta.json');
  return response.data;
};

export const getProjects = async () => {
  const responses = await axios.get('/api/projects.json');
  return responses.data;
};

export const getShapes = async () => {
  const response = await axios.get('/api/shapes.json');
  return response.data;
};

export const getDistinctValuesByColumn = async (params : any) => {
  const response = await axios.get('/api/distinct_values.json', { params: params });
  return response.data.result;
};

export const getDistrictShapes = async () => {
  const response = await axios.get('/api/district_shapes.json');
  return response.data;
};

export const getUserDetails = async () => {
  const response = await axios.get('/user/details.json');
  return response;
};

export const getBellerophonConfig = async () => {
  const response = await axios.get('/api/administrator/app_config.json');
  return response.data;
};

export const updateBellerophonConfig = async (configParams: Record<string, any>) => { 
  const response = await axios.post('/api/administrator/config_update', {
    ...configParams
  });
  return response.data;
};

export const getUserAcessData = async (params : any) => {
  const response = await axios.get('/user/has_admin_access.json', { params: params });
  return response.data;
};
