import { useDispatch, useSelector } from "react-redux";
import useLocationUpdateWithParams from "./useLocationUpdateWithParams";
import _ from "lodash";
import { RootState } from "../reducers/rootReducer";
import { updateCurrentProject } from "../actions/projectListActions";
import { useEffect, useRef } from "react";
import { updateCurrentTab } from "../actions/projectFilterActions";

const useProjectDetail = () => {
  const { updateLocationWithParams } = useLocationUpdateWithParams();
  const projects: any = useSelector<RootState>((store) => store.projects.filteredProjects);
  const currentProject: any = useSelector<RootState>((store) => store.projects.currentProject);
  const currentTab = useSelector<RootState>((store) => store.projectFilter.currentTab);

  const dispatch = useDispatch();
  const projectRef = useRef<any[]>([]);

  useEffect(() => {
    if (!_.isEmpty(projects)) {
      projectRef.current = projects;
    }
  }, [projects]);

  const handleScroll = () => {
    const detailView = document.querySelector('#project-details-container');
    if (detailView) {
      const rect = detailView.getBoundingClientRect();
      window.scrollTo({
        top: rect.bottom,
        behavior: 'smooth',
      });
    } else {
      console.log('detailView not found');
      handleScroll();
    }
  };

  const updateRouteAndScroll = (selectedProject: any, projectId: string) => {
    if (_.isEmpty(selectedProject) || _.isEmpty(projectId)) return;
    const currentProjectId = _.get(currentProject, 'project_id', '');
    if (currentProjectId != projectId) {
      const updatedProjectId = projectId.replace(/\//g, "~");
      updateLocationWithParams(`/projects/${updatedProjectId}`, {});
      if(currentTab != "projects") {
        dispatch(updateCurrentTab('projects'));
      }
      dispatch(updateCurrentProject(selectedProject));
      setTimeout(() => {
        handleScroll();
      }, 3000);
    }else{
      const siteWrapper = document.querySelector('.site-wrapper');
      if (siteWrapper) siteWrapper.scrollTo({
        top: (document.querySelector('.project-scroll-top-icon') as HTMLElement)?.offsetTop || 0,
        behavior: 'smooth'
      });
    }
  };

  const onProjectSelect = (projectId: string) => {
    const selectedProject = projectRef.current?.find((project: any) => project.project_id === projectId);
    updateRouteAndScroll(selectedProject, projectId);
  };

  const onProjectSearchSelect = (searchField:string,  selectedText: string) => {
    const selectedProject: any = projectRef.current?.find((project: any) => project[searchField as string] === selectedText);
    const projectId = _.get(selectedProject, 'project_id', '');
    updateRouteAndScroll(selectedProject, projectId);
  };

  return {
    onProjectSelect, onProjectSearchSelect
  };
};

export default useProjectDetail;
