import _ from 'lodash';
import Map from '../Visualization/Map';
import { getConfig } from '../../helper/serverConfigHelper';
import useRecentreButton from '../../hooks/useRecentreButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { Project } from 'types/projectTypes';
import { getShapeDatasets } from '../../helper/geoJsonMapFormatter';

const ProjectMap = () => {
  const { project_detail_default_zoom_level, map: mapConfig } = getConfig();
  const defaultZoom = _.toNumber(project_detail_default_zoom_level) || 12;
  const center = _.mapValues(mapConfig.center, _.toNumber) || { lat: 0, lng: 0 };
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const { showRecenterButton, onReCenterBtnClick } = useRecentreButton(true, 'DetailMap');
  const hideMap = _.isEmpty(getShapeDatasets());

  return (
    <div className="project-details-map-view">
      {!hideMap && (
        <Map
          isSingleProject={true}
          zoom={defaultZoom}
          center={center}
          showShapes={true}
          projects={currentProject}
          mapId="DetailMap"
        />
      )}
      {showRecenterButton &&
        <button id="recentreBtn" className="recenter-button" onClick={() => onReCenterBtnClick()}>
          <i className="icons icon-location-arrow"></i>
          <span>Re-center</span>
        </button>
      }
    </div>
  );
};

export default ProjectMap;
