import { getHeaderLogoUrl } from '../../helper/configHelper';
import HeaderLinks from './HeaderLinks';
import SearchContainer from './SearchContainer';
import SocialMediaLinks from './SocialMediaLinks';
import classNames from 'classnames';
import SignInButton from './SignInButton';
import { getOpenDataMountPath } from '../../helper/serverConfigHelper';
import HeaderProfile from './HeaderProfile';
import CopyrightText from './CopyrightText';
import { createRef, RefObject } from 'react';
import { useHeaderOverflow } from '../../hooks/useHeaderOverflow';

interface HeaderListItemsProps {
  isNavBarOpen: boolean;
  customerName: string;
  appBarRef: RefObject<HTMLDivElement>;
}

const HeaderContent = (props: HeaderListItemsProps) => {
  const { isNavBarOpen, customerName, appBarRef } = props;
  const headerLogo = getHeaderLogoUrl();
  const openDataMountPath = getOpenDataMountPath();
  const listsRef = createRef<HTMLUListElement>();
  
  const { isOverflowing } = useHeaderOverflow({ appBarRef, listsRef, isNavBarOpen });

  const listClassNames = classNames('nav-bar-list app-bar-list', {
    'open-nav': isNavBarOpen
  });

  const navigateToHome = () => {
    const homePath = openDataMountPath.replace(/\/$/, '');
    document.location.href = homePath + '/';
  };

  return (
    <>
      <div className="logo-container app-bar-list" slot="start">
        <a href="#" onClick={ navigateToHome } >
          <img className="brand-icon" src={headerLogo} alt="Header Logo" />
        </a>
        <span className="app-title">{customerName}</span>
      </div>
      <div slot="end" className={listClassNames}>
        <ul ref={listsRef} className="navbar-right">
          <HeaderLinks />
          <SocialMediaLinks />
          <SignInButton />
          <HeaderProfile isOverflowing={isOverflowing}/>
          <SearchContainer />
          <CopyrightText />
        </ul>
      </div>
    </>
  );
};

export default HeaderContent;
