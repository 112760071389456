import { ForgeList, ForgeListItem } from '@tylertech/forge-react';
import useUrlParamAdapter from '../../util/urlParamAdapter';
import _ from 'lodash';
import { resetLeftPanelFilters, updateCurrentTab } from '../../actions/projectFilterActions';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchField, updateSearchText, updateShowSearchContainer } from '../../actions/searchActions';
import useRemoveForgeElements from '../../hooks/useRemoveFocusIndicator';
import { RootState } from 'reducers/rootReducer';
import classNames from 'classnames';
import { updateCurrentProject } from '../../actions/projectListActions';
import { getI18nConfig } from '../../helper/serverConfigHelper';
import { HOME_TAB, PROJECTS_TAB } from '../../appConstants';

const NavigationList = () => {
	useRemoveForgeElements('.left-nav-list', '.left-nav-list-item');

	const { home_link_label_text: homeLinkLabelText } = getI18nConfig();
	const { gotoState } = useUrlParamAdapter();
	const allProjectsFilter = useSelector<RootState>((store) => store.projectFilter.allProjectFilters);
	const currentTab = useSelector<RootState>((store) => store.projectFilter.currentTab);
	const hasActiveFilters = _.some(allProjectsFilter as object, Boolean);

	const dispatch = useDispatch();

	const onListItemClick = (tab : string) => {
		const resetCategories = tab === HOME_TAB;
		dispatch(updateCurrentTab(tab));
		dispatch(resetLeftPanelFilters(resetCategories));
		dispatch(updateSearchText(''));
		dispatch(updateSearchField('project_name'));
		dispatch(updateCurrentProject({}));
		gotoState('/projects', {}, false);
		if(tab === 'list') {
			dispatch(updateShowSearchContainer(false));
		}
	};

	const getClassNames = (tab: string) => {
		const listItemClassNames = classNames('left-nav-list-item', {
			'active': tab === currentTab || ( currentTab === PROJECTS_TAB && tab === 'list') ,
		});
		return listItemClassNames;
	};

	const filterClassname = classNames( 'left-nav-list-item',{
		'active': hasActiveFilters
		});

	const getIconClassNames = () => {
		const iconClassNames = classNames('icons pe-3', {
			'icon-filtered-all': hasActiveFilters,
			'icon-all': !hasActiveFilters
		});
		return iconClassNames;
	};

	return (
		<div className="left-nav-list pb-4 mt-2">
			<ForgeList>
				<ForgeListItem className={getClassNames('home')}>
					<a
						href="#home"
						onClick={() => onListItemClick('home')}
						role="button"
					>
						<span className="icons fa fa-home pe-3"></span>
						{homeLinkLabelText}
					</a>
				</ForgeListItem>
				<ForgeListItem className={`${getClassNames('list')} ${filterClassname}`}>
					<a
						href="#list"
						onClick={() => onListItemClick('list')}
						role="button"
					>
						<span className={getIconClassNames()}></span>
						{hasActiveFilters ? 'Reset Filtered Projects' : 'All Projects'}
					</a>
				</ForgeListItem>
			</ForgeList>
		</div>
	);
};

export default NavigationList;
