import React from 'react';
import { ForgePaginator } from '@tylertech/forge-react';
import { getItemPerPage } from '../../helper/configHelper';

interface PaginatorType {
  total: number;
  onPageLimitChange: (pageSize: number, pageIndex: number) => void;
  pageIndex: number;
  pageSize: number;
}

const Paginator = ( { total, onPageLimitChange, pageIndex } : PaginatorType) => {
  const pageSize = getItemPerPage();
  const pageSizeOptions = pageSize;

  const handlePaginatorChange = (e: CustomEvent<{ pageSize: number; pageIndex: number }>) => {
    onPageLimitChange(e.detail.pageSize, e.detail.pageIndex);
  };

  return (
    <div className="project-table-paginator">
      <ForgePaginator
        total={total}
        alternative={true}
        pageSizeOptions={pageSizeOptions}
        pageSize={pageSize}
        pageIndex={pageIndex}
        on-forge-paginator-change={handlePaginatorChange}
      />

    </div>
  );
};

export default React.memo(Paginator);
