import { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import LoadingSpinner from './components/LoadingSpinner';
import LeftPanel from './components/LeftPanel/LeftPanel';
import MapVisualization from './components/Base/MapVisualization';
import BodyContent from './BodyContent';
import GlobalEvents from './components/GlobalEvents';
import { RootState } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';
import { getShapeDatasets } from './helper/geoJsonMapFormatter';

const MainContent = () => {
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject);
  const [isMapView, setIsMapView] = useState(false);
  const hideMap = _.isEmpty(getShapeDatasets());


  const handleClick = () => {
    setIsMapView(!isMapView);
    setTimeout(() => {
      GlobalEvents.emit('Map::InvalidateSize::mainMap');
    }, 300);
  };

  const forgeSplitViewClassNames = classNames({
    'forge-split-view': true,
    'is-project': !_.isEmpty(currentProject),
  });

  return (
    <div className={forgeSplitViewClassNames} id="main_content_section" role="main">
      <div className="page-wrapper">
        <LeftPanel />
        <BodyContent isMapView={isMapView} hideMap={hideMap} handleClick={handleClick} />
        <MapVisualization hideMap={hideMap} isMapView={isMapView} />
      </div>
      <LoadingSpinner />
    </div>
  );
};

export default MainContent;
