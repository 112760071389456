import { getI18nConfig } from '../../helper/serverConfigHelper';
import { PROJECT_SORT_TYPE } from '../../appConstants';
import { useConfigHelpers } from '../../hooks/useConfigHelpers';

const AdvanceProjectListHeader = ({ toggleCostVariance, sortProjects, toggleVariance, sortByVariance, isCostSortAsc, isNameSortAsc, sortType, searchEntry }: any) => {
  const { facetOneLabel, facetTwoLabel } = useConfigHelpers();
  const { project_name_label_text: projectNameLabelText } = getI18nConfig();

  const activeCostClass = toggleCostVariance ? 'cost-variance' : '';
  const activeScheduleClass = !toggleCostVariance ? 'cost-variance' : '';
  const varianceIconClass = isCostSortAsc ? 'fa-arrow-down' : 'fa-arrow-up';
  const nameActive = sortType === PROJECT_SORT_TYPE.NAME ? 'cost-variance' : '';
  const varianceActive = sortType !== PROJECT_SORT_TYPE.NAME ? 'cost-variance' : '';

  const renderHeaderColumns = () => (
    <>
      <th colSpan={2} style={{ width: '40%' }}>
        {projectNameLabelText}
        <a href="#" className={nameActive} onClick={() => sortProjects('name')} aria-label="Sort">
          <span
            className={`ms-2 fa ${isNameSortAsc ? 'fa-arrow-down' : 'fa-arrow-up'
              }`}
          ></span>
        </a>
      </th>
      <th className='variance-header'>
        <div>
          <a
            aria-label="Sorts"
            role="region"
            className={"cursor-pointer table-variance " + activeCostClass}
            onClick={() => toggleVariance(PROJECT_SORT_TYPE.COST_VARIANCE)}
          >
            {facetOneLabel}
          </a>
          <span className="separator">|</span>
        </div>
        <div className='schedule-variance'>
          <a
            role='region'
            aria-label="Sorts"
            className={"cursor-pointer table-variance " + activeScheduleClass}
            onClick={() => toggleVariance(PROJECT_SORT_TYPE.SCHEDULE_VARIANCE)}
          >
            {facetTwoLabel}
          </a>
          <a className={varianceActive + ' sort-icon cursor-pointer'} onClick={() => sortByVariance()} aria-label="Sort" role='region'>
            <span className={`ms-4 fa ${varianceIconClass}`} aria-hidden="true"></span>
          </a>
        </div>
      </th>
    </>
  );

  return (
    <thead>
      <tr>
        {!searchEntry?.isProject && (
          <th>{searchEntry ? searchEntry?.name : ''}</th>
        )}
        {renderHeaderColumns()}
      </tr>
    </thead>
  );
};

export default AdvanceProjectListHeader;
