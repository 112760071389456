import PlotlyChart from './PlotlyChart';
import _ from 'lodash';
import { useLineChart } from '../../hooks/useLineChart';
import { getLineChartPrimaryColor, getLineChartSecondaryColor, getLineChartTeriaryColor } from '../../helper/configHelper';

const LineChart = () => {
  const {
    plotData,
    layout,
    actualYAxisValues,
    approveYAxisValues,
    spentYAxisValues,
    lineChartLineOneLabel,
    lineChartLineTwoLabel,
    lineChartLineThreeLabel,
  } = useLineChart();

  const lineChartPrimaryColor = getLineChartPrimaryColor();
  const lineChartSecondaryColor = getLineChartSecondaryColor();
  const lineChartTeriaryColor = getLineChartTeriaryColor();

  if (_.isEmpty(actualYAxisValues) && _.isEmpty(approveYAxisValues) && _.isEmpty(spentYAxisValues)) {
    return;
  }

  const renderLegend = () => {
    return (
      <div className="d-flex gap-3 mx-5 mt-3 legend-text">
        {!_.isEmpty(actualYAxisValues) && (
          <div>
            <span className="legend-dot" style={{ backgroundColor: lineChartPrimaryColor }}></span>{' '}
            {lineChartLineOneLabel}
          </div>
        )}
        {!_.isEmpty(approveYAxisValues) && (
          <div>
            <span className="legend-dot" style={{ backgroundColor: lineChartSecondaryColor }}></span>{' '}
            {lineChartLineTwoLabel}
          </div>
        )}
        {!_.isEmpty(spentYAxisValues) && (
          <div>
            <span className="legend-dot" style={{ backgroundColor: lineChartTeriaryColor }}></span>{' '}
            {lineChartLineThreeLabel}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="line-chart-container">
      <PlotlyChart data={plotData} layout={layout} />
      {renderLegend()}
    </div>
  );
};

export default LineChart;
