import React, { useEffect, useRef, useState } from "react";
import { ForgeListItem } from "@tylertech/forge-react";
import classNames from "classnames";
import ProjectSubCategoryFilter from "./ProjectSubCategoryFilter";
import _ from "lodash";
import { ProjectTypeFilterProps } from "./ProjectTypeFilter";

 interface NestedFilterOptionProps {
  config: ProjectTypeFilterProps;
  index: number;
  selectedFilters: Record<string, boolean>;
  handleFilterSelection: (
    config: ProjectTypeFilterProps,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleSubFilterSelection?: (childConfig: ProjectTypeFilterProps, config: ProjectTypeFilterProps) => void;
  checkIfSubFilterSelected?: (childConfig: ProjectTypeFilterProps, config: ProjectTypeFilterProps) => boolean;
  hasChildConfigs?: boolean;
  ifAnySubCategoriesSelected?: boolean;
}

const NestedFilterOption: React.FC<NestedFilterOptionProps> = (props) => {
  const {
    config,
    index,
    selectedFilters,
    handleFilterSelection,
    handleSubFilterSelection,
    checkIfSubFilterSelected,
    hasChildConfigs,
    ifAnySubCategoriesSelected,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const isFilterSelected = selectedFilters.hasOwnProperty(config.type);
  const filterClass = classNames("filter-selector-wrapper", {
    "filter-selector-wrapper-active": isFilterSelected,
	"focused": isFocused,
  });
  const listItem = `${_.toLower(_.replace(config.name, /\s+/g, "_"))}_${index}`;
  const listItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (e: MouseEvent) => {
    if (listItemRef.current) {
      if (listItemRef.current.contains(e.target as Node)) {
		  setIsFocused(true);
      } else {
		  setIsFocused(false);
      }
    }
  };

  const renderIcon = (config: ProjectTypeFilterProps) => {
    const { icon, project_icon } = config;
    if (!icon) {
      return <span className="icon fa fa-question"></span>;
    }
    return (
      <div className="filter-selector-icon">
        {icon && (
          <img
            className="d-none d-md-block"
            src={icon}
            alt="icons"
            onError={(e) => e.currentTarget.classList.add("hidden")}
          />
        )}
        {project_icon && (
          <img
            className="d-md-none"
            src={project_icon}
            alt="icons"
            onError={(e) => e.currentTarget.classList.add("hidden")}
          />
        )}
      </div>
    );
  };

  const icon = ifAnySubCategoriesSelected
    ? "fa fa-minus"
    : "fa fa-check fa-icon-checked";

  const handleKeyDown = (event: React.KeyboardEvent, config: ProjectTypeFilterProps) => {
    if (event.key === "Enter" || event.key === " ") {
      handleFilterSelection(config, event as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>);
    }
  };

  return (
    <div className="filter-selector-container">
      <ForgeListItem
        className={`d-flex ${filterClass}`}
        key={index}
		ref={listItemRef}
      >
        <div className="filter-selector-info" title={config.name}>
          <div
            className="filter-selector-label filter-selector-list"
            onClick={(event) => handleFilterSelection(config, event)}
          >
            <label className="all-projects-selector" htmlFor={listItem}>
              <span
                className={icon}
                onKeyDown={(event) => handleKeyDown(event, config)}
                tabIndex={0}
                role="checkbox"
                aria-label={listItem}
                aria-checked={isFilterSelected}
              ></span>
            </label>
            {renderIcon(config)}
            <span className="overflow-hidden" id={listItem}>
              {config.name}
            </span>
          </div>
          {hasChildConfigs && (
            <ProjectSubCategoryFilter
              config={config}
              handleSubFilterSelection={handleSubFilterSelection}
              checkIfSubFilterSelected={checkIfSubFilterSelected}
            />
          )}
        </div>
      </ForgeListItem>
    </div>
  );
};

export default NestedFilterOption;
