import { format, isValid, parseISO } from 'date-fns';
import { getConfig } from './serverConfigHelper';
import _ from 'lodash';
import { formatNumber } from '../util/numberFormatter';

const { rounding_options, currency_symbol, fixed_precision } = getConfig();
const doNotRoundNumbers = rounding_options === 'no_rounding';
const roundNumbersToDefaultRounding = rounding_options === 'default_rounding';
const currency = currency_symbol || '$';
const fixedPrecision = _.toNumber(fixed_precision) || 1;

export const formatValueBasedOnType = (value: any, type: string) => {
  let formattedValue;
  if (type == 'date') {
    formattedValue = formattedDate(value);
  } else if (type == 'amount') {
    formattedValue = getFormattedCurrencyValue(value);
  } else {
    formattedValue = value;
  }
  return formattedValue;
};

export const formattedDate = (value: Date): string => {
  const date = typeof value === 'string' ? parseISO(value) : new Date(value);
  return isValid(date) ? format(date, 'MMMM, yyyy') : String(value);
};

const convertNumberToCurrency = (amount: number): string => {
  if(_.isUndefined(amount)) {
    return '0';
  }
   let value = Number(amount);
  return formatNumber(Number(value), {
    currency: currency
  });
};

export const roundToNearestThousand = (value: number): string => {
  if(_.isUndefined(value)) {
    return '0';
  }

  let amount = Number(value);
  if ((value >= 1000 && value <= 1000000) || (value <= -1000 && value >= -1000000)) {
    amount = Math.round(value / 1000) * 1000;
  }

  return formatNumber(Number(amount), {
    currency,
    fullTextMultiplier: true,
    fixedPrecision: (amount > 1000000 || amount < -1000000) ? fixedPrecision : 0,
    addAbbr: (amount >= 1000000 || amount <= -1000000)
  });
};

export const roundToNearestDollar = (amount: number): string => {
  if(_.isUndefined(amount)) {
    return '0';
  }

  let value = Number(amount);
  if ( amount >= 1000 && amount <= 1000000 || (amount <= -1000 && amount >= -1000000)) {
    value = Math.round(amount);
  }

  return formatNumber(Number(value), {
    currency: currency,
    fullTextMultiplier: true,
    fixedPrecision: (amount > 1000000 || amount < -1000000) ? fixedPrecision : 0,
    addAbbr: (amount >= 1000000 || amount <= -1000000)
  });
};

export const getFormattedCurrencyValue = (value: number | null | undefined): string => {
  if (doNotRoundNumbers) {
    return convertNumberToCurrency(value ?? 0);
  }

  return roundNumbersToDefaultRounding ? 
    roundToNearestThousand(value ?? 0) : roundToNearestDollar(value ?? 0);
};

export const isValidEmail = (email: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
