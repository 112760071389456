import useMiniMap from '../../hooks/useMiniMap';
import { getConfig } from '../../helper/serverConfigHelper';
import _ from 'lodash';

const MiniMapFilter = () => {
  const showMiniMapFilter = getConfig().city_boundary_show_option == 'in_filter_overlay';
  const { 
    district_map_filter_header_text: miniMapFilterHeading, 
    map_reset_text: resetFilterText 
  } = getConfig();
  
  const {
    miniMapContainer,
    onResetFilter,
    canShowResetFilter,
    getFilterCountText,
    districtShapesData,
    onFocusDistrictSelect,
    onFocusOutDistrictUnSelect,
    onDistrictSelect
  } = useMiniMap();

  const renderResetButton = () => {
    return canShowResetFilter && (
      <div onClick={() => onResetFilter()} className="reset-filter">
        <span className="fa fa-times me-1"></span>
        <span>{resetFilterText}</span>
      </div>
    );
  };

  const renderScreenReaderText = () => {
    const sortedAndUniqueDistrictShapesData = _.sortBy(
      _.uniqBy(districtShapesData, 'district_name'),
      'district_name'
    );

    return (
        <div className="visually-hidden">
          {_.map(sortedAndUniqueDistrictShapesData, (shapeDatum: { district_id: string; district_name: string }) => (
            <div key={shapeDatum.district_id}>
              <a
                href="#"
                onFocus={() => onFocusDistrictSelect(shapeDatum)}
                onBlur={onFocusOutDistrictUnSelect}
                onClick={() => onDistrictSelect(shapeDatum.district_id)}
              >
                {shapeDatum.district_name}
              </a>
            </div>
          ))}
        </div>
    );
  };

  return showMiniMapFilter && (
    <div className="project-type-wrapper">
      <h3 className="section-heading">{`${miniMapFilterHeading} ${getFilterCountText()}`}</h3>
      {renderResetButton()}
      <div ref={miniMapContainer} style={{ minHeight: '200px' }}></div>
      {renderScreenReaderText()}
    </div>
  );
};

export default MiniMapFilter;
