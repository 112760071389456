import {
  checkIfLoginPage
} from '../../helper/serverConfigHelper';
import useHeaderLinks from '../../hooks/useHeaderLink';

interface Link {
  url: string;
  name: string;
}

const HeaderLinks = () => {
  const isLoginPage = checkIfLoginPage();
  const { links } = useHeaderLinks();

  if (isLoginPage) {
    return null;
  }

  return (
    <>
      {links.map((link: Link, index: number) => (
        <li key={index}>
          <a href={link.url} target="_blank" tabIndex={0} className="nav-links">
            {link.name}
          </a>
        </li>
      ))}
    </>
  );
};

export default HeaderLinks;
