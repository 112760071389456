import React, { useEffect } from 'react';
import {
  ForgeDivider,
  ForgeIcon,
  ForgeIconButton,
  ForgeScaffold,
} from '@tylertech/forge-react';
import { ADMIN_MENU_ITEM, ADMIN_MENUS } from '../../appConstants';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import useManageAccess from '../../hooks/useManageAccess';
import useLocationUpdateWithParams from '../../hooks/useLocationUpdateWithParams';
import AdminListPage from './AdminListPage';
import AdminListItem from './AdminListItem';
import { CurrentUser } from 'types/stateTypes';

function AdminPage() {
  const { menu_type } = useParams();
  const [selectedMenuItem, setSelectedMenuItem] = React.useState<string>(menu_type || ADMIN_MENU_ITEM.USER_PROFILE);
  const currentUser = useSelector<RootState>((store) => store.currentUser) as CurrentUser;
  const { loggedIn, isLaunchpadAdmin } = currentUser;
  const { isManageBranding, isManageUser, isManageUserProfile } = useManageAccess();
  const navigate = useNavigate();
  const { updateLocationWithParams } = useLocationUpdateWithParams();

  const menuItemAccessMap = {
    [ADMIN_MENU_ITEM.USER_PROFILE]: isManageUserProfile || isLaunchpadAdmin,
    [ADMIN_MENU_ITEM.BRANDING]: isManageBranding || isLaunchpadAdmin,
    [ADMIN_MENU_ITEM.USER_ROLES]: isManageUser || isLaunchpadAdmin,
  };

  if(!loggedIn) {
    handleBackClick();
  };

  useEffect(() => {
    setSelectedMenuItem(menu_type || ADMIN_MENU_ITEM.USER_PROFILE);
    if(menu_type && !menuItemAccessMap[menu_type]) {
      handleBackClick();
    }
   }, [menu_type]);

  const handleMenuItemClick = (menuItem: Record<string, string>) => {
    setSelectedMenuItem(menuItem.value);
    updateLocationWithParams(`/admin/${menuItem.value}`, {});    
  };

  function handleBackClick() {
    navigate('/projects');
  }


  const adminHeader = () => {
    return (
      <div className="admin-head">
        <ForgeIconButton onclick={() => handleBackClick()} ariaLabel={'Back'} >
          <ForgeIcon name="arrow_back" />
        </ForgeIconButton>
        <h6>Administration</h6>
      </div>
    );
  };

  return (
    <div className="admin-page" role="main">
      <div className="container-fluid">
        {adminHeader()}
        <ForgeDivider />
        <ForgeScaffold className="admin-scaffold">
          <AdminListItem
            selectedMenuItem={selectedMenuItem}
            ADMIN_MENUS= {ADMIN_MENUS}
            handleMenuItemClick={handleMenuItemClick}
            menuItemAccessMap={menuItemAccessMap}
          />
          <AdminListPage
            selectedMenuItem={selectedMenuItem}
            ADMIN_MENU_ITEM={ADMIN_MENU_ITEM}
          />
        </ForgeScaffold>
      </div>
    </div>
  );
}

export default AdminPage;
