import { ProjectFilterConfig } from "types/configurationTypes";
import { getProjectColumnFilterPosition } from "./configHelper";
import _ from "lodash";

type CategoryMap = {
  [key: string]: string[];
};
export const transformDataToCategoryMap = (filterConfigEntries: ProjectFilterConfig[]): any => {
	const categoryMap: CategoryMap = {};

	filterConfigEntries.forEach((entry: ProjectFilterConfig) => {
		if (entry.childConfigs) {
			categoryMap[entry.type] = _.compact(entry.childConfigs
				.map((child: { type?: string }) => child.type));
		} else {
			categoryMap[entry.type] = [];
		}
	});

	return categoryMap;
};


export const isColumnFilterPositionedAtTop = () => {
	return _.isEqual(getProjectColumnFilterPosition(), 'top');
};
