const HeaderNavigation = () => {
  return (
    <div className="app-bar-responsive app-bar-list">
      <nav
        className="app-header"
        aria-label="Header navigation"
      >
        <div className="skiplink-section">
          <a href="#main_content_section" className="skiplink">
            Skip to main content
          </a>
          <a href="#search_input" className="skiplink" rel="nofollow">
            Skip to search
          </a>
          <a href="#filters-section" className="skiplink" rel="nofollow">
            Skip to filters
          </a>
          <a href="#footer" className="skiplink">
            Skip to footer links
          </a>
        </div>
      </nav>
    </div>
  );
};

export default HeaderNavigation;
