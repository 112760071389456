import _ from 'lodash';
import React, { useRef } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
const Plot = createPlotlyComponent(window.Plotly);
interface PlotlyChartProps {
  data: Plotly.Data[];
  layout?: Partial<Plotly.Layout>;
  config?: Partial<Plotly.Config>;
}

const PlotlyChart: React.FC<PlotlyChartProps> = ({
  data,
  layout = {},
  config = {},
}) => {
  const refChartContainer = useRef<HTMLDivElement>(null);

  const defaultLayout: Partial<Plotly.Layout> = {
    margin: { l: 0, r: 0, t: 0, b: 80 },
  };

  const defaultConfig: Partial<Plotly.Config> = {
    responsive: true,
    displayModeBar: false,
    editable: false,
    staticPlot: false,
  };

  const onPlotlyHover = () => {
    setMouseCursor('pointer');
  };

  const onPlotlyUnhover = () => {
    setMouseCursor('inherit');
  };

  const setMouseCursor = (cursor: string) => {
    const dragLayer = refChartContainer.current?.querySelector('g.draglayer');
    if (!_.isUndefined(dragLayer) && !_.isNull(dragLayer)) {
      (dragLayer as HTMLElement).style.cursor = cursor;
    }
  };

  return (
    <div ref={refChartContainer}>
      <Plot divId = "plotly-chart-container"
        onHover={onPlotlyHover}
        onUnhover={onPlotlyUnhover}
        data={data}
        layout={{ ...defaultLayout, ...layout }}
        config={{ ...defaultConfig, ...config }}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default PlotlyChart;
