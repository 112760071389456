import { createRef, useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ALL_PROJECTS_CATEGORY_ID, DEFAULT_SEARCH_FIELD } from "../appConstants";
import { updateAllProjectFilters, updateCurrentTab, updateFilterSelectedCategories } from "../actions/projectFilterActions";
import { updateSearchText, updateShowSearchContainer } from "../actions/searchActions";
import useRemoveFocusIndicator from "../hooks/useRemoveFocusIndicator";
import { RootState } from "../reducers/rootReducer";
import useProjectDetail from "../hooks/useProjectDetail";
import { updateCurrentProject } from "../actions/projectListActions";

const useHeaderSearch = () => {

  useRemoveFocusIndicator('#solution-name-search', '.solution-library-search');
  const projects: any = useSelector<RootState>((store) => store.projects.allProjects);
  const searchText: any = useSelector<RootState>((store) => store.search.searchText);

  const [searchInputText, setSearchInputText] = useState<string>("");
  const [formattedProjects, setFormattedProjects] = useState<any[]>([]);
  const dispatch = useDispatch();
  const searchField = useSelector<RootState>((store) => store.search.searchField);
  const autoCompleteRef = createRef<HTMLInputElement>();
  const inputRef = createRef<HTMLInputElement>();

  const { onProjectSearchSelect } = useProjectDetail();

  const onSearchInputKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(updateCurrentTab('projects'));
      dispatch(updateFilterSelectedCategories(ALL_PROJECTS_CATEGORY_ID));
    }
  };

  useEffect(() => {
    setSearchInputText("");
    dispatch(updateSearchText(""));
    dispatch(updateAllProjectFilters('searchText', false));
  }, [searchField]);

  useEffect(() => {
    if (_.isEmpty(searchText)) {
      setSearchInputText("");
      dispatch(updateSearchText(""));
      dispatch(updateAllProjectFilters('searchText', false));
    }
  }, [searchText]);


  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('keydown', onSearchInputKeyDown);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', onSearchInputKeyDown);
      }
    };
  }, [onSearchInputKeyDown]);

  const onSearchInputChange = (e: any) => {
    const selectedText = e.target.value;
    setSearchInputText(selectedText);
    dispatch(updateSearchText(selectedText));
    dispatch(updateAllProjectFilters('searchText', _.size(selectedText) > 0));
  };

  useEffect(() => {
    const formattedProjects = projects.map((project: any) => {
      return {
        value: project[searchField as string],
        label: project[searchField as string],
      };
    });
    setFormattedProjects(_.uniqBy(formattedProjects, 'value'));
  }, [searchField]);

  const onSearchValueUpdate = (selectedText: string) => {
    dispatch(updateCurrentTab('projects'));
    dispatch(updateSearchText(selectedText));
    dispatch(updateAllProjectFilters('searchText', _.size(selectedText) > 0));
    dispatch(updateFilterSelectedCategories(ALL_PROJECTS_CATEGORY_ID));
  };

  const onSearchValueSelect = (event: any) => {
    const selectedText = _.get(event, 'detail.value', []);
    setSearchInputText(selectedText);
    if(searchField == DEFAULT_SEARCH_FIELD) {
      dispatch(updateCurrentTab('projects'));
      dispatch(updateSearchText(selectedText));
      dispatch(updateAllProjectFilters('searchText', _.size(selectedText) > 0));
      dispatch(updateFilterSelectedCategories(ALL_PROJECTS_CATEGORY_ID));
      onProjectSearchSelect(searchField as string, selectedText);
    }else{
      onSearchValueUpdate(selectedText);
    }
  };

  const clearSearch = () => {
    dispatch(updateShowSearchContainer(false));
    dispatch(updateSearchText(''));
    dispatch(updateAllProjectFilters('searchText', false));
    dispatch(updateCurrentProject({}));
  };

  return { clearSearch, onSearchInputChange, onSearchValueSelect,
    searchInputText, formattedProjects, inputRef, autoCompleteRef };
};

export default useHeaderSearch;
