import _ from "lodash";
import {
  getEnableMultiCategorySupportConfig,
  getProjectFiltersCondition,
} from "./configHelper";
import { Project } from "types/projectTypes";
export interface FilterParam {
  selectedFilterCategories?: Record<string, string>;
  currentTab?: string;
  selectedFilterStages: any[];
  selectedFilterFunds: any[];
  selectedColumnFilters: Record<string, string[]>;
  searchText?: string;
  searchField: keyof Project;
  matrixId?: string;
  locationId: string[];
}
const enableMultiCategorySupport = getEnableMultiCategorySupportConfig();

const isMultiCategoryNotSupported = () => {
  return enableMultiCategorySupport === "false";
};

export const isAllProjectsSelected = (selectedFilters: FilterParam) => {
  const categoryFilter = _.get(selectedFilters, "selectedFilterCategories", {});
  return _.isEqual(categoryFilter, { all: [] });
};

export const getFilterProjects = (
  projects: Project[],
  filters: FilterParam
) => {
  let selectedFilters = _.omitBy(filters, _.isUndefined) as FilterParam;
  let filteredProjects = projects;
  let anyFilterSelected = false;
  let currentTab = _.get(selectedFilters, "currentTab", "");

  delete selectedFilters.currentTab;
  if (isAllProjectsSelected(selectedFilters)) {
    delete selectedFilters.selectedFilterCategories;
  }

  _.each(selectedFilters, (values) => {
    if (!_.isEmpty(values)) {
      anyFilterSelected = true;
      return false;
    }
  });

  // return empty when no filters are selected
  if (!anyFilterSelected) {
    return isAllProjectsSelected(selectedFilters) ? projects : [];
  }

  if (!_.isEmpty(selectedFilters.locationId)) {
    filteredProjects = getProjectByDistrictId(filteredProjects, selectedFilters.locationId);
  }

  if (!_.isEmpty(selectedFilters.selectedFilterStages)) {
    filteredProjects = filterProjectsByStages(
      filteredProjects,
      selectedFilters.selectedFilterStages
    );
  }

  if (!_.isEmpty(selectedFilters.selectedFilterCategories)) {
    filteredProjects = filterProjectsByType(
      filteredProjects,
      selectedFilters.selectedFilterCategories
    );
  }

  if (!_.isEmpty(selectedFilters.selectedFilterFunds)) {
    filteredProjects = filterProjectsByFunds(
      filteredProjects,
      selectedFilters.selectedFilterFunds
    );
  }

  if (!_.isEmpty(selectedFilters.searchText) && _.includes(["projects", "list"], currentTab)) {
    filteredProjects = filterProjectsBySearchText(
      filteredProjects,
      selectedFilters.searchText,
      selectedFilters.searchField
    );
  }

  if (!_.isEmpty(selectedFilters.matrixId)) {
    filteredProjects = filterByMatrixId(filteredProjects, selectedFilters.matrixId);
  }

  let allProjectFilters: { columnFilters?: boolean } = _.get(filters, "allProjectFilters", {});
  if (!_.isEmpty(selectedFilters.selectedColumnFilters) && allProjectFilters.columnFilters) {
    filteredProjects = filterCustomColumnFilterProjects(
      filteredProjects,
      selectedFilters.selectedColumnFilters
    );
  }

  return filteredProjects;
};

function filterByMatrixId(projects : any, matrixId : any) {
  return _.filter(projects, function (project) {
    return project['matrix_id'] === matrixId;
  });
}

const filterProjectsBySearchText = (
  projects: Project[],
  searchText: string | undefined,
  searchField: keyof Project
) => {
  return _.filter(projects, function (project) {
    return _.includes(
      _.lowerCase(project[searchField] as string),
      _.lowerCase(searchText)
    );
  });
};

const filterProjectsByStages = (projects: Project[], stageIds: number[]) => {
  return _.filter(projects, function (project) {
    return _.includes(stageIds, project["project_stage_id"]);
  });
};

function getProjectCategories(project: Project) {
  const currentProjectTypes = isMultiCategoryNotSupported()
    ? [project["category_id"]]
    : (project["category_id"] || "").split(",").map((item: any) => item.trim());
  return currentProjectTypes.map((type: string) => type.trim());
}

export function filterProjectsByType(
  projects: Project[],
  categoryFilters: Record<string, string>
) {
  return _.filter(projects, (project) => {
    const currentProjectTypes = getProjectCategories(project);
    const subCategoryTypes = isMultiCategoryNotSupported()
      ? [project["sub_category_id"]]
      : (project["sub_category_id"] || "")
          .split(",")
          .map((item: any) => item.trim());

    const selectedCategoriesWithoutSubCategory = _.keys(
      _.pickBy(categoryFilters, _.isEmpty)
    );
    const isSubCategorySelected = currentProjectTypes.some(
      (projectType) =>
        !_.isEmpty(
          _.intersection(subCategoryTypes, categoryFilters[projectType])
        )
    );

    const selectedProjectCategory = _.compact(_.keys(categoryFilters));
    if (selectedProjectCategory.length === 0) {
      return false;
    } else if (isSubCategorySelected) {
      return true;
    } else {
      return !_.isEmpty(
        _.intersection(
          currentProjectTypes,
          selectedCategoriesWithoutSubCategory
        )
      );
    }
  });
}

function filterProjectsByFunds(projects: Project[], funds: string[]) {
  if (_.isEmpty(funds)) {
    return projects;
  }
  return _.filter(projects, function (project) {
    const fund = project["fund"] || "";
    let projectFunds = fund.split(",").map(function (item: string) {
      return item.trim();
    });
    if (enableMultiCategorySupport == "false") {
      projectFunds = [fund];
    }
    return !_.isEmpty(_.intersection(projectFunds, funds));
  });
}

export const filterCustomColumnFilterProjects = (
  projects: Project[],
  customColumnFilters: Record<string, string[]>
) => {
  let filteredProjects: Project[] = [],
    intersectProjects = projects;

  if (_.isEmpty(customColumnFilters)) {
    return projects;
  }

  _.each(customColumnFilters, function (values, key) {
    if (!_.isEmpty(values)) {
      if (getProjectFiltersCondition() === "or") {
        let projectsFiltersByColumn = _.filter(projects, function (project) {
          return _.includes(values, _.get(project, key));
        });
        filteredProjects = filteredProjects.concat(projectsFiltersByColumn);
      } else {
        intersectProjects = _.filter(intersectProjects, function (project) {
          return _.includes(values, _.get(project, key));
        });
        filteredProjects = intersectProjects;
      }
    }
  });
  return _.uniq(_.flatten(filteredProjects));
};

const getProjectByDistrictId = (projects :any, districtIds: string[]) => {
  if (_.isEmpty(districtIds)) {
    return projects;
  }
  return _.filter(projects, function (project) {
    let projectDistrictIds = (project['district_id'] || '').split(',').map(function(item: string) {
        return item.trim();
    });
    if (enableMultiCategorySupport == 'false') {
      projectDistrictIds = [project['district_id']];
    }
    let convertedDistrictIds = _.map(districtIds,(districtId) => {
      return _.isNaN(Number(districtId)) ? districtId : _.toString(Number(districtId));
    });
    return !_.isEmpty(_.intersection(projectDistrictIds, convertedDistrictIds));
  });
};
