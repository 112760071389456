import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import _ from "lodash";
import { transformDataToCategoryMap } from "../helper/filterHelper";
import { updateAllProjectFilters, updateCurrentTab, updateFilterSelectedCategories } from "../actions/projectFilterActions";
import useLocationUpdateWithParams from "./useLocationUpdateWithParams";
import { updateCurrentProject } from "../actions/projectListActions";
import { RootState } from "reducers/rootReducer";
import { ProjectFilterConfig } from "../types/configurationTypes";
import { PROJECTS_LIST_TAB, PROJECTS_TAB } from "../appConstants";
import { getEnableMultiCategorySupportConfig, getProjectTypeFilterEntries } from "./../helper/configHelper";
import { Project } from "./../types/projectTypes";

const useProjectTypeFilter = () => {
  const selectedFilters: any = useSelector<RootState>((store) => store.projectFilter.selectedFilterCategories);
  const projectTypeFilter: any = useSelector<RootState>((store) => store.projectFilter.allProjectFilters.projectTypeFilter);
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;
  const currentTab = useSelector<RootState>((store) => store.projectFilter.currentTab) as string;
  const projects = useSelector<RootState>((store) => store.projects.allProjects) as Project;
  const filterConfigEntries: ProjectFilterConfig[] = getProjectTypeFilterEntries();

  const projectTypes = getProjectTypes(filterConfigEntries, projects);

  const [areAllFiltersSelected, setAllFiltersSelected] = useState(false);
  const dispatch = useDispatch();
  const { updateLocationWithParams } = useLocationUpdateWithParams();

  useEffect(() => {
    setAllFiltersSelected(checkIfAllFiltersSelected());
  }, [selectedFilters]);

  const handleFilterSelection = (config: any) => {
    const isFilterSelected = _.has(selectedFilters, config.type);
    delete selectedFilters['all'];
    let updatedFilters = {};
    if (!isFilterSelected) {
      const categoryMap = transformDataToCategoryMap(filterConfigEntries);
      updatedFilters = _.set(_.cloneDeep(selectedFilters), config.type, categoryMap[config.type]);
    } else {
      updatedFilters = _.omit(selectedFilters, config.type);
    }
    dispatchUpdatedFilters(updatedFilters);
  };

  const handleSelectAllFilters = () => {
    const categoryMap = !areAllFiltersSelected ? transformDataToCategoryMap(filterConfigEntries) : {};
    setAllFiltersSelected(!areAllFiltersSelected);
    dispatchUpdatedFilters(categoryMap);
  };

  const checkIfAllFiltersSelected = () => {
    return _.size(selectedFilters) === _.size(filterConfigEntries);
  };

  const filterSelectState = projectTypeFilter && !checkIfAllFiltersSelected() ?  'partial' : 'all';

  const checkIfSubFilterSelected = (childConfig: any, config: any) => {
    const selectedSubFilters = _.get(selectedFilters, config.type, []);
    return _.includes(selectedSubFilters, childConfig.type);
  };

  const checkIfAnySubCategoriesSelected = (config: any) => {
    const selectedSubFilters = _.get(selectedFilters, config.type, []);
    const allSubFilters = _.get(transformDataToCategoryMap(filterConfigEntries), config.type, []);
    return _.size(selectedSubFilters) > 0 &&  _.size(selectedSubFilters) !== _.size(allSubFilters);
  };

  const handleSubFilterSelection = (childConfig: any, config: any) => {
    delete selectedFilters['all'];
    const selectedSubFilters = _.get(selectedFilters, config.type, []);
    let updatedSubFilterEntries = _.cloneDeep(selectedSubFilters);
    const isFilterSelected = _.includes(updatedSubFilterEntries, childConfig.type);
    if (isFilterSelected) {
      updatedSubFilterEntries = _.pull(updatedSubFilterEntries, childConfig.type);
    } else {
      updatedSubFilterEntries = _.concat(updatedSubFilterEntries, childConfig.type);
    }
    let updatedFilter = {};
    if(_.size(updatedSubFilterEntries) > 0) {
      updatedFilter = _.set(_.cloneDeep(selectedFilters), config.type, updatedSubFilterEntries);
    }else{
      updatedFilter = _.omit(selectedFilters, config.type);
    }
    dispatchUpdatedFilters(updatedFilter);
  };

  const dispatchUpdatedFilters = (updatedFilters: any) => {
    dispatch(updateFilterSelectedCategories(updatedFilters));
    dispatch(updateAllProjectFilters('projectTypeFilter', _.size(updatedFilters) > 0));
    if(!_.isEmpty(currentProject) || !_.includes([PROJECTS_TAB, PROJECTS_LIST_TAB], currentTab)) {
      dispatch(updateCurrentProject({}));
      dispatch(updateCurrentTab('projects'));
      updateLocationWithParams('/projects', {});
    }
  };

  const hasChildConfigs = (config: any) => {
    const childConfigs = config.childConfigs || [];
    return _.size(childConfigs) > 0 && childConfigs.some((child: any) => child.type);
  };

  function getProjectTypes(projectTypes: any, projects: any) {
    if (_.isUndefined(projects)) {
      return projectTypes;
    }
    if (getEnableMultiCategorySupportConfig()) {
      const projectTypess = projectTypes.filter((projectType: any) => !projectType.type.includes(','));
      return projectTypess;
    }
    const uniqueCategoryIds = _.uniq(_.map(projects, 'category_id'));
    return projectTypes.filter((entity: any) => uniqueCategoryIds.includes(entity.type));
  }

  return {
    filterSelectState,
    selectedFilters,
    areAllFiltersSelected,
    handleFilterSelection,
    handleSelectAllFilters,
    handleSubFilterSelection,
    checkIfAllFiltersSelected,
    checkIfSubFilterSelected,
    hasChildConfigs,
    checkIfAnySubCategoriesSelected,
    projectTypes
  };
};

export default useProjectTypeFilter;
