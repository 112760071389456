import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "reducers/rootReducer";
import { useMemo, useState } from "react";
import useAppConfig from "./data/useAppConfig";
import { SITE_TYPE } from "./../appConstants";
import useToast from "./useToast";
import { SecurityUserTypes } from "types/configurationTypes";
import { isValidEmail } from ".././helper/projectUtilHelper";

function useUserRoles() {
  const [searchEmail, setSearchEmail] = useState("");
  const [email, setEmail] = useState("");
  const { isLoading, appConfigData, updateAppConfig } = useAppConfig();
  const showMessage = useToast();

  const currentUser: any = useSelector<RootState>((store) => store.currentUser);
  const { data } = currentUser || {};

  const currentUserEmail = _.get(data, "email", "");

  const configUserData = useMemo(
    () => JSON.parse(_.get(appConfigData, "cpe_app_users", "[]")),
    [appConfigData]
  );

  const securityRoles = useMemo(
    () => JSON.parse(_.get(appConfigData, "security_roles", "[]")),
    [appConfigData]
  );

  const onSearchByEmail = (email: string) => {
    setSearchEmail(email);
  };
  
  const onChangeEmail = (email: string) => {
    setEmail(email);
  };

  const onClickSave = () => {
    if (!_.isEmpty(email)) {
      saveNewUser(email);
    }
  };

  const filteredUserList = _.isEmpty(searchEmail) ? configUserData
    : _.filter(configUserData, (datum) => datum?.email?.toLowerCase().includes(searchEmail));


  const updateUserAndRoles = (userConfig: any[]) => {
    const userConfigUpdate = {
      app_config_key: "cpe_app_users",
      config_value_to_update: JSON.stringify(userConfig),
    };

    const newConfigUserData = {
      configs: JSON.stringify([userConfigUpdate]),
      site_type: SITE_TYPE,
    };

    updateAppConfig(newConfigUserData);
  };

  const updateRoles = (user: SecurityUserTypes, roleEntry:any) => {
    const updateRoleConfig = _.map(configUserData, (datum) => {
      if (datum.email === user.email) {
        const isManageUser  = roleEntry['can_manage_user'];
        const isManageBranding = roleEntry['can_manage_branding'];
        return _.merge(user, {role: roleEntry.role_id, isManageUser, isManageBranding});
      }
      return datum;
    });
   
    updateUserAndRoles(updateRoleConfig);
  };

  const deleteUser = (user: SecurityUserTypes) => {
    const updateRoleConfig = _.filter(configUserData, (datum) => {
      return datum.email !== user.email;
    });
   
    updateUserAndRoles(updateRoleConfig);
  };

  const getNewUserRole = (email: string, userRole: any) => {
    return {
      admin_welcome_email_sent_at: "",
      email: email,
      isManageBranding: "false",
      isManageUser: "false",
      is_email_sent: "false",
      role: _.get(userRole, "role_id", ""),
      send_admin_welcome_email: "false",
      send_welcome_email: "true",
      user_created_at: new Date().toISOString(),
      user_created_by: currentUserEmail,
      user_created_from: "administration_page",
      user_updated_at: new Date().toISOString(),
      welcome_email_sent_at: "",
    };
  };

  const isExistingUserEmail = (email: string) => {
    const userExist = _.find(configUserData, (datum) => _.toLower(datum.email) === _.toLower(email));
    return !_.isEmpty(userExist);
  };

  const saveNewUser = (email: string) => {
    const userRole = _.find(securityRoles, { default_security_role: "true" });
    
    let newUsers: any = [];
    let inValidEmails: any = [];
    let existEmails: any = [];

    const emails = email.split(";");
    
    _.forEach(emails, (email) => {
      if (isValidEmail(_.trim(email))) {
        const newUser = getNewUserRole(_.trim(email), userRole);
        newUsers.push(newUser);
      } else {
        inValidEmails.push(email);
      }
      if(isExistingUserEmail(email)) {
        existEmails.push(email);
      }
    });

    if(!_.isEmpty(existEmails)) {
      showMessage(`Already a user with the email "${existEmails.join(",")}" has access to this application.`, "error");
    } else if(!_.isEmpty(inValidEmails)) {
      showMessage(`Invalid email address "${inValidEmails.join(",")}"`, "error");
    } else if(_.isEmpty(newUsers)) {
      return;
    } else {
      setEmail("");
      updateUserAndRoles([...configUserData, ...newUsers]);
    }
  };

  const handleChangeRole = (option: any, user: any) => {
    updateRoles(user, option);
  };

  return {
    searchEmail,
    email,
    currentUserEmail,
    configUserData,
    filteredUserList,
    securityRoles,
    handleChangeRole,
    onSearchByEmail,
    onChangeEmail,
    onClickSave,
    deleteUser,
    isLoading
  };
}

export default useUserRoles;
