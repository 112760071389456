import { ForgeIcon } from '@tylertech/forge-react';
import _ from 'lodash';
import useUserProfile from '../../hooks/useUserProfile';

function UserProfile() {
  const {
    email,
    firstTwoLetters,
    role
  } = useUserProfile();

  const renderRoleDescription = () => {
    if (!role || _.isEmpty(role.description)) {
      return null;
    }

    return (
      <div className="info-card forge-typography--caption mt-3">
        <ForgeIcon name="info" className="mr-2" />
        {role.description}
      </div>
    );
  };

  return (
    <div className="user-profile">
      <div className="user-rs-side">
        <div className="user-name">{firstTwoLetters}</div>
      </div>
      <div className="user-ls-side-content">
        <div className="forge-typography--title mb-4">User profile</div>
        <div className="user-info-details">
          <div className="label forge-typography--overline">User name</div>
          <div className="user-name-details forge-typography--body1">
            {email}
          </div>
        </div>
        <div className="user-info-card">
          <div className="user-info-details mb-2">
            <div className="forge-typography--overline">Role</div>
            <div className="user-name-details forge-typography--body1">
              {role?.name}
            </div>
          </div>
          {renderRoleDescription()}          
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
