import _ from 'lodash';
import { DATA_INSIGHTS_URL } from '../../appConstants';
import { getCurrentModeAngularConstants } from '../../helper/serverConfigHelper';
import useFooterData from '../../hooks/useFooterData';
import { Link } from 'types/stateTypes';

const Footer = () => {
  const currentModeConstants = getCurrentModeAngularConstants();
  const {
    logoUrl,
    links,
    poweredByLabelText,
    dataUpdatedInfoText,
    showDataUpdatedMessage,
    showLastModifiedTime,
    lastModifiedDate,
    copyrightText = ''
  } = useFooterData();

  const showLastUpdatedMsg = () => {
    return (
      <>
      {copyrightText && <span>{`${copyrightText} | `}</span>}
      {showDataUpdatedMessage && showLastModifiedTime && (
        <span>{`${dataUpdatedInfoText} ${lastModifiedDate},`}</span>
      )}
    </>
    );
  };

  const renderFooterLinks = (links: Link[]) => {
    return _.size(links) > 0 && (
      <ul className="site-footer-nav">
        {links.map((link: Link, index: number) => (
          <li key={index}>
            <a
              className="link"
              href={link.url}
              rel="nofollow"
              target="_blank"
              tabIndex={0}
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  if (currentModeConstants.isUnifiedMode) return null;

  return (
    <footer className="app-footer" id="footer">
        { logoUrl && 
          <div className="site-footer-logo">
            <img className="footer-logo" src={logoUrl} alt="" />
          </div> 
        }
        {renderFooterLinks(links)}
        <div className="site-footer-copyright">
          {showLastUpdatedMsg()}
          <a href={DATA_INSIGHTS_URL} title={poweredByLabelText}>
            {poweredByLabelText}
          </a>
        </div>
    </footer>
  );
};

export default Footer;
