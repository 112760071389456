import projectListReducer from './projectListReducer';
import { combineReducers } from 'redux';
import projectFilterReducer from './projectFilterReducer';
import searchReducer from './searchReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  currentUser: userReducer,
  projects: projectListReducer,
  projectFilter: projectFilterReducer,
  search: searchReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
