import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { Outlet } from 'react-router-dom';
import ProjectIndicator from './components/ProjectIndicator/ProjectIndicator';
import MainContent from './MainContent';
import { Project } from 'types/projectTypes';
import classNames from 'classnames';
import _ from 'lodash';

const Layout = () => {
  const currentTab = useSelector<RootState>((store) => store.projectFilter.currentTab);
  const currentProject = useSelector<RootState>((store) => store.projects.currentProject) as Project;

  const baseClassNames = classNames(`base-page-wrapper ${currentTab}`,{
    'project-detail': !_.isEmpty(currentProject)
  });

  return (
    <div className={baseClassNames}>
      <ProjectIndicator />
      <MainContent  />
      <Outlet />
    </div>
  );
};

export default Layout;
