import _ from 'lodash';
import { Project } from 'types/projectTypes';

export class ProjectFormatter {
  private _CAPITAL_PROJECTS_CONFIG: any;
  private _projectTypeFilterConfig: any;
  private _enableMultiCategorySupport: any;

  constructor(capitalProjectConfig: any) {
    this._CAPITAL_PROJECTS_CONFIG = capitalProjectConfig;
    this._projectTypeFilterConfig = JSON.parse(capitalProjectConfig.project_types || '[]');
    this._enableMultiCategorySupport = capitalProjectConfig.enable_muti_category_support || 'true';
  }

  decorateProject = (project: Project) => {
    project.displayConfig = this._getProjectDisplayConfig(project);
    return project;
  };

  _getProjectDisplayConfig(project: Project) {
    if (_.isUndefined(project)) {
      return {};
    }

    const displayConfig: any = {},
      projectCategory = this._getProjectCategory(project) || {},
      projectSubCategory = this._getProjectSubCategory(project, projectCategory) || {},
      projectIcon = projectSubCategory.project_icon || projectCategory.project_icon;

    displayConfig.icon_light = projectSubCategory.icon || projectCategory.icon;

    if (this._enableMultiCategorySupport == 'true' && ((project['category_id'] || '').split(',').length > 1 || (project['fund'] || '').split(',').length > 1)) {
      displayConfig.project_icon = (this._CAPITAL_PROJECTS_CONFIG.multiple_categories_or_funds_icon || projectIcon);
    } else {
      displayConfig.project_icon = projectIcon;
    }
    return displayConfig;
  };

  _getProjectCategories(project: Project) {
    const currentProjectTypes = (this._enableMultiCategorySupport == 'false' ? [project['category_id']] : (project['category_id'] || '').split(',').map((item: any) => {
      return item.trim();
    }));
    return _.map(currentProjectTypes, function (type) {
      return type.trim();
    });
  };

  _getProjectCategory(project: Project) {
    if (_.isUndefined(project)) {
      return;
    }
    return _.find(this._projectTypeFilterConfig, (projectType) => {
      return _.includes(this._getProjectCategories(project), projectType.type);
    });
  };

  _getProjectSubCategory(project: Project, category: any) {
    if (_.isUndefined(project) || _.isUndefined(category)) {
      return;
    }
    return _.find((category.childConfigs || []), function (childTypeConfig) {
      return project.sub_category_id == childTypeConfig.type;
    });
  }
}
