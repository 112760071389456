import PieChart from './PieChart';
import _ from 'lodash';
import {
  pieChartScheduleLabel,
  pieChartSpentLabel,
  pieChartWorkLabel
} from '../../helper/configHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { Project } from 'types/projectTypes';
import { getBudgetIndicatorByValue, getOptionalIndicatorByValue, getScheduleIndicatorByValue } from '../../helper/projectIndicatorHelper';

interface PieChartProps {
  value: number;
  label: string;
  color: string;
}

const ProjectPieCharts = () => {
  const project = useSelector<RootState>((store) => store.projects.currentProject) as Project;

  const {
    budget_indicator: budgetIndicator,
    schedule_indicator: scheduleIndicator,
    optional_indicator: OptionalIndicator
  } = project || {};

  const {
    icon_color: budgetIconColor
  } = getBudgetIndicatorByValue(budgetIndicator);
  const {
    icon_color: scheduleIconColor
  } = getScheduleIndicatorByValue(scheduleIndicator);

  const {
    icon_color: optionalIconColor
  } = getOptionalIndicatorByValue(OptionalIndicator);

  const pieChartData = [
    { value: Number(project?.percent_spent),  color: budgetIconColor, label: pieChartSpentLabel() },
    { value: Number(project?.percent_schedule), color: scheduleIconColor,  label: pieChartScheduleLabel() },
    { value: Number(project?.percent_work), color: optionalIconColor, label: pieChartWorkLabel() },
  ];

  const renderPieChart = (datum: PieChartProps, index: number) => {
    if (_.isNaN(datum.value) || datum.value < 0) {
      return null;
    }

    const layout = {
      showlegend: false,
      height: 140,
      width: 140,
      margin: { l: 5, r: 5, t: 5, b: 5 },
    };

    const uniqueKey = `${_.snakeCase(datum.label)}${index}`;
   
    return (
      <div className="pie-chart" key={uniqueKey}>
        <PieChart
          value={datum.value}
          label={datum.label}
          color={datum.color}
          layout={layout} />
        <span>{datum.value}% {datum.label}</span>
      </div>
    );
  };

  const renderPieCharts = () => {
    return _.map(pieChartData, (datum, index) => {
      return renderPieChart(datum, index);
    });
  };

  return renderPieCharts();
};

export default ProjectPieCharts;
