import _ from 'lodash';

import { isShowAdvancedProjectMetrics } from '../../helper/configHelper';
import BulletChart from '../Visualization/BulletChart';
import ProjectListIcon from './ProjectListIcon';
import { Project } from 'types/projectTypes';

const AdvancedProjectList = ({ handleRowClick, toggleCostVariance, searchEntry, projectData }: any) => {
  const showAdvanceProjectList = isShowAdvancedProjectMetrics();

  const renderProjectPreview = (projects: Project) =>
    showAdvanceProjectList && !_.isEmpty(projects) ? (
      <td>
        <BulletChart
          project={projects}
          isTableView={true}
          toggleCostVariance={toggleCostVariance}
        />
      </td>
    ) : (
      <ProjectListIcon project={projects} />
    );

  const renderProjectDetails = (projects: any) => (
    <>
      <td style={{ width: '25px', paddingLeft: '10px' }} className="align-middle">
        {projects.displayConfig.project_icon && (
          <img
            src={projects.displayConfig.project_icon}
            alt={projects.project_name}
            style={{ width:'25px' }}
          />
        )}
      </td>
      <td className="project-name align-middle">{projects.project_name}</td>
    </>
  );

  const renderProjectDetailsAndPreview = (projects: Project) => (
    <>
      {renderProjectDetails(projects)}
      {renderProjectPreview(projects)}
    </>
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTableRowElement>, projects: any) => {
    if (event.key === 'Enter') {
      handleRowClick(projects);
    }
  };

  return (
    <tbody>
      {projectData.map((projects: Record<string, any>, index: number) => (
        <tr
          key={index}
          onClick={() => handleRowClick(projects)}
          onKeyDown={(event) => handleKeyDown(event, projects)}
          className="cursor-pointer"
          role="row"
          tabIndex={0}
        >
          {!searchEntry?.isProject && (
            <td style={{ paddingRight: '10px' }}>{searchEntry ? projects[searchEntry.mapped_field_name] : ''}</td>
          )}
          {renderProjectDetailsAndPreview(projects as Project)}
        </tr>
      ))}
    </tbody>
  );
};

export default AdvancedProjectList;
